/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react"
import { Combobox, Transition } from "@headlessui/react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { Button } from "../../../Components"
import useToggle from "../../../Components/_utils/useToggle"
import { DatePicker } from "../../../Components/base/datepicker/datepicker"
import Field from "../../../Components/base/field/field"
import Icon from "../../../Components/base/icon/icon"
import Loading from "../../../Components/base/loading/loading"
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from "../../../Components/base/modal/modal"
import { PhoneNumberField } from "../../../Components/base/phone-number/PhoneNumberField"
import { ToolTip } from "../../../Components/base/tooltip/tooltip"
import { toastSuccess } from "../../../Components/toast"
import { useUserInfoEditHook } from "../../../Hooks/UserProfile"
import { getCurrentUser, getCurrentUserDetail } from "../../../Services/userReducer"
import { useAppSelector } from "../../../Store/hooks"
import { Verified } from "../../../models/user.model"
import UserDescription from "../UserDescription"
import UserNameAndShare from "../UserNameAndShare"
import UserPersonalDetails from "../UserPersonalDetails"
import UserSocials from "../UserSocials"
import EditWrapper from "./EditWrapper"
import ReactQuill from "react-quill"

// const ReactQuill = lazy(() => import("react-quill"));

const editorModules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ 'font': ['monospace'] }],
    ]
  }
};

const formats = ['background', 'bold', 'color', 'font', 'code', 'italic', 'link', 'size', 'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block', 'formula']

  /**
   * Renders a UserInfoFormEditor component which wraps a set of components that render user information.
   * The components wrapped are UserNameAndShare, UserPersonalDetails, UserSocials, and UserDescription.
   * The component also renders an edit button which opens a modal with an InfoEditor component.
   * @returns {JSX.Element}
   */
const UserInfoFormEditor = () => {

  const currentUser = useAppSelector(getCurrentUser)
  const currentUserDetail = useAppSelector(getCurrentUserDetail)

  const { isOpen: isEditorOpen, toggle: toggleIsEditorOpen } = useToggle()

  /**
   * Handles the edit button click event by toggling the isEditorOpen state variable.
   * If the editor is not already open, it will open the editor.
   * Otherwise, it will do nothing.
   */
  const handleEditClick = () => {
    if (!isEditorOpen)
      toggleIsEditorOpen()
  }

  return (
    <EditWrapper keyId="userInfoForm" onEditClick={handleEditClick}>
      <UserNameAndShare user={currentUser} />
      <UserPersonalDetails user={currentUser} userDetail={currentUserDetail} />
      <UserSocials user={currentUser} />
      <UserDescription user={currentUser} />
      {isEditorOpen && <InfoEditor isEditorOpen={isEditorOpen} toggleIsEditorOpen={toggleIsEditorOpen} />}
    </EditWrapper>
  )
}

export default UserInfoFormEditor

interface InfoEditorProps {
  isEditorOpen: boolean
  toggleIsEditorOpen: () => void
}

/**
 * The InfoEditor component is a modal that wraps a form with user information fields.
 * The component is used in the UserInfoFormEditor component.
 * @param {boolean} isEditorOpen - a boolean that indicates whether the editor modal should be open or not.
 * @param {() => void} toggleIsEditorOpen - a function that toggles the isEditorOpen state variable.
 * @returns {JSX.Element} a JSX element that represents the InfoEditor component.
 */
const InfoEditor = (props: InfoEditorProps) => {
  const { isEditorOpen, toggleIsEditorOpen } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const currentUser = useAppSelector(getCurrentUser)
  const currentUserDetail = useAppSelector(getCurrentUserDetail)

  const [selected, setSelected] = useState({})

  const { register,
    control,
    setValue,
    getValues,
    errors,
    clearErrors,
    getMaxDob,
    getMinDob,
    isLoading,
    countryCodeList,
    countryNameList,
    watch,
    profileUpdateLoading,
    resetPassword,
    setNavigateLink,
    navigateLink,
    disableVerification,
    changePhoneNumber,
    revertPhoneNumber,
    sendOTP,
    phoneVerified,
    emailVerified,
    isCurrentUserLoading,
    showToastMessage,
    setShowToastMessage,
    trigger,
    isValid,
    saveProfile,
    maxCharacters
  } = useUserInfoEditHook()

  useEffect(() => {
    if (countryNameList && currentUser) {
      setSelected({ 'label': currentUser.country, 'value': currentUser.country })
    }
  }, [countryNameList, currentUser])

  useEffect(() => {
    if (showToastMessage) {
      toastSuccess(t(showToastMessage));
      setShowToastMessage('');
      handleCloseEditor()
    }
  }, [showToastMessage, t])

  useEffect(() => {
    if (navigateLink) {
      navigate(navigateLink);
      setNavigateLink(undefined);
    }
  }, [navigateLink])



  /**
   * Handles the close button click event in the editor modal by toggling the isEditorOpen state variable.
   * If the editor is open, it will close the editor.
   * Otherwise, it will do nothing.
   */
  const handleCloseEditor = () => {
    toggleIsEditorOpen()
  }

  /**
   * Trims the start of the input value whenever the user types in or pastes text into the input field.
   * This is to prevent the user from entering whitespace at the beginning of the input.
   * @param {object} e The event object.
   */
  const handleChange = (e: any) => {
    e.target.value = e.target.value.trimStart();
  };

  let emailVerifiedIcon: any;

  if (emailVerified) {
    emailVerifiedIcon = <Icon icon="TICK_GREEN" size="medium" />
  } else {
    emailVerifiedIcon = <Icon icon="CLOSE_RED" size="medium" />
  }

  let phoneVerifiedIcon: any;

  if (isCurrentUserLoading) {
    phoneVerifiedIcon = <Loading />;
  } else if (phoneVerified) {
    phoneVerifiedIcon = <Icon icon="TICK_GREEN" size="medium" />
  } else {
    phoneVerifiedIcon = <Icon icon="CLOSE_RED" size="medium" />
  }

  return (
    <Modal
      isOpen={isEditorOpen}
      toggle={() => {
        handleCloseEditor()
      }}
      closeOnClickOutside={false}>
      <div className="p-2">
        <div className="flex flex-row justify-between">
          <ModalHeader keyId="profileInformation">
            <div>{t('profileInformation')}</div>
          </ModalHeader>
          <ModalCloseButton
            toggle={() => {
              handleCloseEditor()
            }}
          />
        </div>
        <ModalBody>
          {(!currentUser || !currentUserDetail || isLoading) && (
            <div className="flex flex-col gap-y-5 flex-1 min-h-[30vh] w-full">
              <Loading />
            </div>
          )}

          {(currentUser && currentUserDetail && !isLoading) && (
            <div className="flex flex-col gap-y-3 flex-1 h-[70vh] w-full relative max-h-[70vh] overflow-y-scroll px-1">

              {/* FirstName LastName */}
              <div className="flex flex-row w-full gap-x-2">
                <div className="w-full flex flex-col gap-y-1">
                  <div className="font-medium text-md">{t('firstName') + ' *'}</div>
                  <Field
                    {...register('firstName')}
                    className="capitalize"
                    data-testid="input-firstName"
                    error={errors?.firstName?.message}
                    name="firstName"
                    type="text"
                    placeholder={t('firstName') + ' *'}
                  />
                </div>

                <div className="w-full flex flex-col gap-y-1">
                  <div className="font-medium text-md">{t('lastName') + ' *'}</div>
                  <Field
                    {...register('lastName')}
                    className="capitalize"
                    data-testid="input-lastName"
                    error={errors?.lastName?.message}
                    name="lastName"
                    type="text"
                    placeholder={t('lastName') + ' *'}
                  />
                </div>
              </div>

              {/* Email */}
              <div className="flex flex-col gap-y-1">
                <div className="font-medium text-md">{t('email')}</div>
                <Field
                  {...register('email')}
                  data-testid="input-email"
                  error={errors?.email?.message}
                  icon={emailVerifiedIcon}
                  name="email"
                  type="inputGroup"
                  placeholder={t('email')}
                  disabled
                />
                {(!(currentUserDetail?.username?.includes('google')) && currentUser.verified === Verified.Complete) && (
                  <div className="float-right items-center text-blue-900 text-sm flex gap-2">
                    <span
                      className="hover:underline cursor-pointer "
                      id="btn_resetPassword">
                      <span
                        onClick={() => {
                          resetPassword()
                        }}>
                        {t('resetPassword')}
                      </span>
                    </span>
                    <div className="">
                      <ToolTip
                        theme="BeeMG-gray"
                        tip="resetPasswordLinkWillBeSentToYourEmail"
                        keyId="reset">
                        <Icon icon="QUESTION" size="x-small" />
                      </ToolTip>
                    </div>
                  </div>
                )}
                {currentUser.emailVerified === Verified.NotComplete && (
                  <div className="float-right flex" id="btn_verify">
                    <Link to="/s/verifyEmail">{t('verify')}</Link>
                  </div>
                )}
              </div>

              {/* Phone */}
              <div className="flex flex-col gap-y-1">
                <div className="font-medium text-md">{t('phone') + ' *'}</div>
                <div>
                  <div
                    data-testid="input_countryCode"
                    id="input_countryCode"
                    className="md:col-span-3 sm:col-span-6"
                    style={{ width: 'inherit' }}>
                    {countryCodeList && (
                      <Controller
                        control={control}
                        name={'phoneNumber'}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <PhoneNumberField
                            value={value}
                            disabled={disableVerification}
                            placeholder={t('phoneNo') + ' *'}
                            onChange={(value: any) => onChange(value)}
                            icon={phoneVerifiedIcon}
                            error={errors?.phoneNumber?.message}
                          />
                        )}
                      />
                    )}
                    {errors.phoneNumber?.message && (
                      <span
                        id="error_phoneNo"
                        data-testid="error_phoneNo"
                        className="text-red-500">
                        {t(errors.phoneNumber.message)}
                      </span>
                    )}
                  </div>
                  <div>
                    <div className="flex flex-row items-center mt-2">
                      {!phoneVerified && (
                        <span
                          id="message_phoneVerify"
                          data-testid="message_phoneVerify"
                          className="text-red-500 mr-2">
                          {t('phoneVerificationNotComplete')}
                        </span>
                      )}
                      {!disableVerification && (
                        <div>
                          <Button className='mr-2'
                            size="sm" color="category" id="btn_sendOTP" data-testid="btn_sendOTP" onClick={() => {
                              sendOTP(getValues())
                            }}
                            disabled={!!errors?.phoneNumber || phoneVerified}
                          >
                            {t('sendOTP')}
                          </Button>
                          <Button size="sm" color="category" id="btn_codeCancel" data-testid="btn_codeCancel" onClick={() => {
                            revertPhoneNumber();
                            clearErrors('phoneNumber');
                            setValue('phoneNumber', currentUserDetail.phone_number)
                          }}>
                            {t('cancel')}
                          </Button>
                        </div>
                      )}
                    </div>
                    {currentUser.phoneVerified === 'Complete' && disableVerification && (
                      <small className="text-blue-900 ml-1">
                        <button
                          type="button"
                          id="btn_changePhoneNumber"
                          data-testid="btn_changePhoneNumber"
                          onClick={() => {
                            changePhoneNumber()
                          }}>
                          {t(`changePhoneNumber`)}
                        </button>
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* Date of birth */}
              <div data-testid="input-date" className="font-semibold">
                {getMaxDob() && getMinDob() && (
                  <DatePicker
                    label={t('dateOfBirth')}
                    name="dateOfBirth"
                    control={control}
                    error={errors.dateOfBirth?.message}
                    minDate={getMinDob()}
                    maxDate={getMaxDob()}
                  />
                )}
              </div>

              {/* Gender */}
              <div className="flex flex-col gap-y-1">
                <div className="font-medium text-md">{t('gender')}</div>
                <Field
                  {...register('gender')}
                  name="gender"
                  data-testid="input-gender"
                  error={errors?.gender?.message}
                  type="select">
                  <option value="Male" key="Male">
                    {t('male')}
                  </option>
                  <option value="Female" key="Female">
                    {t('female')}
                  </option>
                  <option value="Others" key="Others">
                    {t('others')}
                  </option>
                </Field>
              </div>

              {/* City */}
              <div className="flex flex-col gap-y-1">
                <div className="font-medium text-md">{t('city') + ' *'}</div>
                <Field
                  {...register('city')}
                  error={errors?.city?.message}
                  data-testid="input-city"
                  name="city"
                  type="text"
                  placeholder={t('city') + ' *'}
                />
              </div>

              {/* Country */}
              {countryNameList && <DisplayCountryCombo />}

              {/* Host intro */}
              <div className="flex flex-col gap-y-1">
                <div className="font-medium text-md">{t('hostIntroduction')}</div>
                <div data-testid="input_selfIntro" className="border-t rounded-xl">
                  {/* <Suspense fallback={<Loading />}> */}
                    <ReactQuill
                      theme="snow" value={watch('selfIntro')} id="input_selfIntro" onChange={content => setValue('selfIntro', content)} modules={editorModules} formats={formats} preserveWhitespace
                    />
                  {/* </Suspense> */}
                  {maxCharacters &&
                    <span data-testid="error_selfIntroMax" id="error_selfIntroMax" className="text-red-500 relative flex md:mt-12 mt-20">{t('introductionCanBeMax1500CharactersLong')}
                    </span>}
                </div>
              </div>

              {/* Socials */}
              <div className="flex flex-col gap-y-1 md:pt-12 pt-20">
                <div className="font-medium text-md">{t('socialMedia')}</div>
                <div>
                  <Field
                    {...register('facebookName')} error={errors?.facebookName?.message} data-testid="input_facebookName"
                    name="facebookName" type="text" icon={<Icon icon="FACEBOOK" size="medium" />}
                    placeholder={t('facebookAccountName')}
                    onChange={handleChange}
                  />
                </div>
                <div >
                  <Field
                    {...register('instagramName')} error={errors?.instagramName?.message} data-testid="input_instagramName"
                    name="instagramName" type="text" icon={<Icon icon="INSTA" size="medium" />} placeholder={t('instagramAccountName')}
                    onChange={handleChange}
                  />
                </div>
                <div >
                  <Field
                    {...register('twitterName')} error={errors?.twitterName?.message} data-testid="input_twitterName"
                    name="twitterName" type="text" icon={<Icon icon="TWITTER" size="small" />} placeholder={t('twitterAccountName')}
                    onChange={handleChange}
                  />
                </div>
                <div >
                  <Field
                    {...register('linkedInName')} error={errors?.linkedInName?.message} data-testid="input_linkedInName"
                    name="linkedInName" type="text" icon={<Icon icon="LINKEDIN" size="medium" />}
                    onChange={handleChange}
                    placeholder={t('linkedInAccountName')}
                  />
                </div>
              </div>

            </div>
          )}
        </ModalBody>
        <div className="flex mx-auto mt-[1.2rem] self-center justify-center items-center">
          <Button
            onClick={() => {
              trigger()

              if (isValid) {
                saveProfile(getValues())
              }
            }}
            className="mx-0"
            id="btn_saveProfile"
            color="save"
            disabled={profileUpdateLoading || currentUser.phoneVerified === Verified.NotComplete || isCurrentUserLoading || maxCharacters}
          >
            <div className="flex items-center">{t('saveProfile')}</div>
          </Button>
        </div>
      </div>
    </Modal>
  )

  /**
   * Component for displaying a list of countries to select from in the profile editor.
   * The component uses a Combobox component from the Headless UI library.
   * The list of countries is filtered based on the user's input in the text box.
   * When a country is selected, the selected country is displayed in the text box.
   * @returns A JSX element representing the component.
   */
  function DisplayCountryCombo() {
    const [query, setQuery] = useState<string>('')

    let filteredCountry

    if (countryNameList) {
      filteredCountry =
        query === ''
          ? countryNameList
          : countryNameList.filter(country =>
              country.value
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(query.toLowerCase().replace(/\s+/g, '')),
            )
    }

    return (
      <>
        <div className="flex flex-col gap-y-1">
          <div className="font-medium text-md">{t('country')}</div>
          <div
            data-testid="input_country"
            id="input_country"
            className="col-span-6 sm:col-span-3">
            {filteredCountry && (
              <>
                <div className="z-30 w-full my-2 md:my-0">
                  <Combobox
                    value={selected}
                    onChange={(event: any) => {
                      setSelected(event)
                      setValue('country', event.value)
                    }}>
                    <div className="relative hover:z-30">
                      <div>
                        <Combobox.Input
                          className="leading-5 relative flex flex-1 w-full rounded-lg p-input bg-white text-gray-400 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 h-8"
                          displayValue={(country: any) => country?.value}
                          onChange={(event: any) => {
                            setQuery(event.target.value)
                          }}
                          onSelect={(event: any) => {
                            setQuery(event.target.value)
                          }}
                          placeholder={t('country')}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center">
                          <div className="px-2">
                            <Icon icon="RIGHT" size="x-small" />
                          </div>
                        </Combobox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}>
                        <Combobox.Options className="z-30 focus:outline-none absolute mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                          {filteredCountry.length === 0 && query !== '' ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                              Nothing found.
                            </div>
                          ) : (
                            filteredCountry.map(country => (
                              <Combobox.Option
                                key={country.label}
                                className={({active}) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 mx-1 rounded-lg ${
                                    active
                                      ? 'bg-BeeMG-yellow text-black'
                                      : 'text-gray-900'
                                  }`
                                }
                                value={country}>
                                {({selected, active}) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? 'font-medium' : 'font-normal'
                                      }`}>
                                      {country.value}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={`absolute inset-y-0 left-0 flex items-center pl-1 ${
                                          active ? 'text-white' : 'text-black'
                                        }`}>
                                        <Icon icon="TICK_GREEN" size="medium" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Combobox.Option>
                            ))
                          )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    )
  }
}
