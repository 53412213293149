/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../Components'
import Field from '../Components/base/field/field'
import { toastSuccess } from '../Components/toast'
import { useResetPasswordHook } from '../Hooks/ResetPassword'
import { resetPasswordFormValidationSchema } from '../Utils/validation'
import Footer from './Footer'
import Header from './Header'
// import Loading from '../Components/base/loading/loading'
import OtpInput from 'react-otp-input';
import Icon from '../Components/base/icon/icon'

// const OtpInput = lazy(() => import("react-otp-input"));

  /**
   * Resets the user's password.
   * @param {string} email - The email address of the user to reset the password for.
   * @param {string} medium - The medium to use for sending the password reset code.
   * @returns {JSX.Element} The JSX element representing the reset password form.
   */
function ResetPassword() {
  const { email, medium } = useParams()
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState('');

  const [otpCount, setOtpCount] = useState<number>(0);

  const {
    errorMessage, navigateScreen, onSubmit, t, showToastMessage, setShowToastMessage, handlePassword, handleResendCodeClick, codeSentMessage, setCodeSentMessage, setErrorMessage
  } = useResetPasswordHook(email);
  const [otp, setOtp] = useState('')

  const {
    register, handleSubmit, watch, formState: { errors }, setValue
  } = useForm({ resolver: yupResolver(resetPasswordFormValidationSchema) })

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);



  /**
   * Toggles the visibility of the password field.
   * @function
   */
  const togglePassword = () => {
    setShowPassword(prev => !prev);
  };
  /**
   * Toggles the visibility of the confirm password field.
   * @function
   */
  const toggleConfirmPassword = () => {
    setShowConfirmPassword(prev => !prev);
  };

  if (navigateScreen) {
    navigate('/signIn');
  }
  useEffect(() => {
    if (showToastMessage) {
      toastSuccess(t('passwordHasBeenReset'));
      setShowToastMessage(false);
    }
  }, [showToastMessage])

  useEffect(()=>{
    if(codeSentMessage){
      toastSuccess(t('codeSentSuccessfully'))
      setCodeSentMessage(false)
    }
  },[codeSentMessage])

  useEffect(() => {
    setValue('code', otp)
  },[otp])

  useEffect(() => {
    if(otpCount !== 6){
      setErrorMessage('')
    }
  }, [otpCount])

  if (errors.password) {
    const content = (errors.password as any).message?.split(",");

    if (content && content?.length > 0) {
      let errorMessage = '<ul>';

      content.forEach((errorContent: string) => {
        if (errors.password) {
          errorMessage += '<li>' + t(errorContent) + '</li>';
        }
      })

      errorMessage += '</ul>';

      if (passwordError !== errorMessage) {
        setPasswordError(errorMessage);
      }
    }
  } else {
    if (passwordError) {
      setPasswordError('');
    }
  }

  return (
    <>
      <div className='flex flex-col md:px-4' style={{ 'height': '93vh' }}>
        <Header />
        <div className='text-center text-4xl mb-2'>{t('resetPassword')}</div>
        <div className="px-2">
          <span className="flex items-center flex-col">
            <div className="lg:w-96 sm:mx-3 text-black font-semibold mt-2">
              {otpCount === 6 && errorMessage && <span id="error_wrongCode" className="flex justify-center text-red-500">{t(errorMessage)}</span>}
              <form onSubmit={handleSubmit(onSubmit)}>
                <span className="flex justify-start mb-2">{t('enterCodeSentToYour')} {medium}</span>
                <div className="col-span-6 w-auto">
                  {/* <Suspense fallback={<Loading />}> */}
                    <OtpInput
                      {...register('code')}
                      value={otp}
                      data-testid="input_code"
                      onChange={(e:any)=>{setOtp(e);setOtpCount(e.length)}}
                      numInputs={6}
                      inputStyle={{ width: '1.8em', height: '1.8em', borderRadius: '0.25rem', margin: '0 0.2em', padding: '0.5em 0.5em', fontSize: '1.25em', fontWeight: '600', color: '#374151' }}
                      separator={<span>-</span>}
                      isInputNum={true}
                      errorStyle={{ border: '1px solid red' }}
                      hasErrored={(errors?.code as any)?.message || errorMessage ? true : false}
                    />
                  {/* </Suspense> */}
                </div>
                <span className="flex justify-start mb-2 mt-4">{t('enterPassword')}</span>
                <div className="col-span-6 w-full">
                  <Field
                    {...register('password')}
                    data-testid="input_password"
                    id="input_password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={`${t('enterPassword')} *`}
                    onKeyDown={handlePassword}
                    icon={
                      watch('password')?.length > 0 ? (
                        <Icon
                          icon={showPassword ? "VIEW" : "VIEW_SLASH"}
                          onClick={togglePassword}
                        />
                      ) : undefined
                    }
                  />
                  {passwordError &&
                    <span id="error_password" data-testid="error_password" className="text-red-500 text-sm" dangerouslySetInnerHTML={{ __html: passwordError, }}></span>
                  }
                </div>
                <span className="flex justify-start mb-2 mt-4">{t('reEnterPassword')}</span>
                <div className="col-span-6 w-full">
                  <Field
                    {...register('confirmPassword')}
                    data-testid="input_confirmPassword"
                    id="input_confirmPassword"
                    error={t((errors?.confirmPassword as any)?.message)}
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder={`${t('reEnterPassword')} *`}
                    onKeyDown={handlePassword}
                    icon={
                      watch('confirmPassword')?.length > 0 ? (
                        <Icon
                          icon={showConfirmPassword ? "VIEW" : "VIEW_SLASH"}
                          onClick={toggleConfirmPassword}
                        />
                      ) : undefined
                    }
                  />
                </div>
                <span className="flex justify-start text-red-500 mb-2 mt-4 cursor-pointer" id="btn_resendOTP" data-testid="input_resendOTP" onClick={handleResendCodeClick}>{t('resendCode')} ?</span>
                <span className="flex justify-end mb-2 mt-4"><Button color="save" id="btn_reset" data-testid="btn_save" disabled={otpCount !== 6} submit><div>{t('reset')}</div></Button></span>
              </form>
            </div>
          </span>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ResetPassword
