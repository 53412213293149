/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/**
 * A small, round, black dot. Useful for indicating the presence of something
 * in a non-intrusive way.
 *
 * @param className - An optional className to pass to the div element.
 * @returns A React component that renders a small, round, black dot.
 */
const Dot = ({ className }: { className?: string }) => {
  return <div className={`h-1.5 w-1.5 bg-black rounded-full ${className}`} />
}

export default Dot;
