/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import { useVideoMeetingStatus } from '../../Hooks/MessageHooks'
import { ChatMessage } from '../../Store/Chat/ChatModel'

/**
 * Renders the status of a video meeting.
 *
 * This component renders the status of a video meeting, which can be either
 * 'meetingDuration' (with the meeting duration in minutes), 'meetingCompleted',
 * 'meetingExpired', 'meetingRequest', or 'meetingRequestExpired'.
 *
 * @param {{ message: Partial<ChatMessage> }} props - The properties of the component.
 * @param {Partial<ChatMessage>} props.message - The message object.
 * @return {JSX.Element} The JSX element representing the video meeting status.
 */
const VideoMeetingStatus = ({ message }: { message: Partial<ChatMessage> }) => {
    const { t } = useTranslation()
    const { status, minutes } = useVideoMeetingStatus({ message })

    return (
        <>
            {status && <div className="flex flex-row items-center my-1 text-sm">
                <div
                    id={`chk_meetingCompleted${message?.messageId}`}
                    className="flex flex-row items-center py-1">
                    {t(status)}
                    {minutes && status === 'meetingDuration' && (
                        <>{` : ${minutes} ${t('min')
                            }`}</>
                    )}
                </div>
            </div>}
        </>
    )
}

export default VideoMeetingStatus
