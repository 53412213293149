/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import React from "react";
import Loading from "../../Components/base/loading/loading";
import NoSession from "../../Components/NoSession";
import { getCurrentUser } from "../../Services/userReducer";
import { useAppSelector } from "../../Store/hooks";
import RequestForMeetingReviewCard from "../ViewEvents/RequestForMeetingReviewCard";
import { useRequestForMeetingReviewsHook } from "../../Hooks/RequestForMeetingReviewsHook";

/**
 * A component that displays the list of feedbacks for the current user's hosted meetings.
 *
 * - If the feedbacks are still loading, it displays a loading animation.
 * - If the feedbacks are not available, it displays a "No Session" message.
 * - Otherwise, it displays a list of RequestForMeetingReviewCards, one for each feedback in the list.
 *
 * @returns {JSX.Element} The feedback list component.
 */
const RequestEventsFeedbacks = () => {
  const currentUser = useAppSelector(getCurrentUser)
  const { reviews, isReviewsLoading, isReviewsSuccess } = useRequestForMeetingReviewsHook({ hostId: currentUser.id })

  if (isReviewsLoading) {
    return <Loading />
  }

  if (!isReviewsSuccess || (reviews && reviews.length === 0)) {
    return <NoSession type="feedbacks" />
  }

  return (<>
    <div className="block lg:w-11/12 w-full px-3 mb-48 md:mb-36 lg:mb-28">
      {React.Children.toArray(reviews?.map((review) => <RequestForMeetingReviewCard review={review} />))}
    </div></>);
}

export default RequestEventsFeedbacks;