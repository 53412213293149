/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  nanoid,
} from '@reduxjs/toolkit';
import {Message, MessageType} from '../../API/socketTypes';
import {socketClient} from '../../API/socketio';
import {messageApi} from '../../Services/messageApi';
import {formatTimeToTimezone} from '../../Utils/datetime';
import {Meeting} from '../../models/meeting.model';
import {Accept} from '../../models/meetingRequest.model';
import {User} from '../../models/user.model';
import {RootState} from './../index';
import {
  ChatMessage,
  ChatMessageType,
  ChatUser,
  ChatUserData,
} from './ChatModel';

export const sendChatMessage = createAsyncThunk<void, Message>(
  'chat/message/sendchatmessage',
  async (message, {dispatch, getState}) => {
    const {util} = getState() as any;

    if (util.isAppOffline) {
      dispatch(addToOfflineQueue(message));
      return;
    }

    socketClient.send(message);
    dispatch(
      removeFromOfflineQueue((message.message as ChatMessage).messageId),
    );

    // TODO: add ACK
  },
);

/**
 * Sends a video meeting acceptance message to the server.
 *
 * @param {ChatMessage} rMessage - The chat message containing the meeting request to accept.
 * @return {Promise<void>} Resolves when the acceptance message has been sent.
 */

export const acceptVideoRequest = async (rMessage: ChatMessage) => {
  const chatMessage = {
    type: ChatMessageType.Video,
    meetingRequest: {id: rMessage.meetingRequest?.id},
    messageId: rMessage.messageId,
  } as ChatMessage;

  const message = {} as Message;
  message.type = MessageType.VideoMeetingAccept;
  message.message = chatMessage;

  socketClient.send(message);
};

/**
 * Sends a video meeting rejection message to the server.
 *
 * @param {ChatMessage} rMessage - The chat message containing the meeting request to reject.
 * @return {Promise<void>} Resolves when the rejection message has been sent.
 */

export const declineVideoRequest = async (rMessage: ChatMessage) => {
  const chatMessage = {
    type: ChatMessageType.Video,
    meetingRequest: {id: rMessage.meetingRequest?.id},
    messageId: rMessage.messageId,
  } as ChatMessage;

  const message = {} as Message;
  message.type = MessageType.VideoMeetingReject;
  message.message = chatMessage;

  socketClient.send(message);
};

export const addRequestDetailFromServer = createAsyncThunk<void, string[]>(
  'chat/message/addRequestDetailFromServer',
  async (requestDetail, thunkApi: any) => {
    const messages: Partial<ChatMessage>[] =
      thunkApi.getState().chatmessage.messages;
    const isChatExists = messages?.find(
      (message: Partial<ChatMessage>) =>
        message.fromUser?.id === requestDetail[0] ||
        message.toUser?.id === requestDetail[0],
    );
    if (isChatExists) {
      thunkApi.dispatch(setMeetingRequestId(requestDetail[1]));
    }
  },
);

export const sendTextMessage = createAsyncThunk<
  void,
  {messageText: string; isBlocked: boolean}
>(
  'chat/message/sendTextMessage',
  async ({messageText, isBlocked}, {dispatch, getState}: any) => {
    const state = getState();

    const chatMessage = {
      messageId: nanoid(),
      toUser: {
        id: state.chatmessage?.currentChatUser?.id,
      },
      fromUser: {
        id: state.user.currentUser.id,
        anonymous: state.user.currentUser?.anonymous,
        image: state.user.currentUser?.image,
        userImage: state.user.currentUser?.userImage,
        avatar: state.user.currentUser?.avatar,
        firstName: state.user.currentUser?.firstName,
        lastName: state.user.currentUser?.lastName,
        nickName: state.user.currentUser?.nickName,
        allowMessage: state.user.currentUser?.allowMessage,
      },
      message: messageText,
      dateSend: new Date(Date.now()).toISOString(),
      dateSeen: null,
      timeDisplay: '',
      type: ChatMessageType.Text,
      meetingRequest: {},
      sent: false,
      isBlocked: isBlocked ?? false,
    } as ChatMessage;

    const message = {} as Message;
    message.type = MessageType.Text;
    message.message = chatMessage;

    dispatch(sendChatMessage(message));
    dispatch(addToChat(message.message as ChatMessage));
  },
);

export const sendVideoRequest = createAsyncThunk<
  void,
  {toUserId: string; isBlocked: boolean}
>(
  'chat/message/sendTextMessage',
  async ({toUserId, isBlocked}, {dispatch, getState}: any) => {
    const state = getState();
    const videoRequest = {
      messageId: nanoid(),
      type: ChatMessageType.Video,
      fromUser: {
        id: state.user.currentUser.id,
        anonymous: state.user.currentUser?.anonymous,
        image: state.user.currentUser?.image,
        userImage: state.user.currentUser?.userImage,
        avatar: state.user.currentUser?.avatar,
        firstName: state.user.currentUser?.firstName,
        lastName: state.user.currentUser?.lastName,
        nickName: state.user.currentUser?.nickName,
        allowMessage: state.user.currentUser?.allowMessage,
      } as User,
      toUser: {
        id: toUserId,
      } as User,
      dateSend: new Date(Date.now()).toISOString(),
      dateSeen: null,
      timeDisplay: '',
      meetingRequest: {},
      sent: false,
      isBlocked: isBlocked ?? false,
    } as ChatMessage;

    const message = {} as Message;
    message.type = MessageType.VideoRequest;
    message.message = videoRequest;

    dispatch(sendChatMessage(message));
    dispatch(addToChat(message.message as ChatMessage));
    dispatch(messageApi.util.invalidateTags(['VideoRequest']));

    return;
  },
);

/**
 * Converts a given date and time to a human-readable format based on the time elapsed since the chat message was sent.
 *
 * @param {string} dateSend - The date and time the chat message was sent in ISO format.
 * @param {string} timeZone - The time zone to use for the conversion.
 * @param {any} t - A translation function to use for formatting the result.
 * @return {string} A human-readable string representing the time elapsed since the chat message was sent.
 */

export function convertChatTime(dateSend: string, timeZone: string, t: any) {
  const nowDateTime = new Date();
  const chatDateTime = new Date(dateSend);
  const difference = nowDateTime.valueOf() - chatDateTime.valueOf();

  if (difference < 3.6e6) {
    const time = Math.floor(difference / 60000);
    if (time > 0) {
      return time + t(' min');
    } else {
      return t('justNow');
    }
  } else {
    return formatTimeToTimezone(dateSend);
  }
}

/**
 * Calculates the duration of a meeting in minutes.
 *
 * @param {Meeting} meeting - The meeting object containing actual start and end times, and scheduled duration.
 * @return {number} The calculated duration of the meeting in minutes.
 */

export function calculateMeetingDuration(meeting: Meeting): number {
  const difference =
    new Date(meeting.actualEndAt).getTime() -
    new Date(meeting.actualStartAt).getTime();
  const differenceInMinutes = Math.round(difference / 60000);

  if (differenceInMinutes > parseInt(meeting.duration, 10)) {
    return parseInt(meeting.duration, 10);
  }

  if (differenceInMinutes === 0) {
    return 1;
  }

  return differenceInMinutes;
}

interface IChatState {
  messages: ChatMessage[];
  chatUsersList: ChatUserData[];
  currentChatUser: ChatUser | null;
  meetingRequestId: string;
  scrollChatToBottom: boolean;
  sendVideoChatRequest: boolean;
  todayMeetingRequestCount: number;
  chatMaxCharExceeded: boolean;
  offlineQueue: Message[];
}

const initialState: IChatState = {
  messages: [],
  chatUsersList: [],
  currentChatUser: null,
  meetingRequestId: '',
  scrollChatToBottom: true,
  sendVideoChatRequest: false,
  todayMeetingRequestCount: 0,
  chatMaxCharExceeded: false,
  offlineQueue: [],
};

export const chatSlice = createSlice({
  name: 'chatmessage',
  initialState: initialState,

  reducers: {
    addToChat: (state, action: PayloadAction<ChatMessage>) => {
      const isExists = !!state.messages.find(
        message => message.messageId === action.payload.messageId,
      );
      if (!isExists) {
        state.messages.push(action.payload);
      } else {
        state.messages.forEach(message => {
          if (message.messageId === action.payload.messageId) {
            message.sent = true;
          }
        });
      }
      state.scrollChatToBottom = !state.scrollChatToBottom;
    },
    setCurrentChatUser: (state, action) => {
      state.currentChatUser = action.payload;
      if (
        action.payload &&
        !state.chatUsersList?.find(
          value => value?.chatUser?.id === action.payload.id,
        )
      ) {
        const newChatUserData: ChatUserData = {
          chatUser: action.payload,
          dateSend: new Date().toString(),
          message: ' ',
          unreadMessage: 0,
        };
        state.chatUsersList.unshift(newChatUserData);
      }
    },
    setMeetingRequestId: (state, action) => {
      if (action.payload) {
        state.meetingRequestId = action.payload;
      }
    },
    setSendVideoChatRequest: (state, action) => {
      state.sendVideoChatRequest = action.payload;
    },
    clearUnreadMessages: (state, action) => {
      if (action.payload) {
        state.chatUsersList.forEach(item => {
          if (action.payload === item.chatUser?.id) {
            item.unreadMessage = 0;
          }
        });
      }
    },
    setTodayMeetingRequestCount: (state, action) => {
      if (action.payload) {
        state.todayMeetingRequestCount = action.payload;
      }
    },
    clearEmptyChatUser: state => {
      state.chatUsersList = state.chatUsersList.filter(
        item => item.message !== ' ',
      );
    },
    setMaxCharExceeded: (state, action: PayloadAction<boolean>) => {
      state.chatMaxCharExceeded = action.payload;
    },
    updateVideoRequestChat: (state, action) => {
      if (!action.payload) {
        return;
      }
      const {message, type} = action.payload;
      const chatMessage = state.messages.find(
        msg => msg.messageId === message.messageId,
      );

      if (chatMessage && type === MessageType.VideoMeetingAccept) {
        chatMessage.meetingRequest.accept = Accept.Yes;
        chatMessage.meetingRequest.meeting = (
          message as ChatMessage
        ).meetingRequest?.meeting;
      } else if (chatMessage && type === MessageType.VideoMeetingReject) {
        chatMessage.meetingRequest.accept = Accept.No;
      }
    },
    updateChatUsers: (state, action) => {
      const {message, fromCurrentUser} = action.payload;
      if (state.chatUsersList.length === 0) {
        const chatUserData = {
          chatUser: message.fromUser,
          message: message.message,
          dateSend: message.dateSend,
          unreadMessage: fromCurrentUser ? 0 : 1,
        };
        state.chatUsersList.unshift(chatUserData);
      } else {
        const isChatUserPresent = state.chatUsersList.find(
          item =>
            item.chatUser.id ===
            (fromCurrentUser ? message.toUser.id : message.fromUser?.id),
        );
        if (isChatUserPresent) {
          state.chatUsersList.forEach(item => {
            if (
              item.chatUser.id ===
              (fromCurrentUser ? message.toUser?.id : message.fromUser?.id)
            ) {
              if (typeof item.unreadMessage !== 'undefined') {
                item.unreadMessage = fromCurrentUser
                  ? (item.unreadMessage = 0)
                  : state.currentChatUser?.id === message.fromUser?.id
                  ? 0
                  : Number(item.unreadMessage) + 1;
                item.message = message.message
                  ? message.message
                  : 'Video Request';
                item.dateSend = message.dateSend;
              }
            }
          });
        } else {
          const chatUserData = {
            chatUser: message.fromUser,
            message: message.message,
            dateSend: message.dateSend,
            unreadMessage: fromCurrentUser ? 0 : 1,
          };
          state.chatUsersList.unshift(chatUserData);
        }
      }
      state.chatUsersList.sort((a, b) => b.dateSend.localeCompare(a.dateSend));
    },
    updateVideoRequestMeeting: (state, action) => {
      state.messages.forEach(message => {
        if (message.meetingRequest?.meeting?.id === action.payload?.id) {
          message.meetingRequest.meeting = action.payload;
        }
      });
    },
    addToOfflineQueue: (state, action: PayloadAction<Message>) => {
      if (action.payload) {
        const message = action.payload;
        (message.message as ChatMessage).sent = false;
        state.offlineQueue.push(message);
      }
    },
    removeFromOfflineQueue: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.offlineQueue = state.offlineQueue.filter(
          value => (value.message as ChatMessage).messageId !== action.payload,
        );
      }
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      messageApi.endpoints.getAllChatUsers.matchFulfilled,
      (state, action) => {
        if (action.payload && action.payload.length > 0) {
          state.chatUsersList = action.payload;
        }
      },
    );
    builder.addMatcher(
      messageApi.endpoints.getMessagesByUser.matchFulfilled,
      (state, action) => {
        if (action.payload) {
          let _messages: ChatMessage[] = [];
          action.payload.results.forEach(item => {
            const isExists = state.messages.find(
              message => message.messageId === item.messageId,
            );
            if (!isExists) {
              _messages.push(item);
            }
          });
          state.messages.unshift(..._messages);
          state.messages.sort((a, b) => a.dateSend.localeCompare(b.dateSend));
        }
      },
    );
    builder.addMatcher(
      messageApi.endpoints.getMessagesByMeetingRequestId.matchFulfilled,
      (state, action) => {
        state.messages.forEach(message => {
          if (message?.meetingRequest) {
            if (
              message.meetingRequest?.id === action.payload.meetingRequest?.id
            ) {
              message.meetingRequest = action.payload.meetingRequest;
            }
          }
        });
      },
    );
    builder.addMatcher(
      messageApi.endpoints.getTodayMeetingRequestCount.matchFulfilled,
      (state, action) => {
        state.todayMeetingRequestCount = action.payload;
      },
    );
    builder.addMatcher(
      messageApi.endpoints.updateSeen.matchFulfilled,
      (state, action) => {
        const messageFound = state.messages.find(
          message => message.messageId === action.payload.messageId,
        );

        if (messageFound) {
          messageFound.dateSeen = action.payload.dateSeen;
        }
      },
    );
  },
});

export const {
  addToChat,
  updateVideoRequestChat,
  setCurrentChatUser,
  setMeetingRequestId,
  updateChatUsers,
  clearUnreadMessages,
  setSendVideoChatRequest,
  setTodayMeetingRequestCount,
  clearEmptyChatUser,
  setMaxCharExceeded,
  updateVideoRequestMeeting,
  addToOfflineQueue,
  removeFromOfflineQueue,
} = chatSlice.actions;

export const getMessages = (state: RootState): ChatMessage[] =>
  state.chatmessage?.messages;
export const getChatUsersList = (state: RootState): ChatUserData[] =>
  state.chatmessage?.chatUsersList;
export const getCurrentChatUser = (state: RootState) =>
  state.chatmessage?.currentChatUser;
export const getActiveMeetingRequestId = (state: RootState) =>
  state.chatmessage?.meetingRequestId;
export const getScrollChatToBottom = (state: RootState) =>
  state.chatmessage?.scrollChatToBottom;
export const getSendVideoChatRequest = (state: RootState) =>
  state.chatmessage?.sendVideoChatRequest;
export const getTodayMeetingRequestCount = (state: RootState): number =>
  state.chatmessage?.todayMeetingRequestCount;
export const getMaxCharExceeded = (state: RootState): boolean =>
  state.chatmessage?.chatMaxCharExceeded;
export const getOfflineQueue = (state: RootState): Message[] =>
  state.chatmessage?.offlineQueue;

export default chatSlice.reducer;
