/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import EditIcon from "../EditIcon"

interface EditWrapperProps {
  children: React.ReactNode
  onEditClick: () => void
  className?: string
  keyId?: string
}

  /**
   * Wraps a component with an edit icon button.
   *
   * @param {React.ReactNode} children - The component to be wrapped.
   * @param {() => void} onEditClick - The function to be called when the edit icon is clicked.
   * @param {string} [className] - The class name to be added to the wrapper.
   * @param {string} [keyId] - The keyId to be used for the wrapper.
   * @returns {React.ReactElement} The wrapped component.
   */
const EditWrapper = (props: EditWrapperProps) => {
  const { children, onEditClick, className, keyId } = props

  return <div id={`${keyId}_detail`} data-testid={`${keyId}_detail`}className={`p-[1rem] flex flex-col gap-y-4 justify-center items-center rounded-xl transition-all duration-100 ease-in-out group relative ${className}`}>
    <div className="absolute hidden group-hover:block top-3 right-3"><EditIcon keyId={keyId} onClick={onEditClick} /></div>
    {children}
  </div>
}

export default EditWrapper
