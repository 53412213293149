/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useState } from 'react';

/**
 * A hook that provides a toggle function and isOpen state.
 * @returns A tuple where the first element is a function that toggles the isOpen state and the second element is the isOpen state.
 * @example
 * const { toggle, isOpen } = useToggle();
 * 
 * <div onClick={toggle}>Click me</div>
 * <div style={{ display: isOpen ? 'block' : 'none' }}>I'm open</div>
 * 
 */
const useToggle = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * A function that toggles the isOpen state.
   * If the event argument is provided, it will call stopPropagation on the event.
   * @param {Event} [e] The event object.
   * @example
   * <div onClick={(e) => toggle(e)}>Click me</div>
   * 
   */
  const toggle = (e?: any) => {
    try {
      e?.stopPropagation()
    } catch (error) {}
    
    setIsOpen(!isOpen)
  }
  return { toggle, isOpen };
};

export default useToggle;
