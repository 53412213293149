/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {RefundRequest} from '../models/refundRequest.model';
import {allApis} from './allApis';

/**
 * Defines the endpoints for refund request API.
 *
 * @param {object} options - Options for the API endpoints.
 * @return {{ createRefundRequest: mutation, uploadRefundAttachments: mutation }} - An object containing the defined endpoints.
 */

export const refundRequestApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createRefundRequest: builder.mutation<
      RefundRequest,
      Partial<RefundRequest>
    >({
      query: refundRequest => ({
        url: `refundRequest`,
        method: 'POST',
        body: refundRequest,
      }),
      invalidatesTags: (result, error, refundRequest) => [
        {type: 'MeetingNotificationRemainder'},
      ],
    }),

    uploadRefundAttachments: builder.mutation<any[], any>({
      query: ({id, files}) => {
        return {
          url: `refundRequest/refundRequestAttachments/${id}`,
          method: 'POST',
          body: files,
        };
      },
    }),
  }),
});
export const {
  useCreateRefundRequestMutation,
  useUploadRefundAttachmentsMutation,
} = refundRequestApi;
