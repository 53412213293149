/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import { Accordion, AccordionBorder, AccordionItem } from '../Components/base/accordion/accordion'
import CardImagePlaceHolder from './EventsCard/CardImagePlaceHolder'
import CardTitle from './EventsCard/CardTitle'
import TextLink from './EventsCard/TextLink'
import { FC } from 'react'
import { Meeting } from '../models/meeting.model'
import { EventCard } from './EventsCard/EventCard'
import { DisplayEventTypeIcons } from './EventsCard/DisplayEventTypeIcons'
import { DisplayEventCost } from './EventsCard/DisplayEventCost'
import NewIcon from '../Components/base/icon/newIcons'
import { DisplayFormattedDateAndTime } from '../Components/formattedDateAndTime'

interface InterestedMeetings {
  meetings: Meeting[]
}

const style = { editEventText: `flex md:flex-col sm:gap-2 xs:gap-2 md:gap-0 items-center justify-center min-w-cardRightText md:w-36`, }
/**
 * A React component that displays a list of meetings that the user is interested in.
 *
 * @param meetings - An array of Meeting objects to be displayed.
 * @returns A JSX element containing the list of interested meetings.
 */
const InterestedAndSuggestions: FC<InterestedMeetings> = ({ meetings }: InterestedMeetings) => {
  const { t } = useTranslation()

  return (
    <div
      className="block lg:w-10/12 w-full px-3"
      data-testid={`meetingsLength-${meetings?.length}`}>
      {meetings.map((meeting: Meeting, index: number) => (
        <Accordion key={'acc' + meeting.id}>
          <AccordionBorder>
            <AccordionItem toggle={'accint' + meeting.id} color="card">
              <EventCard
                keyId={meeting.id}
                link={`/s/meeting/${meeting.meetingDetails.id}`}>
                <EventCard.Image>
                  <CardImagePlaceHolder
                    images={meeting?.meetingDetails?.image}
                  />
                </EventCard.Image>
                <EventCard.Title>
                  <CardTitle
                    keyId={meeting.id}
                    meetingTitle={meeting.meetingDetails.title.length > 25 ? meeting.meetingDetails.title.substring(0, 25) + '...' : meeting.meetingDetails.title}
                  />
                </EventCard.Title>
                <EventCard.SubTitle>
                  <DisplayFormattedDateAndTime
                    displayFromTime={meeting.fromDateTime}
                    displayToTime={meeting.toDateTime}
                    displayDate={meeting.fromDateTime}
                  />
                </EventCard.SubTitle>
                <EventCard.Icons>
                  <DisplayEventTypeIcons
                    meetingDetail={meeting.meetingDetails}
                    meeting={meeting}
                  />
                  <DisplayEventCost keyId={index} meeting={meeting} />
                </EventCard.Icons>

                <EventCard.Actions>
                  <div className={style.editEventText}>
                    <div>
                      <NewIcon
                        icon="CALENDER"
                        size="medium"
                        height="medium"
                        stroke="#545454"
                      />
                    </div>
                    <div>
                      <TextLink
                        link={`/s/meeting/${meeting.meetingDetails.id}`}
                        type="view">
                        <div className="normal-case font-normal">
                          {t('viewEvent')}
                        </div>
                      </TextLink>
                    </div>
                  </div>
                </EventCard.Actions>
              </EventCard>
            </AccordionItem>
          </AccordionBorder>
        </Accordion>
      ))}
    </div>
  )
}

export default InterestedAndSuggestions
