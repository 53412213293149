/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import Icon from '../../Components/base/icon/icon'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useGetAllMeetingsByIdQuery } from '../../Services/meetingApi'
import Loading from '../../Components/base/loading/loading'
import { Meeting } from '../../models/meeting.model'
import NoSession from '../../Components/NoSession'
import { useTranslation } from 'react-i18next'
import { useGetAllParticipantByMeetingQuery } from '../../Services/meetingParticipantApi'

/**
 * A page component that displays a list of meetings related to a meeting details page, and
 * allows navigation to the participants list page for each meeting.
 * @returns A JSX element containing the list of meetings and a frame to display the participants list.
 */
function ParticipantsList() {
  const { meetingDetailId } = useParams()
  const navigate = useNavigate();
  const { t } = useTranslation()
  
  const {data: meetings } = useGetAllMeetingsByIdQuery(meetingDetailId ?? '', { skip: !meetingDetailId })
  

  if (!meetings) {
    return <Loading />
  }
  if (!meetings || meetings.length === 0) {
    return <NoSession>{t('noUsers')}</NoSession>
  }
    
    return (
    <div className="text-black">
      <div className="flex flex-col lg:flex-row w-100">
        <div className="w-full lg:w-1/2 text-base h-full overflow-scroll max-h-96">
          {meetings?.map((meeting, index) => <MeetingListCard key={`p-${meeting.id}`} meeting={meeting} keyId={index}/>)}
        </div>
        <div className='w-full lg:w-3/4 px-4 h-full overflow-x-hidden overflow-y-auto'>
          <Outlet />
        </div>
      </div> 
    </div>
  )

/**
 * A card component that displays the date, time, and number of participants for a meeting.
 * The component is a grid with 4 columns, with the first column containing the date,
 * the second column containing the time, and the third column containing the number of
 * participants. The component is rounded and has a gray border. The component is a
 * link to the participants list page for the meeting.
 *
 * @param {Meeting} meeting The meeting object to display.
 * @param {number} [keyId] A unique identifier to append to the id of the component.
 * @returns {JSX.Element} A JSX element containing the meeting card.
 */
  function MeetingListCard({ meeting, keyId }: { meeting: Meeting, keyId?:number }) {

    const { data: meetingParticipantCount } = useGetAllParticipantByMeetingQuery(meeting.id ?? '', { skip: !meeting.id })

    if (!meetingParticipantCount) {
      return <></>
    }

    return (
      <div className="grid grid-cols-4 items-center bg-white rounded-xl border  dark:border-BeeMG-light-gray py-2 mb-1 mr-1 cursor-default">
        <div className='flex flex-col text-base font-semibold text-center border-r-2 border-BeeMG-light-gray col-span-1'>
          {parseInt((meeting.display_MonthDate).split(" ")[1]) > 9 ? ((meeting.display_MonthDate).split(" ")[1]) : '0' + ((meeting.display_MonthDate).split(" ")[1])}
          <span className='text-BeeMG-yellow'>{(meeting.display_MonthDate).split(" ")[0]}</span>
        </div>
        <div className="flex flex-col justify-between leading-normal col-span-3 ml-3">
          <p className="text-base font-medium tracking-tight">{meeting.display_FromTime}</p>
          <p className="text-base tracking-tight hover:underline underline-offset-2 flex items-center cursor-pointer gap-1"
            id={`tab_participants${keyId}`}
            onClick={() => navigate(`/s/events/my-events/${meetingDetailId}/participants/${meeting.id}`)}>
            <Icon icon={'USERS'} size="small" />
            {t('users')}({meetingParticipantCount && meetingParticipantCount.length + 1})
          </p>
        </div>
      </div>
    )
  }
}

export default ParticipantsList
