/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {getToken} from './../Services/tokenReducer';
import {getCurrentUser} from './../Services/userReducer';
import {useAppDispatch, useAppSelector} from './../Store/hooks';
import {useTranslation} from 'react-i18next';
import {
  preferenceApi,
  useUpdatePreferenceMutation,
  useGetUserPreferenceQuery,
} from '../Services/preferenceApi';
import {useEffect} from 'react';

/**
 * A hook to manage language switching functionality.
 * It provides a function to set the language and the current i18n instance.
 *
 * @return {object} An object containing the setLanguage function and the i18n instance.
 */

export const useLanguageSwitchHook = () => {
  const {i18n} = useTranslation();
  const [changePreference] = useUpdatePreferenceMutation();
  const dispatch = useAppDispatch();

  const {data: preference} = useGetUserPreferenceQuery();
  const currentUser = useAppSelector(getCurrentUser);
  const token = useAppSelector(getToken);

  useEffect(() => {
    if (preference?.language && preference?.language !== i18n.language) {
      i18n.changeLanguage(preference?.language);
    }
  }, [preference?.language]);

  const setLanguage = (language: string) => {
    if (language !== i18n.language) {
      if (currentUser && token) {
        const updatePreference = {...preference, language};
        changePreference(updatePreference)
          .unwrap()
          .then(async () => {
            dispatch(preferenceApi.util.invalidateTags(['Preference']));
          })
          .catch(error => {
            console.log('Update Language error ->', error);
          });
      }
      i18n.changeLanguage(language);
    }
  };
  return {setLanguage, i18n};
};
