/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */


import { useTranslation } from "react-i18next";
import Button from "../../Components/base/button/button";
import { Meeting } from "../../models/meeting.model";
import { useHandleMeetingNavigation } from "../LiveKit/hooks/livekitWebOnlyHooks";
import { useAppSelector } from "../../Store/hooks";
import { getMeetingInfo } from "../../Services/livekitReducer";
import { toastError } from "../../Components/toast";

/**
 * A button that allows users to join a meeting.
 *
 * If the meeting is ongoing, instead show an error message.
 *
 * @param {{meeting: Meeting}} props
 * @prop {Meeting} meeting The meeting to join.
 *
 * @returns {JSX.Element} The button to join the meeting.
 */
export function JoinButton({meeting}: {meeting: Meeting}) {
    const {t}      = useTranslation()
    const { joinMeeting } = useHandleMeetingNavigation()
    const meetingInfo = useAppSelector(getMeetingInfo)

    /**
     * Handles the click event for joining a meeting.
     *
     * If the meeting is already ongoing, show an error message.
     * Otherwise, navigate to the meeting page and join the meeting.
     */
    const handleJoinClick = () => {
        if (meetingInfo && (meetingInfo.isAudioMeeting || meetingInfo.isVideoMeeting)) {
            toastError(t('meetingOngoingDisconnectToJoin'))
        } else {
            joinMeeting(meeting.id)
        }
    }

    return (
        <Button
            id={`btn_join${meeting.id}`}
            size="sm"
            rounded
            color="join"
            onClick={handleJoinClick}
            className="whitespace-nowrap"
        >
            {t('join')}
        </Button>
    )
}
