/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { DependencyList, useEffect, useRef } from "react";
/**
 * The `useDidUpdateEffect` hook is like `useEffect`, except that it does not call the effect
 * function when the component is first mounted. Instead, it calls the effect function
 * every time the component is updated.
 *
 * @param {() => void} fn The effect function to call when the component is updated.
 * @param {DependencyList | undefined} inputs The dependency list. If the dependency list
 * is empty or undefined, the effect function will be called on every render.
 * @returns {void}
 */
export function useDidUpdateEffect(fn: { (): void; (): void; (): void; }, inputs: DependencyList | undefined) {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) fn();
        else didMountRef.current = true;
    }, inputs);
}