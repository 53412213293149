/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { Navbar, NavbarBrand, NavbarNav, NavbarItem2 } from '../Components/base/navbar/navbar'
import LangDropdownMenu from '../Components/LangDropdownMenu'
import companyLogo from '../Assets/Images/logo.svg'
import { HelpLink } from '../Components/HelpLink'

/**
 * A Header component that renders a navbar with a logo, language dropdown, and help link
 *
 * @return {JSX.Element} The rendered header component
 */
const Header = () => {
    return (
        <header className="z-40 bg-white h-20 px-[2.5%]">
            <div>
                <Navbar className="text-black bg-transparent h-24 flex px-3">
                    <NavbarBrand href="/s/home">
                        <img
                            src={companyLogo}
                            alt="logo"
                            className="h-12 w-12 m-1 pt-1"
                            id="btn_logo"
                            data-testid="btn_logo"
                        />
                    </NavbarBrand>
                    <NavbarNav orientation="end">
                        <NavbarItem2>
                            <LangDropdownMenu />
                        </NavbarItem2>
                        <NavbarItem2>
                            <HelpLink />
                        </NavbarItem2>
                    </NavbarNav>
                </Navbar>
            </div>
        </header>
    );
    
}

export default Header