/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState } from 'react'
import {Button} from '../../Components'
import Loading from '../../Components/base/loading/loading'
import {toastError, toastSuccess} from '../../Components/toast'
import {useVerifyEmailHook} from '../../Hooks/VerifyEmailHook'
import {getCurrentUser} from '../../Services/userReducer'
import {useAppSelector} from '../../Store/hooks'
import {Verified} from '../../models/user.model'
import useWindowDimensions from '../../Components/base/carousel/whirligig/utils'
import {useGetSettingValue} from '../../Services/settingReducer'
import NewIcon from '../../Components/base/icon/newIcons'
import OtpInput from 'react-otp-input'
import InitialPage from '../InitialPage'
import { useNavigate } from 'react-router-dom'
import { getCognitoObject } from '../../Services/signUpReducer'

// const OtpInput = lazy(() => import("react-otp-input"));

/**
 * A component for verifying a user's email address.
 *
 * The component renders a simple form with an input field for the user to enter
 * the verification code sent to their email address. The component also renders
 * a button for the user to resend the verification email if they have not
 * received it.
 *
 * When the user submits the form, the component will call the onSubmit function
 * from the useVerifyEmailHook to verify the email address.
 *
 * @returns {JSX.Element} The VerifyEmail component.
 */
function VerifyEmail() {
  const currentUser = useAppSelector(getCurrentUser)
  const [otp, setOtp] = useState('')
  const navigate = useNavigate();
  const imageSize = (size: number) => `${size}px`
  const {height: screenHeight, width: screenWidth} = useWindowDimensions()
  const imageWidth = imageSize(screenWidth / 2)
  const imageHeight = imageSize(screenHeight / 2)
  const baseImageURL = useGetSettingValue('IMAGE_URL')
  const cognitoModel = useAppSelector(getCognitoObject) ?? JSON.parse(localStorage.getItem('SIGNIN_HELPER_COGNITOMODEL') as any);
  const [otpCount, setOtpCount] = useState<number>(0);
  const {
    errorMessage,
    handleSubmit,
    register,
    errors,
    t,
    onSubmit,
    resendEmail,
    showToastMessage,
    setShowToastMessage,
    limitCheck,
    setValue,
  } = useVerifyEmailHook()

  useEffect(() => {
    if (currentUser?.emailVerified === Verified.Complete) {
      toastSuccess(t('yourEmailHasBeenVerified'))
      localStorage.setItem('SIGNIN_HELPER_COGNITOMODEL', 'null')
    }
  }, [currentUser?.emailVerified])

  useEffect(() => {
    if (!cognitoModel && !currentUser) {
      navigate('/')
    }
    
  }, [cognitoModel, currentUser])

  useEffect(() => {
    if (limitCheck) {
      toastError(t('attemptLimitExceededPleaseTryAfterSomeTime'))
    }
  }, [limitCheck])

  useEffect(() => {
    if (showToastMessage) {
      toastSuccess(t('verificationEmailResendSuccessfully'))
      setShowToastMessage(false)
    }
  }, [showToastMessage])

  useEffect(() => {
    setValue('code', otp)
  }, [otp])

  if (currentUser?.emailVerified === Verified.Complete) {
    return <Loading />
  }

  return (
    
      <InitialPage>
      <span
        className="flex items-center h-screen"
        style={{ paddingLeft: `5%` }}>
      <div className="flex items-center flex-col px-2 h-full w-full flex-1 overflow-y-auto justify-center z-30">
        <NewIcon icon="BEEMG_LOGO" stroke='none' className='h-32 w-32 mb-2 mx-auto'/>
        <span data-testid="txt_Email" id="txt_title" className="text-lg sm:text-normal font-semibold mb-4">
          {t('emailVerification')}
        </span>
        <span className="flex items-center flex-col">
          <div className="sm:mx-3 text-black font-semibold">
            <form onSubmit={handleSubmit(onSubmit)}>
              <span id="txt_emailTitle" className="flex justify-start mb-2">
                {t('enterCodeSentToYourEmail')}
              </span>
              <div className="col-span-6 w-full">
                {/* <Suspense fallback={<Loading />}> */}
                  <OtpInput
                    {...register('code')}
                    value={otp}
                    data-testid="input_emailCode"
                    onChange={(e:any)=>{setOtp(e);setOtpCount(e.length)}}
                    numInputs={6}
                    inputStyle={{
                      width: '2.5em',
                      borderRadius: '0.25rem',
                      margin: '0 0.2em',
                      padding: '0.5em 0.5em',
                      fontSize: '1.25em',
                      fontWeight: '600',
                      color: '#374151',
                    }}
                    separator={<span>-</span>}
                    isInputNum={true}
                    errorStyle={{border: '1px solid red'}}
                    hasErrored={
                      (errors?.code as any)?.message || errorMessage
                        ? true
                        : false
                    }
                  />
                {/* </Suspense> */}
              </div>
              <div className="flex flex-row justify-between">
                {errorMessage && (
                  <span
                    id="error_wrongCode"
                    data-testid="error_code"
                    className="flex my-2 text-red-500">
                    {t(errorMessage)}
                  </span>
                )}
                <span className="flex justify-center my-3 text-red-500">
                  {errorMessage ? '' : t((errors?.code as any)?.message)}
                </span>
                <span className="ml-2">
                  <button
                    id="btn_resend"
                    data-testid="btn_resend"
                    className="float-right flex text-red-500 text-lg font-normal hover:underline mt-3"
                    onClick={e => {
                      e.preventDefault()
                      resendEmail()
                    }}>
                    {t('resendMail')} ?
                  </button>
                </span>
              </div>
              <br />
              <span className="flex justify-center mb-2 mt-4">
                <Button
                  id="btn_save"
                  data-testid="btn_save"
                  className="w-64 my-2 mt-4"
                  color="save"
                  disabled={otpCount !== 6}
                  submit>
                  <div>{t('next')+' 3/4 '}</div>
                </Button>
              </span>
            </form>
          </div>
        </span>
      </div>
      </span>
    </InitialPage>
  )
}

export default VerifyEmail
