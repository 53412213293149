/*
 * Copyright © 2024 @Himutsulab All Rights Reserved.
 */
import { format, getUnixTime, parseISO } from 'date-fns';

/**
 * Merge two arrays into a new array with unique elements.
 * @param {array} arr1 The first array.
 * @param {array} arr2 The second array.
 * @returns {array} A new array with unique elements from both arrays.
 */
export const mergeUniqueArray = (arr1, arr2) => {
    if (!arr1) {
        return arr2;
    }
    if (!arr2) {
        return arr1;
    }
    const newArr = [...arr1, ...arr2];
    return [...new Set(newArr)];
}

/**
 * Group an array of messages by their send date.
 * @param {array} messages The array of messages to group.
 * @returns {object} An object with keys representing the day of the messages
 *   and values being arrays of messages on that day.
 */
function groupedDays(messages) {
    return messages.reduce((acc, el, i) => {
        const messageDay = format(new Date(el.dateSend), "MMMM d, yyyy");
        if (acc[messageDay]) {
            return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
        }
        return { ...acc, [messageDay]: [el] };
    }, {});
}

/**
 * Generates a grouped array of messages by their send date.
 * Each group is represented by a date string and an array of messages
 * on that day. The groups are sorted by the most recent date first.
 * The messages within each group are sorted by the most recent time first.
 * @param {array} messages The array of messages to group.
 * @returns {array} An array of grouped messages and dates.
 */
export function generateGroupedMessagesWithDate(messages) {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort((x, y) => getUnixTime(parseISO(format(new Date(y), "MMMM d, yyyy"))) - getUnixTime(parseISO(format(new Date(x), "MMMM d, yyyy"))));
    const items = sortedDays.reduce((acc, date) => {
        const sortedMessages = days[date].sort((x, y) => new Date(x.dateSend) - new Date(y.dateSend));
        return acc.concat([{ type: 'date', date, messageId: date }, ...sortedMessages]);
    }, []);
    return items;
}
