/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {Auth} from 'aws-amplify';

/**
 * Signs out the current authenticated user.
 *
 * @return {Promise<boolean>} A promise that resolves to true if the sign out is successful, or rejects with an error if it fails.
 */

export function logout() {
  return new Promise((resolve, reject) => {
    Auth.signOut()
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
}
