/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {MeetingParticipant} from './../models/meetingParticipant.model';
import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  meetingParticipantApi,
  useUpdateNewPaidGuestEmailMutation,
} from '../Services/meetingParticipantApi';
import {inviteValidationSchema} from '../Utils/validation';
import {useAppDispatch} from '../Store/hooks';

/**
 * A custom React hook for managing the invitation of guests to a meeting.
 * It provides functionality for updating the guest list, handling form submissions,
 * and displaying error messages.
 *
 * @return {object} An object containing various properties and functions for managing the invitation process.
 */

export const useInviteGuestHook = () => {
  const dispatch = useAppDispatch();

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    formState: {errors, isDirty, isValid},
  } = useForm<{inviteEmail: string}>({
    mode: 'onChange',
    resolver: yupResolver(inviteValidationSchema),
  });

  const [
    updateGuestInvite,
    {
      isSuccess: updateGuestSuccess,
      isLoading: updateGuestLoading,
      data: partcipantData,
    },
  ] = useUpdateNewPaidGuestEmailMutation();
  const [meetingParticipant, setMeetingParticipant] =
    useState<MeetingParticipant>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [notify, setNotify] = useState(false);
  const [invitedguest, setInvitedguest] = useState<any>();
  const [viewGuest, setViewGuest] = useState<boolean>(false);

  useEffect(() => {
    if (meetingParticipant) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingParticipant]);

  const resetForm = () => {
    reset({inviteEmail: ''});
    setErrorMessage('');
  };

  const updateGuestLists = (id: string) => {
    setViewGuest(!viewGuest);
    const res = dispatch(
      meetingParticipantApi.endpoints.getAllInvitedGuests.initiate(id),
    );

    res.then((val: any) => {
      const InviteData = val.data;
      setInvitedguest(InviteData);
    });
  };

  const updateGuestList = () => {
    const values = getValues();
    if (meetingParticipant) {
      const args = {
        meetingParticipantId: meetingParticipant.id,
        email: values.inviteEmail,
      };
      updateGuestInvite(args).then((res: any) => {
        if (res?.data.some((user: any) => user.error)) {
          res?.data.map((user: any) => {
            if (!errorMessage) {
              setErrorMessage(user.error);
            } else if (errorMessage !== user.error) {
              setErrorMessage(user.error);
            } else {
              setErrorMessage('');
            }
          });
          if (!notify) {
            setNotify(true);
          }
        }
      });
    }
  };

  return {
    control,
    getValues,
    errorMessage,
    notify,
    setNotify,
    errors,
    setErrorMessage,
    updateGuestList,
    resetForm,
    handleSubmit,
    reset,
    isDirty,
    isValid,
    setMeetingParticipant,
    register,
    meetingParticipant,
    updateGuestSuccess,
    updateGuestLoading,
    invitedguest,
    viewGuest,
    updateGuestLists,
    partcipantData,
  };
};
