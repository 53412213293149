/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */


import { t } from "i18next";
import Avatar from "../../Components/base/avatar/avatar";
import Button from "../../Components/base/button/button";
import Field from "../../Components/base/field/field";
import Icon from "../../Components/base/icon/icon";
import { toastSuccess } from "../../Components/toast";
import { useEventReviewsHook } from "../../Hooks/MyEvents/EventReviewsHook";
import { Feedback } from "../../models/feedback.model";
import StarRating from "./StarRating";
import { useEffect, useState } from 'react';
import { getCurrentUser } from "../../Services/userReducer";
import { useSelector } from "react-redux";
import { useUpdateFeedbackMutation } from "../../Services/feedbackApi";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DisplayFormattedDate } from "../../Components/formattedDateAndTime";

interface _Props {
  review: Feedback
}

/**
 * A component for displaying a review card with the option to reply.
 *
 * @param {props} props - The component props.
 * @param {Feedback} props.review - The review item to display.
 * @return {JSX.Element} The review card component.
 */
const RequestForMeetingReviewCard = (props: _Props) => {
  const { review } = props;
  const currentUser = useSelector(getCurrentUser);
  const hostId = review?.user?.id ? review?.user?.id : "";
  const [showReply, setShowReply] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const { pathname } = useLocation();

  const [updateReply, { isSuccess: replyFeedbackSuccess, isLoading: replyFeedbackLoading }] = useUpdateFeedbackMutation()

  /**
   * A function to update a reply for a given feedback.
   *
   * @param {object} values - The values to update the reply with.
   * @return {void} Nothing.
   */
  const handleUpdateReply = (values: any) => {
    if (review) {
      const inviteRes = Object.assign({}, review)
      inviteRes.reply = values.reply
      updateReply(inviteRes).then(() => {
        setShowReply(false)
      })
    }
  }

  /**
   * A function to expand the review text.
   *
   * @return {void} Nothing.
   */
  const handleReadMore = () => {
    setIsExpanded(true);
  };

  /**
   * A function to collapse the review text.
   *
   * @return {void} Nothing.
   */
  const handleReadLess = () => {
    setIsExpanded(false);
  };

  const reviewText = review?.review || '';
  const displayText =
    isExpanded || reviewText.length <= 200
      ? reviewText
      : `${reviewText.slice(0, 200)}...`;

  const [feedback, setFeedback] = useState<Feedback | null>(null);
  /**
   * A function to show the reply form when the reply button is clicked.
   * @return {void} Nothing.
   */
  const handleReplyClick = () => {
    setShowReply(true);
  };

  const displayWords = isExpanded
    ? review?.review.split(' ')
    : review?.review.split(' ').slice(0, 25);

  return (
    <div className="border border-transparent hover:border-gray-200 rounded-2xl w-auto">
      <div className="flex flex-row w-100 mb-1 mt-4">
        <div className="w-20 py-1 mx-5">
          <Avatar
            size="medium"
            images={review?.user?.image}
            avatar={review?.user?.avatar}
            anonymous={review?.user?.anonymous}
          />
        </div>
        <div className="flex flex-col gap-y-1 flex-1 w-auto">
          <div className="flex flex-row gap-x-1 w-20">
            <div data-testid={`txt_reviewName${review?.id}`} className="text-lg font-semibold p-0 leading-5">
              {review?.name}
            </div>
            <div data-testid={`txt_reviewRating${review?.id}`}>
              {review?.rating > 0 && <StarRating rating={review.rating} />}
            </div>
          </div>
          <div data-testid={`txt_reviewDate${review?.id}`} className="text-xs">
            <DisplayFormattedDate displayDate={review?.display_createdAt}/>
          </div>
          <div >
            <div data-testid={`txt_review${review?.id}`} className="text-sm mr-1 w-5/6 break-all">
              {displayText}
            </div>
            {review?.review.length > 200 &&
              <div className="text-sm text-gray-400 cursor-pointer">
                {isExpanded ? (
                  <button data-testid={`btn_readLess${review?.id}`} onClick={handleReadLess}>Read Less</button>
                ) : (
                  <button data-testid={`btn_readMore${review?.id}`} onClick={handleReadMore}>Read More</button>
                )}
              </div>}
          </div>
          {review && review.reply?.length > 0 && (
            <div className="flex gap-x-1 ">
              <div>
                <Icon icon="RIGHT" size="small" height="small" />
              </div>
              <div>
                <span id={`txt_reply${feedback?.id}`}
                >
                  <span>{review.reply}</span>
                </span>
              </div>
            </div>)}
        </div>

        {!showReply && review && review.reply == null && (pathname === '/s/events/request-events/feedbacks') &&  (
          <div className="flex flex-col items-left justify-center min-w-cardRightText text-normal mb-1 mr-2">
            <span
              id={`btn_reply`}
              data-testid={`btn_reply`}
              className="cursor-pointer w-8"
              onClick={() => handleReplyClick()}
            >
              <Icon icon="REPLY" size="medium" height="medium" />
              <span  data-testid="btn_reply" className="text-xs">{t('reply')}</span>
            </span>
          </div>
        )}

        {review && showReply &&
          <RFMReply feedback={review} setShowReply={setShowReply} />}
      </div>
    </div >
  )
}
/**
 * A component to display a reply form for a given feedback.
 *
 * @param {props} props - The component props.
 * @param {Feedback} props.feedback - The feedback item to display.
 * @param {Function} props.setShowReply - A callback function to set the show reply state.
 * @return {JSX.Element} The reply form component.
 */
const RFMReply = ({
  feedback,
  setShowReply,
}: {
  feedback: Feedback
  setShowReply: any
}) => {
  const { t } = useTranslation()
  const { meetingId } = useParams()
  const {
    replyFeedbackSuccess,
    register,
    reset,
    isValid,
    errors,
    reply,
    setReply,
    showReply,
    setValue,
    trigger,
    handleSubmit,
    handleUpdateReply,
    replyFeedbackLoading,
  } = useEventReviewsHook({ meetingId: meetingId ? meetingId : '', feedback })

  const style = {
    reviewCard: `pl-2 min-w-scheduleCard overflow-hidden mt-2`,
    reviewDetailText: `flex font-normal text-xs text-justify pr-2 items-center`,
    replyText: `flex flex-col items-center justify-center min-w-cardRightText text-normal uppercase mb-1`,
  }

  useEffect(() => {
    if (replyFeedbackSuccess) {
      toastSuccess(t('repliedSuccessfully'))
      setShowReply(false)
    }
  }, [replyFeedbackSuccess, t])

  return (
    <>
      <div className="my-1">
        {!showReply &&
          (feedback.reply?.length <= 0 || feedback.reply === null) && (
            <span
              className={style.reviewDetailText}
              data-testid={`txt_suggestion${feedback?.id}`}
              id={`txt_suggestion${feedback?.id}`}
              onClick={() => {
                setReply('Thanks')
                setValue('reply', 'Thanks')
                trigger()
                setShowReply(true)
              }}>
              <Icon icon="LOGOUT" size="small" height="small" />
              Thanks
            </span>
          )}
      </div>
      <form data-testid="btn_reply" onSubmit={handleSubmit(handleUpdateReply)}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 mr-10 justify-center ">
          <div className="md:col-span-2">
            <Field
              {...register('reply')}
              name="reply"
              id={`input_reply${feedback.id}`}
              type="text"
              autoFocus
              placeholder={t('reply') + ' *'}
              defaultValue={reply}
              onInput={(event) =>
                setReply((event.target as HTMLInputElement).value)
              }
              error={errors?.reply?.message}
            />
          </div>
          <div className="md:col-span-1 flex justify-center items-center">
            <Button
              data-testid="btn_cancel"
              id="btn_cancel"
              size="md"
              color="save"
              onClick={() => {
                setShowReply(false);
                setReply('');
                reset();
              }}
            >
              {t('cancel')}
            </Button>
          </div>
          <div className="md:col-span-1 flex justify-center items-center">
            <Button
              data-testid="btn_submit"
              id="btn_submit"
              size="md"
              color="save"
              submit
              disabled={!isValid || replyFeedbackLoading}>
              {' '}
              {t('submit')}{' '}
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}

export default RequestForMeetingReviewCard;