/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Icon from '../../Components/base/icon/icon'
import { ToolTip } from '../../Components/base/tooltip/tooltip'
import { toastError } from '../../Components/toast'
import { useChatErrorAndAllowed, useChatUserBlockedHook, useCheckOfflineMessages } from '../../Hooks/MessageHooks'
import { getCurrentChatUser, getSendVideoChatRequest, sendTextMessage, sendVideoRequest, setSendVideoChatRequest } from '../../Store/Chat/chat'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import NewIcon from '../../Components/base/icon/newIcons'

/**
 * MessageTypeArea
 *
 * This component displays a form with a text input and buttons for sending text messages and video requests.
 *
 * It uses the following hooks:
 * - useForm() for managing the form state
 * - useChatErrorAndAllowed() for checking if the user is allowed to send messages
 * - useChatUserBlockedHook() for checking if the user is blocked by the current chat user
 * - useCheckOfflineMessages() for checking if there are offline messages
 *
 * It also uses the following selectors:
 * - getCurrentChatUser() for getting the current chat user
 * - getSendVideoChatRequest() for getting the send video chat request state
 *
 * It dispatches the following actions:
 * - sendTextMessage() for sending a text message
 * - sendVideoRequest() for sending a video request
 * - setSendVideoChatRequest() for setting the send video chat request state
 *
 * It renders:
 * - A text input for typing messages
 * - A button for sending the message
 * - A button for sending a video request
 *
 * It also renders a tooltip for the video request button
 */
function MessageTypeArea() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch()

  const currentChatUser = useAppSelector(getCurrentChatUser)
  const sendVideoChatRequest = useAppSelector(getSendVideoChatRequest)

  const { showUpdatePreferences, showCannotSend, handleMessageSize, maxTextSize, videoLimitExceeded, hasActiveVideoRequest } = useChatErrorAndAllowed()
  const { isBlockedByMe, isBlockedMe } = useChatUserBlockedHook()
  const { register, handleSubmit, resetField, getValues } = useForm()
  const isSendDisabled = ((!showUpdatePreferences && !showCannotSend) === false || isBlockedByMe(currentChatUser?.id))

  useCheckOfflineMessages()

  useEffect(() => {
    if (sendVideoChatRequest && currentChatUser && videoLimitExceeded !== undefined && videoLimitExceeded === false) {
      if (hasActiveVideoRequest) {
        dispatch(setSendVideoChatRequest(false))
        handleActiveVideoRequest()
        return;
      }
      dispatch(sendVideoRequest({ toUserId: currentChatUser.id, isBlocked: isBlockedMe(currentChatUser?.id) }))
      dispatch(setSendVideoChatRequest(false))
    }
  }, [currentChatUser, sendVideoChatRequest, videoLimitExceeded, hasActiveVideoRequest])

  useEffect(() => {
    if (currentChatUser && getValues('message') && getValues('message')?.length > 0) {
      resetField('message')
      handleMessageSize("")
    }
  }, [currentChatUser])

  /**
   * Called when the user submits a message.
   * @param {any} data The object containing the message text
   */
  const onSubmit = (data: any) => {
    console.log('01. onSubmit', data);

    if (!isSendDisabled && data.message && data.message?.trim() && data.message?.trim().length <= maxTextSize) {
      console.log('02. inside if', data, isSendDisabled);    
      dispatch(sendTextMessage({ messageText: (data?.message).trim(), isBlocked: isBlockedMe(currentChatUser?.id) }))
      .then((response: any) => {
        console.log('03. response', response);
      })
      .catch((error: any) => {
        console.log('04. error', error);
      })

      resetField('message')
      handleMessageSize("")
    }
    else {
      console.log('05. else error', data, isSendDisabled);
    }
  }

  /**
   * Called when the user wants to send a video request.
   * It dispatches the sendVideoRequest() action.
   * It also checks if the user has exceeded the video request limit.
   * If the user has exceeded the limit, it does not send the request.
   * If the user is blocked by the other user, it also does not send the request.
   */
  const sendVideoRequestToServer = () => {
    console.log('05. sendVideoRequestToServer');
    
    if (currentChatUser) {
      console.log('06. inside if', currentChatUser);
      
      if (videoLimitExceeded) {
        console.log('07. inside if', videoLimitExceeded);
        return;
      }
      console.log('08. after inside if', videoLimitExceeded);
      
      dispatch(sendVideoRequest({ toUserId: currentChatUser.id, isBlocked: isBlockedMe(currentChatUser?.id) }))
      .then((response: any) => {
        console.log('09. response', response);
      })
      .catch((error: any) => {
        console.log('10. error', error);
      })
    }
  }

  /**
   * Handles the case when the user has an active video request and
   * wants to send another one. It shows an error message and returns.
   */
  const handleActiveVideoRequest = () => {
    toastError(t('youHaveAnActiveRequestAlready'))
    return;
  }

  return (
    <div className="w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-row h-12 py-1">
        <input
          maxLength={parseInt(maxTextSize) + 1}
          className={`block w-full p-1 pl-3 mr-2 text-xs outline-none font-normal focus:border-orange-200 ${(showUpdatePreferences || showCannotSend) && `bg-gray-200`}`}
          style={{
            border: '1px solid #ced4da',
            borderRadius: '5px',
          }}
          type={''}
          {...register('message', {
        /**
         * Calls handleMessageSize with the new value of the input field
         * to update the chatMaxCharExceeded state whenever the user types
         * in the message input field.
         * @param {React.ChangeEvent<HTMLInputElement>} e The event fired when the input field changes
         */

            onChange: (e) => {
              handleMessageSize(e.target.value)
            },
            required: 'message is required',
          })}
          id="input_typeMessage"
          data-testid="input_typeMessage"
          placeholder={t('startTyping')}
          name="message"
          disabled={isSendDisabled}
        />
        <div
          className="flex flex-row gap-5">
          <button id="btn_sendMessage" data-testid="btn_sendMessage" type="submit" className="p-1 block md:hidden" disabled={isSendDisabled}>
            <div>
              <ToolTip tip='send' keyId='send'>
                <Icon icon="SEND" size="medium" height="small" className='cursor-pointer' />
              </ToolTip>
            </div>
          </button>
          <button
            id="btn_sendVideoRequest"
            data-testid="btn_sendVideoRequest"
            disabled={isSendDisabled || videoLimitExceeded}
            onClick={(e) => {
              e.preventDefault()
              if (hasActiveVideoRequest) {
                handleActiveVideoRequest();
                return;
              }
              sendVideoRequestToServer()
            }}
            className="p-1">
            <div>
              <ToolTip tip='chatVideoRequest' keyId='chatVideoRequest'>
                <NewIcon icon="VIDEO_CAM" size="medium" height="small" className='cursor-pointer' />
              </ToolTip>
            </div>
          </button>
        </div>
      </form>
    </div>
  )
}

export default MessageTypeArea
