/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-unused-vars */
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../Store';
import {
  ConnectParamsType,
  LiveKitChat,
  LiveKitCommand,
  LiveKitEventsType,
  LiveKitReducerInitType,
  LiveKitUser,
  UserType,
} from '../models/livekitTypes';
import {MeetingType} from '../models/meeting.model';
import {VideoMeeting} from '../models/videoMeeting.model';
import {
  isHostOrCohost,
  liveKitEventAction,
} from './../Services/livekit/functions/liveKitHelpers';
import {videoMeetingApi} from './videoMeetingApi';

const initialStateValue: LiveKitReducerInitType = {
  //TODO remove unwanted fields
  meetingInfo: {} as VideoMeeting,
  isMeetingConnected: false,
  isMeetingJoined: false,
  showMeetingGuide: false,
  isMeetingMinimized: false,
  showMeeting: false,
  isMeetingChatOpen: false,
  showEndConfirmation: false,
  videoChatFullScreen: false,
  currentMeetingUser: {
    isAllowedUnmute: false,
    isHandRaised: false,
  } as LiveKitUser,
  remoteMeetingUsers: {},
  isAddedToGroup: false,
  liveKitChats: [],
  unreadMeetingChat: false,
  isMeetingEnded: false,
  connectParams: {} as ConnectParamsType,
  pinnedParticipantId: undefined,
  showStats: false,
  showAllowedUnmuteToast: false,

  joinTimeError: '',
  isAnonymous: false,
  displayName: '',
};

/**
 * Creates a Redux slice for managing the state of the LiveKit application.
 *
 * @return {Object} An object containing the Redux slice with the following properties:
 *   - `name`: The name of the slice.
 *   - `initialState`: The initial state of the slice.
 *   - `reducers`: An object containing the reducer functions for updating the state.
 */

export const livekitSlice = createSlice({
  name: 'livekit',
  initialState: initialStateValue,
  reducers: {
    setCurrentMeetingUser: (
      state,
      action: PayloadAction<Partial<LiveKitUser>>,
    ) => {
      state.currentMeetingUser = {
        ...state.currentMeetingUser,
        ...action.payload,
      };
    },
    changeMuteState: (state, action: PayloadAction<boolean>) => {
      if (
        (state.currentMeetingUser.userType === UserType.Host ||
          state.currentMeetingUser.userType === UserType.CoHost) &&
        !action.payload
      ) {
        state.currentMeetingUser.isHandRaised = false;
      }
      state.currentMeetingUser.isMuted = action.payload;
    },
    changeVideoMuteState: (state, action: PayloadAction<boolean>) => {
      state.currentMeetingUser.isVideoMuted = action.payload;
    },
    changeHandRaiseState: (state, action: PayloadAction<boolean>) => {
      state.currentMeetingUser.isHandRaised = action.payload;
    },
    toggleMeetingChat: state => {
      state.isMeetingChatOpen = !state.isMeetingChatOpen;
      if (state.isMeetingChatOpen) {
        state.unreadMeetingChat = false;
      }
    },
    changeIsUnmuteAllowed: (state, action: PayloadAction<boolean>) => {
      state.currentMeetingUser.isAllowedUnmute = action.payload;
    },
    setShowMeetingGuide: (state, action: PayloadAction<boolean>) => {
      state.showMeetingGuide = action.payload;
    },
    setMeetingMinimized(state, action: PayloadAction<boolean>) {
      state.isMeetingMinimized = action.payload;
      if (action.payload === true) {
        state.isMeetingChatOpen = !action.payload;
      }
    },

    setShowMeeting(
      state,
      action: PayloadAction<{
        showMeeting: boolean;
        meetingId: string;
        isAnonymous: boolean;
        displayName: string;
      }>,
    ) {
      state.showMeeting = action.payload.showMeeting;
      state.isAnonymous = action.payload.isAnonymous;
      state.displayName = action.payload.displayName;
      state.meetingInfo.meetingId = action.payload.meetingId;

      if (action.payload.showMeeting === false) {
        state.isMeetingEnded = false;
        state.isMeetingConnected = false;
        state.isMeetingJoined = false;
        state.isAnonymous = false;
        state.displayName = '';
        state.meetingInfo = {} as VideoMeeting;
      }
    },
    setShowEndConfirmation(state, action: PayloadAction<boolean>) {
      state.showEndConfirmation = action.payload;
    },
    setVideoChatFullScreen(state, action: PayloadAction<boolean>) {
      state.videoChatFullScreen = action.payload;
    },
    setIsAddedToGroup(state, action: PayloadAction<boolean>) {
      state.isAddedToGroup = action.payload;
    },
    setMeetingConnected(state, action: PayloadAction<boolean>) {
      state.isMeetingConnected = action.payload;
    },
    setMeetingJoined(state, action: PayloadAction<boolean>) {
      state.isMeetingJoined = action.payload;
    },
    setMeetingEnded(state, action: PayloadAction<boolean>) {
      state.isMeetingEnded = action.payload;
    },
    setConnectParams(state, action: PayloadAction<ConnectParamsType>) {
      state.connectParams = action.payload;
    },
    sortRemoteUsersByHandRaise(state) {
      Object.values(state.remoteMeetingUsers).sort(
        (user1, user2) =>
          Number(user2.isHandRaised) - Number(user1.isHandRaised),
      );
    },
    setPinnedParticipantId(state, action: PayloadAction<string | undefined>) {
      state.pinnedParticipantId = action.payload;
    },
    setShowStats(state, action: PayloadAction<boolean>) {
      state.showStats = action.payload;
    },
    setShowAllowedUnmuteToast(state, action: PayloadAction<boolean>) {
      state.showAllowedUnmuteToast = action.payload;
    },
    setJoinTimeError(state, action: PayloadAction<string>) {
      state.joinTimeError = action.payload;
    },
    setIsAnonymous(state, action: PayloadAction<boolean>) {
      state.isAnonymous = action.payload;
    },
    // setDisplayName(state, action: PayloadAction<string>) {
    //   state.displayName = action.payload
    // },

    setMeetingInfo(state, action: PayloadAction<VideoMeeting>) {
      state.meetingInfo = action.payload;

      if (action.payload.userType === 'Host') {
        state.currentMeetingUser.userType = UserType.Host;
      } else if (action.payload.userType === 'CoHost') {
        state.currentMeetingUser.userType = UserType.CoHost;
      } else {
        state.currentMeetingUser.userType = UserType.Participant;
      }

      if (
        action.payload &&
        !state.isMeetingConnected &&
        !state.isMeetingJoined
      ) {
        if (
          action.payload.meetingType === MeetingType.ChatVideoRequest ||
          action.payload.userType === 'Host' ||
          action.payload.userType === 'CoHost'
        ) {
          state.currentMeetingUser.isAllowedUnmute = true;
        } else {
          state.currentMeetingUser.isAllowedUnmute = false;
        }
      }
      state.showMeetingGuide = action.payload.isAudioMeeting
        ? action.payload.userType === ('Host' || 'CoHost')
          ? true
          : false
        : false;
      state.joinTimeError = '';
    },

    resetLiveKitState: () => initialStateValue,
    resetJoinTimeError: state => {
      state.joinTimeError = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(liveKitEventAction, (state, action) => {
      switch (action.payload.type) {
        case LiveKitEventsType.USER_JOINED: {
          if (action.payload) {
            const remoteUser = action.payload.data as LiveKitUser;
            const alreadyExists = Object.keys(state.remoteMeetingUsers).find(
              participantId => remoteUser.participantId,
            );

            if (!alreadyExists) {
              state.remoteMeetingUsers[remoteUser.participantId] = remoteUser;
            }
          }
          break;
        }
        case LiveKitEventsType.USER_LEFT: {
          if (action.payload) {
            const leftParticipantId = action.payload.data as string;

            delete state.remoteMeetingUsers[leftParticipantId];
          }
          break;
        }
        case LiveKitEventsType.RECEIVED_USER_INFO: {
          if (action.payload?.data) {
            const command = action.payload.data as LiveKitCommand;

            const remoteUser = command.params as LiveKitUser;

            state.remoteMeetingUsers[remoteUser.participantId] = remoteUser;
            sortRemoteUsersByHandRaise();
          }
          break;
        }
        case LiveKitEventsType.RECEIVED_END_FOR_ALL: {
          if (action.payload) {
            state.isMeetingEnded = true;
          }
          break;
        }
        case LiveKitEventsType.RECEIVED_MUTE_ALL: {
          if (action.payload) {
            state.currentMeetingUser.isMuted = true;
            state.currentMeetingUser.isAllowedUnmute = false;
          }
          break;
        }
        case LiveKitEventsType.RECEIVED_MUTE_ONE: {
          if (action.payload) {
            state.currentMeetingUser.isMuted = true;
            state.currentMeetingUser.isAllowedUnmute = false;
          }

          break;
        }
        case LiveKitEventsType.RECEIVED_HAND_RAISED: {
          if (action.payload && isHostOrCohost(state.currentMeetingUser)) {
            const command = action.payload.data as LiveKitCommand;
            const handRaisedUser = command.params as LiveKitUser;

            state.remoteMeetingUsers[handRaisedUser.participantId] =
              handRaisedUser;
          }
          sortRemoteUsersByHandRaise();
          break;
        }
        case LiveKitEventsType.RECEIVED_UNMUTE_ALLOWED: {
          if (action.payload) {
            state.currentMeetingUser.isHandRaised = false;
            state.currentMeetingUser.isAllowedUnmute = true;
          }
          break;
        }
        case LiveKitEventsType.RECEIVED_CHAT_MESSAGE: {
          if (action.payload) {
            const command = action.payload.data as LiveKitCommand;

            const message = command.params as LiveKitChat;

            const isExists = state.liveKitChats.find(
              m => m.chatId === message.chatId,
            );

            if (isExists) {
              return;
            }

            if (message.senderUserId !== state.currentMeetingUser.userId) {
              if (!state.isMeetingChatOpen && !state.unreadMeetingChat) {
                state.unreadMeetingChat = true;
                message.unread = true;
              }
            }

            if (state.isMeetingChatOpen) {
              state.liveKitChats.forEach(message => {
                message.unread = false;
              });
              message.unread = false;
            }

            state.liveKitChats.push(message);
          }

          break;
        }
        default:
          break;
      }
    });
    builder.addMatcher(
      videoMeetingApi.endpoints.getMeetingToken.matchFulfilled,
      (state, action) => {
        state.meetingInfo = action.payload;

        if (action.payload.userType === 'Host') {
          state.currentMeetingUser.userType = UserType.Host;
        } else if (action.payload.userType === 'CoHost') {
          state.currentMeetingUser.userType = UserType.CoHost;
        } else {
          state.currentMeetingUser.userType = UserType.Participant;
        }

        if (
          action.payload &&
          !state.isMeetingConnected &&
          !state.isMeetingJoined
        ) {
          if (
            action.payload.meetingType === MeetingType.ChatVideoRequest ||
            action.payload.userType === 'Host' ||
            action.payload.userType === 'CoHost'
          ) {
            state.currentMeetingUser.isAllowedUnmute = true;
          } else {
            state.currentMeetingUser.isAllowedUnmute = false;
          }
        }
        state.showMeetingGuide = action.payload.isAudioMeeting
          ? action.payload.userType === ('Host' || 'CoHost')
            ? true
            : false
          : false;
        state.joinTimeError = '';
      },
    );

    builder.addMatcher(
      videoMeetingApi.endpoints.updateStartTimeAPI.matchFulfilled,
      (state, action) => {
        const _meeting = action.payload as any;
        if (
          action.payload &&
          state.meetingInfo.actualStartTime !== _meeting.actualStartAt
        ) {
          state.meetingInfo.actualStartTime = _meeting.actualStartAt;
          state.meetingInfo.expectedEndTime = _meeting.expectedEndAt;
        }
        state.joinTimeError = '';
      },
    );

    builder.addMatcher(
      videoMeetingApi.endpoints.getMeetingToken.matchRejected,
      (state, action) => {
        const errorMessage = (action.payload as any)?.data?.message;
        if (errorMessage) {
          if (action.type.endsWith('rejected') && state.joinTimeError === '') {
            state.joinTimeError = (action.payload as any)?.data?.message;
            if (state.joinTimeError === '') {
              return {
                ...state,
                joinTimeError: errorMessage,
              };
            }
          }
        }
        return state;
      },
    );
  },
});

export const getCurrentMeetingUser = (state: RootState): LiveKitUser =>
  state.livekit?.currentMeetingUser;
export const getMeetingInfo = (state: RootState): VideoMeeting =>
  state.livekit?.meetingInfo;
export const getConnectParams = (state: RootState): ConnectParamsType =>
  state.livekit?.connectParams;
export const getMeetingConnected = (state: RootState): boolean =>
  state.livekit?.isMeetingConnected;
export const getMeetingJoined = (state: RootState): boolean =>
  state.livekit?.isMeetingJoined;
export const getRemoteMeetingUsers = (
  state: RootState,
): {[key: string]: LiveKitUser} => state.livekit?.remoteMeetingUsers;
export const getShowMeeting = (state: RootState): boolean =>
  state.livekit?.showMeeting;
export const getIsMeetingEnded = (state: RootState): boolean =>
  state.livekit?.isMeetingEnded;
export const getIsMeetingMinimized = (state: RootState): boolean =>
  state.livekit?.isMeetingMinimized;
export const getMeetingChats = (state: RootState): LiveKitChat[] =>
  state.livekit?.liveKitChats;
export const getIsMeetingChatOpen = (state: RootState): LiveKitChat[] =>
  state.livekit?.isMeetingChatOpen;
export const getUnreadMeetingChat = (state: RootState): boolean =>
  state.livekit?.unreadMeetingChat;
export const getShowEndConfirmation = (state: RootState): boolean =>
  state.livekit?.showEndConfirmation;
export const getPinnedParticipantId = (state: RootState): string | undefined =>
  state.livekit?.pinnedParticipantId;
export const getVideoChatFullScreen = (state: RootState): boolean =>
  state.livekit?.videoChatFullScreen;
export const getShowStats = (state: RootState): boolean =>
  state.livekit?.showStats;
export const getShowAllowedUnmuteToast = (state: RootState): boolean =>
  state.livekit?.showAllowedUnmuteToast;
export const getJoinTimeError = (state: RootState): string =>
  state.livekit?.joinTimeError;
export const getIsAnonymous = (state: RootState): boolean =>
  state.livekit?.isAnonymous;
export const getDisplayName = (state: RootState): string =>
  state.livekit?.displayName;
export const {
  changeHandRaiseState,
  changeIsUnmuteAllowed,
  changeMuteState,
  changeVideoMuteState,
  setCurrentMeetingUser,
  setShowMeetingGuide,
  setIsAddedToGroup,
  setMeetingMinimized,
  setShowEndConfirmation,
  setShowMeeting,
  setVideoChatFullScreen,
  toggleMeetingChat,
  resetLiveKitState,
  setConnectParams,
  setMeetingConnected,
  setMeetingEnded,
  setMeetingJoined,
  sortRemoteUsersByHandRaise,
  setPinnedParticipantId,
  setShowStats,
  setShowAllowedUnmuteToast,
  setJoinTimeError,
  setMeetingInfo,
  setIsAnonymous,
} = livekitSlice.actions;

export default livekitSlice.reducer;
