/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { Track } from 'livekit-client'
import React from 'react'
import { supportsScreenSharing } from '@livekit/components-core'
import { useLocalParticipantPermissions } from '@livekit/components-react'
import { CustomTrackToggle } from './LivekitCustomTrackToggle'
import { CustomDisconnectButton } from './CustomDisconnectButton'
import { CustomMediaDeviceMenu } from './CustomMediaSelectMenu'
import { CustomChatToggle } from './CustomChatToggleButton'
import { CustomHandRaiseToggle } from './CustomHandRaiseToggleButton'
import CustomSettingsMenu from './CustomSettingsMenu'
import { useControlBarAccessHooks, usePermissionCheckHooks } from '../livekitHooks'
import { useCustomRoomContext } from '../room/CustomRoomContext'
import CustomPipButton from './CustomPipButton'

/** @public */
export type ControlBarControls = {
  handRaise?: boolean
  microphone?: boolean
  camera?: boolean
  chat?: boolean
  screenShare?: boolean
  leave?: boolean
  settings?: boolean
}

/** @public */
export interface ControlBarProps extends React.HTMLAttributes<HTMLDivElement> {
  variation?: 'minimal' | 'verbose' | 'textOnly'
  controls?: ControlBarControls
  isPip?: any
  onLeave?: () => void
  onEnd?: () => void
  isPipEnabled?: boolean
}

export const buttonStyles =
  'py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-full first:ml-0 last:rounded-r-full border font-medium bg-gray-200 text-gray-700 align-middle hover:bg-gray-50 focus:z-10 transition-all text-sm'
export const buttonIconSize = 'small'

/**
 * The ControlBar prefab component gives the user the basic user interface
 * to control their media devices and leave the room.
 *
 * @param {ControlBarProps} props - The properties for the control bar.
 * @param {string} props.variation - The variation of the control bar, either 'minimal', 'verbose', or 'textOnly'.
 * @param {ControlBarControls} props.controls - An object specifying which controls to display.
 * @param {boolean} props.isPip - Whether the control bar is in picture-in-picture mode.
 * @param {boolean} props.isPipEnabled - Whether picture-in-picture mode is enabled.
 * @param {React.HTMLAttributes<HTMLDivElement>} props...props - Additional props for the control bar div.
 * @return {JSX.Element} The JSX element for the control bar.
 */

export function LiveKitCustomControlBar({ variation, controls, isPip, isPipEnabled, ...props }: ControlBarProps) {
  // const layoutContext = useMaybeLayoutContext();
  const { isHostOrCoHostAvailableRoom } = useControlBarAccessHooks()
  const { isAudioPermissionGrantedError, isVideoPermissionGrantedError } = usePermissionCheckHooks()

  // React.useEffect(() => {
  //   if (layoutContext?.widget.state?.showChat !== undefined) {
  //     setIsChatOpen(layoutContext?.widget.state?.showChat);
  //   }
  // }, [layoutContext?.widget.state?.showChat]);
  // const isTooLittleSpace = useMediaQuery(`(max-width: ${isChatOpen ? 1000 : 760}px)`);

  // const defaultVariation = isTooLittleSpace ? 'minimal' : 'verbose';
  // variation ??= defaultVariation;

  const visibleControls = { leave: true, ...controls }

  const localPermissions = useLocalParticipantPermissions()

  if (!localPermissions) {
    visibleControls.camera = false
    visibleControls.chat = false
    visibleControls.microphone = false
    visibleControls.screenShare = false
    visibleControls.settings = false
  } else {
    // visibleControls.camera ??= localPermissions.canPublish;
    // visibleControls.microphone ??= localPermissions.canPublish;
    // visibleControls.camera = isHostOrCohost || (isUnmuteAllowed && localPermissions.canPublish)
    // visibleControls.microphone = isHostOrCohost || (isUnmuteAllowed && localPermissions.canPublish)
    visibleControls.screenShare ??= localPermissions.canPublish
    visibleControls.chat ??= localPermissions.canPublishData && controls?.chat
    visibleControls.settings ??= localPermissions.canPublishData && controls?.settings
  }

  const browserSupportsScreenSharing = supportsScreenSharing()

  const { isUnmuteAllowed, isAudioOnly } = useCustomRoomContext()

  // const [isScreenShareEnabled, setIsScreenShareEnabled] = React.useState(false);

  // const onScreenShareChange = (enabled: boolean) => {
  //   setIsScreenShareEnabled(enabled);
  // };

  // "bg-white flex items-center justify-center gap-1 relative"

  return (
    <div
      {...props}
      className={
        isPipEnabled
          ? 'bg-white flex items-center justify-center gap-x-3 relative rounded-xl mb-2 pl-8'
          : 'bg-white flex items-center justify-center gap-1 relative mt-2'
      }
    >
      {visibleControls.microphone && (
        <div className="flex items-center">
          <CustomTrackToggle
            source={Track.Source.Microphone}
            isPipEnabled={isPipEnabled}
            className={
              isPipEnabled
                ? ''
                : `py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-full first:ml-0 border font-medium bg-gray-200 text-gray-700 align-middle ${
                    !isAudioPermissionGrantedError ? 'hover:bg-gray-50' : 'cursor-not-allowed'
                  } focus:z-10 transition-all text-sm`
            }
          />
          {!isPipEnabled && <CustomMediaDeviceMenu kind="audioinput" bottom="56px" />}
        </div>
      )}
      {visibleControls.camera && !isAudioOnly && (
        <div className="flex items-center">
          <CustomTrackToggle
            source={Track.Source.Camera}
            isPipEnabled={isPipEnabled}
            className={
              isPipEnabled
                ? ''
                : `py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-full first:ml-0 border font-medium bg-gray-200 text-gray-700 align-middle ${
                    !isVideoPermissionGrantedError ? 'hover:bg-gray-50' : 'cursor-not-allowed'
                  } focus:z-10 transition-all text-sm`
            }
          />
          {!isPipEnabled && <CustomMediaDeviceMenu kind="videoinput" bottom="56px" />}
        </div>
      )}
      {visibleControls.screenShare && browserSupportsScreenSharing && !isAudioOnly && (
        <CustomTrackToggle
          source={Track.Source.ScreenShare}
          isPipEnabled={isPipEnabled}
          captureOptions={{ audio: true, selfBrowserSurface: 'include' }}
          className={
            isPipEnabled
              ? ''
              : `py-3 px-4 justify-center items-center gap-2 -ml-px border rounded-full font-medium bg-gray-200 text-gray-700 align-middle ${
                  !isVideoPermissionGrantedError ? 'hover:bg-gray-50' : 'cursor-not-allowed'
                } focus:z-10 transition-all text-sm`
          }
        />
      )}

      {!isPipEnabled && <CustomSettingsMenu />}
      {!isUnmuteAllowed && !isHostOrCoHostAvailableRoom && <CustomHandRaiseToggle isPipEnabled={isPipEnabled} />}

      {visibleControls.chat && <CustomChatToggle isPipEnabled={isPipEnabled} />}
      <CustomPipButton onClick={isPip} isPipEnabled={isPipEnabled} />
      {visibleControls.leave && (
        <CustomDisconnectButton
          id="btn_endCall"
          className={isPipEnabled ? '' : buttonStyles}
          onLeave={() => {
            props.onLeave && props.onLeave()
          }}
          onEnd={() => {
            props.onEnd && props.onEnd()
          }}
          isPipEnabled={isPipEnabled}
        />
      )}
    </div>
  )
}
