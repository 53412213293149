/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import useToggle from "../../Components/_utils/useToggle"
import Button from '../../Components/base/button/button'
import Icon from "../../Components/base/icon/icon"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../Components/base/modal/modal"
import { ToolTip } from "../../Components/base/tooltip/tooltip"
import { Meeting } from "../../models/meeting.model"
import {  FormatDateAndTime } from "../../Components/formattedDateAndTime"
import { subMinutes } from "date-fns"

type Props = {
  meeting: Meeting,
  closeModal: (e: 'CLOSE' | 'YES') => void,
  width: number
}

/**
 * The PartialCancellationPolicyModal component renders a modal dialog with a title and a span
 * containing the meeting title. The component renders a CancellationPolicyModal component
 * inside a ModalBody. The component also renders a ModalFooter with two buttons, one for
 * closing the modal and one for accepting the cancellation policy.
 * @param {Object} props - The props object.
 * @prop {Meeting} meeting - The meeting object.
 * @prop {Function} closeModal - The function to close the modal.
 * @prop {Number} width - The width of the screen.
 * @returns {JSX.Element} The PartialCancellationPolicyModal component.
 */
export function PartialCancellationPolicyModal({ meeting, closeModal, width }: Props) {
  const { t } = useTranslation()
  const { toggle } = useToggle();

  return width >= 1024 ? (
    <Modal isOpen={true} toggle={toggle} closeOnClickOutside={true} position="semiLarge">
      <ModalHeader data-testid="txt_tit">{t('requestForPartialCancellation')}</ModalHeader>
      <span data-testid="txt_title" id="txt_title" className="pb-1">{meeting.meetingDetails.title}</span>
      <hr className="border-b border-gray-200 my-2"></hr>
      <ModalBody>
        <CancellationPolicyModal meeting={meeting} width={width} />
      </ModalBody>
      <ModalFooter>
      <span data-testid="txt_conformation" id="txt_conformation" className="text-xs text-gray-700 absolute left-12">
          {t('IfYouAgreeToTheAbove,PleaseClickNext',)}
        </span>
        <Button id="btn_cancel" onClick={e => closeModal('CLOSE')} color="transparent" className="text-gray-700">{t('close')}</Button>
        <Button id="btn_ok" onClick={e => closeModal('YES')} color="filterData" autoFocus>{t('next')}</Button>
      </ModalFooter>
    </Modal>
  ) : (
    <Modal isOpen={true} toggle={toggle} closeOnClickOutside={true} position="contact">
      <div className='flex justify-end cursor-pointer' onClick={e => closeModal('CLOSE')}>
        <ToolTip tip='close' >
          <Icon icon="CLOSE" />
        </ToolTip>
      </div>
      <ModalHeader>{t('requestForPartialCancellation')}</ModalHeader>
      <span id="text_title" data-testid='txt_title' className="pb-1">{meeting.meetingDetails.title}</span>
      <hr className="border-b border-gray-200 my-2"></hr>
      <ModalBody>
        <CancellationPolicyModal meeting={meeting} width={width} />
      </ModalBody>
      <ModalFooter>
      <span data-testid="txt_conform" id="txt_conform" className="text-xs text-gray-700 absolute left-12">
          {t('IfYouAgreeToTheAbove,PleaseClickNext',)}
        </span>
        <Button id="btn_cancel" onClick={e => closeModal('CLOSE')} color="transparent" className="text-gray-700">{t('close')}</Button>
        <Button id="btn_ok" onClick={e => closeModal('YES')} color="filterData" autoFocus>{t('next')}</Button>
      </ModalFooter>
    </Modal>
  )
}

/**
 * A component that renders the cancellation policy of a meeting.
 * The component renders different information depending on the type of cancellation policy.
 * If the policy type is "Free", the component renders a message indicating that the host has chosen a free cancellation policy.
 * If the policy type is "Moderate", the component renders a message indicating that the host has chosen a moderate cancellation policy.
 * If the policy type is "Flex", the component renders a message indicating that the host has chosen a flex cancellation policy.
 * If the policy type is "RequestedMeeting", the component renders a message indicating that the host has chosen a requested meeting cancellation policy.
 * The component also renders the date and time of the cancellation policy.
 * The component also renders a button that allows the user to cancel the request.
 * @param meeting - The meeting object.
 * @param width - The width of the screen.
 * @returns A JSX Element.
 */
export function CancellationPolicyModal({ meeting, width }: { meeting: any, width: number }) {
  const { t } = useTranslation()

  const style = {
    chipItem: `bg-amber-400 text-center md:text-base whitespace-nowrap`,
    line: `bg-gray-400 w-1/2 h-0.1 my-auto`,
  }
  return (

    <span className="flex flex-col mx-2">
      <span  data-testid="txt_policies"  id="txt_policies" className="mb-1 mt-3">{t('cancellationPolicies')} :</span>
      {meeting.meetingDetails?.cancellationPolicy &&
        <span>
          <ul className="list-disc ml-6">
            {/* {meeting.meetingDetails.cancellationPolicy?.policyType ? */}
            {meeting.meetingDetails.cancellationPolicy?.policyType === "Free" ?
              <li id="txt_free">
                {t('hostHaveChosen')}{' '}
                <span id="txt_free"  className="font-semibold">
                  {meeting.meetingDetails.cancellationPolicy?.policyType}
                </span  >{' '}

                {t('cancellationPolicyForThisEvent')}
              </li>
              : (meeting.meetingDetails.cancellationPolicy?.policyType === "Moderate" || meeting.meetingDetails.cancellationPolicy?.policyType === "Flex" ?
                <>
                  <li id ={`txt_${meeting.meetingDetails.cancellationPolicy?.policyType}`} data-testid ={`txt_${meeting.meetingDetails.cancellationPolicy?.policyType}`}>
                    {t('hostHaveChosen')}{' '}
                    <span  id ={`txt_${meeting.meetingDetails.cancellationPolicy?.policyType}`} className="font-semibold">
                      {meeting.meetingDetails.cancellationPolicy?.policyType}
                    </span> {''}
                    {t('cancellationPolicyForThisEvent')}
                  </li>
                  <div  className="flex font-bold uppercase mt-4 h-7">
                    <div  id="text_fullRefund" data-testid="text_fullRefund" className={`px-1 text-lime-500 whitespace-nowrap`}>{t('fullRefund')}</div>
                    <span className={`${style.line}`}></span>

                    {meeting.meetingDetails.cancellationPolicy?.policyType === "Flex" &&
                      <div  id="flex_partialRefundTotime" className={`px-1 whitespace-nowrap lowercase rounded bg-gray-300`}>{meeting.meetingDetails?.cancellationPolicy?.chargePeriodToTime}{' '}{width > 1050 ? 'hours' : 'h'}</div>}

                    {meeting.meetingDetails.cancellationPolicy?.policyType === "Moderate" &&
                      <div id="moderate_partialRefundTotime" className={`px-1 whitespace-nowrap lowercase rounded bg-gray-300`}>{meeting.meetingDetails?.cancellationPolicy?.chargePeriodToTime}{' '}{width > 1050 ? 'hours' : 'd'}</div>}
                    <span className={`${style.line}`}></span>

                    <div   id="moderate_partialRefundendtime"className={`px-1 whitespace-nowrap lowercase rounded bg-gray-300`}>{meeting.meetingDetails?.cancellationPolicy?.chargePeriodFromTime}{' '}{width > 1050 ? 'hours' : 'h'}</div>
                    <span className={`${style.line}`}></span>

                    <div  data-testid="txt_noRefund" id="txt_noRefund" className={`px-1 text-red-600 whitespace-nowrap`}>{t('noRefund')}</div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between mx-2 text-gray-700 text-xs font-semibold mt-2 mb-4 ">
                    <div data-testid="date_fullRefund" id="date_fullRefund">
                      {FormatDateAndTime(
                        subMinutes(new Date(meeting?.fromDateTime), Number(meeting.meetingDetails?.cancellationPolicy?.chargePeriodToTimeInMinute)),
                        'do MMM',
                        'hh:mm a'
                      )}
                    </div>
                    <div data-testid="date_partialRefund" id="date_partialRefund">
                      {FormatDateAndTime(
                        subMinutes(new Date(meeting?.fromDateTime), Number(meeting.meetingDetails?.cancellationPolicy?.chargePeriodToTimeInMinute)),
                        'do MMM',
                        'hh:mm a'
                      )} - {FormatDateAndTime(
                        subMinutes(new Date(meeting?.fromDateTime), Number(meeting.meetingDetails?.cancellationPolicy?.chargePeriodFromTimeInMinute)),
                        'do MMM',
                        'hh:mm a'
                      )}
                    </div>
                    <div data-testid="date_noRefund" id="date_noRefund">
                      {FormatDateAndTime(
                        subMinutes(new Date(meeting?.fromDateTime), Number(meeting.meetingDetails?.cancellationPolicy?.chargePeriodFromTimeInMinute)),
                        'do MMM',
                        'hh:mm a'
                      )}
                    </div>
                  </div>
                  <li data-testid="txt_noCancel1" id="txt_noCancel1">
                    {t('ifYouCancelThisMeeting')}{' '}
                    <span id="txt_noCancel" className="font-semibold">
                     {t('before')} {meeting.meetingDetails?.cancellationPolicy?.chargePeriodToTime}{' '}{t('hours')} , {t('noCancellationCharge')}
                    </span>{' '}

                    {t('isApplied')}
                  </li>

                  <li  data-testid="txt_partialRefund" id="txt_partialRefund">
                    {t('ifYouCancel')}{' '}
                    <span  id="txt_partialRefund"className="font-semibold">
                    {t('between')}{' '}{meeting.meetingDetails.cancellationPolicy.chargePeriodToTime}-{meeting.meetingDetails.cancellationPolicy.chargePeriodFromTime}{' '}{t('hours')}, {meeting.meetingDetails.cancellationPolicy.deductionPercentage}% {t('ofTheTotalFee')}
                    </span>{' '}
                    {t('willBeDeducted')}
                  </li>

                  <li data-testid="txt_fullRefund" id="txt_fullRefund">
                  {t('ifYouCancelWith')}{' '}
                    <span id="txt_fullRefund" className="font-semibold">{t('lessThan')}</span>{' '}
                    <span id="txt_fullRefund" className="font-semibold">{meeting.meetingDetails.cancellationPolicy.chargePeriodFromTime}{' '}{t('hoursRemaining')}</span>
                    , <span id="txt_fullRefund" className="font-semibold">{t('fullFee')}{' '}</span>{t('willBeDeducted')}
                  </li>
                </>
                : meeting.meetingDetails.cancellationPolicy?.policyType === "RequestedMeeting" &&
                <li id="cancel">
                  {t('ifYouCancelThisMeeting')}{' '}
                  <span  id="cancel" className="font-semibold">
                    {meeting.meetingDetails.cancellationPolicy.deductionPercentage}%
                  </span>{' '}
                  {t('willBeDeducted')} {t('fromYourRefundAmount')}
                </li>)
            }

          </ul>

        </span>
      }
      {/* {meeting.meetingDetails.cancellationPolicy.policyType !== 'Free' &&
        <span className="mb-1 mt-3">
          {t('IfAgreeToTheAbove,PleaseClickNext',)}
        </span>
      } */}

      {/* {meeting.meetingDetails.cancellationPolicy.inconvenienceTax > 0 && meeting.meetingDetails.cancellationPolicy.policyType !== 'Free' &&
        <span className="text-xs">
          {'*'}{t("you")}{' '}{t("willBeDeducted")}{' '}
          <span className="font-semibold">{meeting.meetingDetails.cancellationPolicy.inconvenienceTax}%</span>
          {' '}{t("flatAsPlatformFeeByDefault")}</span>} */}
    </span>)

}