/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useEffect, useState } from "react"

/**
 * A component that renders the given children after a given delay time.
 * This component can be used to delay the rendering of a component, such as
 * a loading animation, until a certain amount of time has passed.
 *
 * @param {{children: JSX.Element, delayTime: number}} props - The component props
 * @param {JSX.Element} props.children - The JSX element to render after the delay time
 * @param {number} props.delayTime - The time in milliseconds to delay rendering
 * @returns {null|JSX.Element} The rendered element, or null if the delay time has not passed
 */
export function DelayDisplay({children, delayTime}: {children: JSX.Element, delayTime: number}) {
    const [show, setShow] = useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true)
        }, delayTime)
        return () => clearTimeout(timer)
    }, [delayTime])
    return show ? children : null
}   