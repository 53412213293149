/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Loading from '../../Components/base/loading/loading'
import DisplayName from '../../Components/base/user-name/DisplayName'
import NoSession from '../../Components/NoSession'
import { MeetingDetails } from '../../models/meetingDetails.model'
import { useGetMeetingDetailsByUserQuery } from '../../Services/meetingDetailsApi'
import { getCurrentChatUser } from '../../Store/Chat/chat'
import { useAppSelector } from '../../Store/hooks'
import { DisplayFormattedDateAndTime } from '../../Components/formattedDateAndTime'

/**
 * ChatUserEvents
 *
 * This component displays a list of events for the current chat user.
 *
 * It uses `useGetMeetingDetailsByUserQuery` to fetch the meeting details for the current chat user.
 *
 * It displays a loading indicator while the data is being fetched.
 *
 * If the data is empty or there is an error, it displays a "No Events" message.
 *
 * If the data is successful and not empty, it maps over the data and renders a `ChatUserEventCard` for each event.
 */
function ChatUserEvents() {
  const { t } = useTranslation()

  const currentChatUser = useAppSelector(getCurrentChatUser)

  const { data: eventDetailsData, isLoading: loadingEventDetails, isFetching: refetchingEvents, isSuccess: userEventsSuccess, isError: userEventsError } = useGetMeetingDetailsByUserQuery(currentChatUser?.id ? currentChatUser.id : '')

  const isEmpty = (eventDetailsData && eventDetailsData?.length === 0) || userEventsError
  const isLoading = loadingEventDetails || refetchingEvents

  return (
    <div className="hidden lg:flex flex-col gap-y-3 w-[30rem] h-[78vh] border-gray-100 border-l px-2 overflow-y-scroll overflow-x-hidden">
    <div id="currentChatUser" data-testid='ttl_chtUsrEvents' className="text-lg font-bold px-3">
      <span className='flex flex-row'><DisplayName user={currentChatUser} firstNameOnly={true} />{`'s ${t('events')}`}</span>
    </div>


      {isLoading && <Loading />}

      {isEmpty && (
        <NoSession className="text-sm md:text-sm px-3.5 justify-start font-normal"> {t('noEvents')}</NoSession>
      )}

      {userEventsSuccess &&
        !isEmpty &&
        !isLoading &&
        eventDetailsData?.map(
          (meetingDetail: MeetingDetails, index: number) => (
            <ChatUserEventCard
              key={index.toString()}
              meetingDetail={meetingDetail}
            />
          )
        )}
    </div>
  )
}

export default ChatUserEvents

interface ChatUserEventCardProps {
  meetingDetail: MeetingDetails
  key: string
}

/**
 * A component that renders a card for an event in the chat user events list.
 * @param {object} props Component props.
 * @param {MeetingDetails} props.meetingDetail The meeting detail object.
 * @param {string} props.key The component key.
 * @returns {JSX.Element} The rendered component.
 */
const ChatUserEventCard = (props: ChatUserEventCardProps) => {
  const { meetingDetail, key } = props
  const navigate = useNavigate()

  return (
    <div
      key={key}
      onClick={() => navigate(`/s/meeting/${meetingDetail.id}`)}
      className="cursor-pointer flex p-3 gap-2 items-center border border-transparent hover:border hover:border-gray-300 relative rounded-xl">
      <div className="flex flex-col w-full leading-none">
        <div className="flex justify-between gap-2">
          <span id={`currentUserEvent_${meetingDetail.id}`} data-testid={`currentUserEvent_${meetingDetail.id}`} className="text-lg font-medium line-clamp-1">
            {meetingDetail.title}
          </span>
        </div>
        <div  className="flex flex-col font-medium text-sm">
          <span data-testid={`eventDateTime_${meetingDetail.id}`}className="font-normal">
            <DisplayFormattedDateAndTime displayFromTime={meetingDetail.meetings[0].fromDateTime}  displayToTime={meetingDetail.meetings[0].toDateTime} displayDate={meetingDetail.meetings[0].fromDateTime} />
          </span>
        </div>
      </div>
    </div>
  )
}
