/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import PropTypes from "prop-types";
import { icons } from '../../../Assets/icons/index';

/**
 * A React component for displaying an SVG icon.
 *
 * @param {Object} props - Component props
 * @param {string} props.size - Size of the icon. Can be xx-small, x-small, small, medium, or large.
 * @param {string} [props.bgColor] - Background color of the icon.
 * @param {string} [props.stroke] - Stroke color of the icon. Defaults to #010101.
 * @param {string} props.icon - Name of the icon to display. Must be one of the names in the icons object.
 * @param {any} [props.onClick] - Function to call when the icon is clicked.
 * @param {string} [props.className] - Additional CSS class to apply to the icon.
 */
const Icon = ({ size, bgColor, stroke = "#010101", icon, onClick, className }: { size: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large', bgColor?: string, stroke?: string, icon: string, onClick?: any, className?: string }) => {
  let sizeWidth: string

  sizeWidth = size === 'x-small' ? '15' : size === 'small' ? '20' : size === 'medium' ? '30' : size === 'large' ? '40' : '10'
  const IconComp = icons[icon as keyof typeof icons];
  return <IconComp fill={bgColor} stroke={stroke} width={sizeWidth} height={sizeWidth} onClick={onClick} className={className} />

};

Icon.defaultProps = {
  size: 'small',
  height: 'small',
  bgColor: "transparent",
};

Icon.propTypes = {
  size: PropTypes.oneOf(['xx-small', 'x-small', 'small', 'medium', 'large']),
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export default Icon;
