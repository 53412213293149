/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {createApi} from '@reduxjs/toolkit/query/react';
import {Avatar} from '../models/avatar.model';
import {Contact} from '../models/contact.model';
import {Currency} from '../models/currency.model';
import {LaunchNotification} from '../models/launchNotification.model';
import {MeetingCategory} from '../models/meetingCategory.model';
import {NickNames} from '../models/nickName.model';
import {Payment} from '../models/payment.model';
import {Report} from '../models/report.model';
import {baseQueryWithInterceptor} from './api';

export const allOthersApi = createApi({
  reducerPath: 'allothersApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['Avatar'],
  endpoints: builder => ({
    // avatarAPI
    getAllAvatar: builder.query<Avatar[], void>({
      query: () => 'avatar/avatarNameList',
      providesTags: ['Avatar'],
    }),

    // ContactAPI
    createContact: builder.mutation<Contact, Partial<Contact>>({
      query: contact => ({
        url: `contact`,
        method: 'POST',
        body: contact,
      }),
    }),
    createContactForNonLoginUser: builder.mutation<Contact, Partial<Contact>>({
      query: contact => ({
        url: `contact/nonLogin`,
        method: 'POST',
        body: contact,
      }),
    }),

    // FeedbackCategoryAPI
    feedbackCategoryList: builder.query<any, void>({
      query: () => `contactFeedbackCategory`,
    }),

    // IPaddressAPI
    getIPAddress: builder.query<any, string | undefined>({
      query: key => `https://api.ipgeolocation.io/ipgeo?apiKey=${key}`,
    }),

    // LanguageAPI
    getAllLanguages: builder.query<string[], void>({
      query: () => `language`,
    }),

    // NickNameAPI
    nickNamesList: builder.query<NickNames[], void>({
      query: () => `nickName/nickNameList`,
    }),

    // ReportAPI
    createReport: builder.mutation<Report, Partial<Report>>({
      query: report => ({
        url: `report`,
        method: 'POST',
        body: report,
      }),
    }),

    // PaymentAPI
    getPayment: builder.query<Payment, string>({
      query: currency => `payment/${currency}`,
    }),

    // LaunchNotificationAPI
    createLaunchNotification: builder.mutation<
      any,
      Partial<LaunchNotification>
    >({
      query: launchNotification => {
        return {
          url: `launchNotification`,
          method: 'POST',
          body: launchNotification,
        };
      },
    }),

    // MeetingCategoryAPI
    getAllMeetingCategory: builder.query<MeetingCategory[], void>({
      query: () => `meetingCategory`,
    }),

    // currencyAPI
    getAllCurrency: builder.query<Currency[], void>({
      query: () => `currency`,
    }),
    getCurrencyByCurrencyCode: builder.query<Currency, string>({
      query: currencyCode => `currency/code/${currencyCode}`,
    }),

    //face Verification
    getEncryptedData: builder.query<any, void>({
      query: () => ({
        url: `api/encrypt`,
        method: 'GET',
      }),
    }),

    getVerification: builder.query<
      any,
      {cUserIdEncrypted: string; ipAddressEncrypted: string}
    >({
      query: ({cUserIdEncrypted, ipAddressEncrypted}) => ({
        url: `api/users/verification/${cUserIdEncrypted}/${ipAddressEncrypted}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAllAvatarQuery,
  useGetAllLanguagesQuery,
  useCreateContactMutation,
  useFeedbackCategoryListQuery,
  useGetIPAddressQuery,
  useNickNamesListQuery,
  useCreateReportMutation,
  useGetPaymentQuery,
  useCreateLaunchNotificationMutation,
  useGetAllMeetingCategoryQuery,
  useCreateContactForNonLoginUserMutation,
  useGetAllCurrencyQuery,
  useGetCurrencyByCurrencyCodeQuery,
  useGetEncryptedDataQuery,
  useGetVerificationQuery,
} = allOthersApi;
