/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useToggle from '../../Components/_utils/useToggle'
import { AccordionBorder, AccordionItem, AccordionPanel } from '../../Components/base/accordion/accordion'
import Button from '../../Components/base/button/button'
import Icon from '../../Components/base/icon/icon'
import Loading from '../../Components/base/loading/loading'
import { Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from '../../Components/base/modal/modal'
import { toastSuccess } from '../../Components/toast'
import { useAttendingPaymentHooks, useAttendingReceivedCardHooks, useAttendingRequestAcceptedHooks } from '../../Hooks/AttendingHooks'
import { useViewReadMore } from '../../Hooks/UpcomingEvents'
import { Select } from '../../models/meetingRequest.model'
import CardImagePlaceHolder from '../EventsCard/CardImagePlaceHolder'
import CardTitle from '../EventsCard/CardTitle'
import { DisplayEventCost } from '../EventsCard/DisplayEventCost'
import { EventCard } from '../EventsCard/EventCard'
import TextLink from '../EventsCard/TextLink'
import JoinAnonymousModal from '../ViewEvents/JoinAnonymousModal'
import { DisplayFormattedDate, DisplayFormattedTime } from '../../Components/formattedDateAndTime'
import { ToolTip } from '../../Components/base/tooltip/tooltip'

const style = {
  reminderIcon: `min-w-rightIcon flex flex-col items-center justify-center`,
  requestDetails: `border-b max-w-md flex gap-2 items-center`,
}

/**
 * Component that displays a list of accepted meeting requests for a user.
 *
 * @param meetingRequest - The meeting request object that contains the details of the meeting request.
 * @returns A JSX element that displays the list of accepted meeting requests.
 */
export function RequestAccepted({ meetingRequest }: { meetingRequest: any }) {
  const { t } = useTranslation()

  const {
    handleAcceptedCancellation, deleteAcceptedSuccess, cancelAcceptedSuccess
  } = useAttendingRequestAcceptedHooks();

  const {
    meetingRequestData,
    meetingRequestSuccess,
    checkForMeetings,
    availableMeetingSubmitDisable,
    expireDateSubmitDisable,
    availableGuestMeetingSubmitDisable
  } = useAttendingReceivedCardHooks({ meetingRequest });

  const { payment, navigateScreenLink, loading } = useAttendingPaymentHooks()
  const { detailsLength, setDetailsLength, readMoreToLess, setReadMoreToLess } = useViewReadMore({ })
  const { toggle, isOpen } = useToggle()
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState(undefined), []);
  const { toggle: toggleJoinAnonymousModal, isOpen: isJoinAnonymousModal } = useToggle()

  useEffect(() => {
    if (deleteAcceptedSuccess) {
      toastSuccess(t('requestDeletedSuccessfully'))
    }
  }, [deleteAcceptedSuccess, t]);

  useEffect(() => {
    if (cancelAcceptedSuccess) {
      toastSuccess(t('requestCancelledSuccessfully'))
    }
  }, [cancelAcceptedSuccess, t])

  useEffect(() => {
    if (meetingRequestData) {
      if (meetingRequestData?.select === Select.Option1) {
        checkForMeetings(meetingRequestData.startAt1DateTime, meetingRequestData?.endAt1DateTime, meetingRequestData?.guest?.id)
      }
      else if (meetingRequestData?.select === Select.Option2 && meetingRequestData.startAt2DateTime && meetingRequestData?.endAt2DateTime) {
        checkForMeetings(meetingRequestData.startAt2DateTime, meetingRequestData?.endAt2DateTime, meetingRequestData?.guest?.id)
      }
      else if (meetingRequestData?.select === Select.Option3 && meetingRequestData.startAt3DateTime && meetingRequestData?.endAt3DateTime) {
        checkForMeetings(meetingRequestData.startAt3DateTime, meetingRequestData?.endAt3DateTime, meetingRequestData?.guest?.id)
      }
    }
  }, [meetingRequestData])

  useEffect(() => {
    forceUpdate()
  }, [navigateScreenLink])

  if (!meetingRequestSuccess) {
    return <></>
  }

  if (navigateScreenLink) {
    window.location.href = navigateScreenLink;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div >
      <AccordionBorder>
        <AccordionItem id={"div_" + meetingRequest.id} toggle={`${meetingRequest.id}`} color="card">
          <EventCard>
            <EventCard.Image><CardImagePlaceHolder images={meetingRequest?.guest?.image} /></EventCard.Image>
            <EventCard.Title>
              <CardTitle
                key={meetingRequest.id}
                keyId={meetingRequest.id}
                meetingTitle={`${t('meetingRequestSentTo')} ${meetingRequest.guest.firstName + ' ' + meetingRequest.guest.lastName}`}
              />
            </EventCard.Title>
            <EventCard.SubTitle>
              {t('requestedOn')}{' '}
              <span className='font-normal'><DisplayFormattedDate displayDate={meetingRequest.createdAt} /></span> | {t('scheduledOn')}{' '}
              <span className='font-normal'>
                { meetingRequestData?.select === Select.Option1 && meetingRequestData?.startAt1DateTime &&
                  <DisplayFormattedDate displayDate={meetingRequestData.startAt1DateTime} />}
                { meetingRequestData?.select === Select.Option2 && meetingRequestData?.startAt2DateTime && 
                  <DisplayFormattedDate displayDate={meetingRequestData.startAt2DateTime} />}
                { meetingRequestData?.select === Select.Option3 && meetingRequestData?.startAt3DateTime && 
                  <DisplayFormattedDate displayDate={meetingRequestData.startAt3DateTime} />}                
              </span>
            </EventCard.SubTitle>
            <EventCard.Icons> <DisplayEventCost meeting={meetingRequest} /></EventCard.Icons>
            <EventCard.Actions>
              <div className="grid grid-cols-6 w-full">
                <div className={`text-center col-span-5`}>
                  <div id={`txt_accepted${meetingRequest.id}`} className="flex items-center order-2 md:order-1 ml-2 mb-2 justify-center text-chat-accepted">
                    {t('accepted')}{' '}<span className="ml-1"></span>
                  </div>

                  <Button
                    id={`btn_payNow${meetingRequest.id}`}
                    data-testid={`btn_payNow${meetingRequest.id}`}
                    size="sm" color="RFMpayNow" disabled={availableMeetingSubmitDisable || expireDateSubmitDisable || availableGuestMeetingSubmitDisable}
                    onClick={(e: any) => { toggleJoinAnonymousModal(); e.stopPropagation() }}
                    className="justify-end w-32"
                  >
                    <ToolTip
                      tip="PleaseCheckWithYourBank"
                      theme="BeeMG-gray">
                      {t('payNow')}
                    </ToolTip>
                  </Button>
                </div>
                <div className='flex justify-end mx-auto'>
                  <div className='opacity-0'><Icon size="small" icon="REMINDER" /></div>
                </div>
              </div>
            </EventCard.Actions>
          </EventCard>
        </AccordionItem>
        <AccordionPanel id={`${meetingRequest.id}`}>
          <div className="grid grid-cols-3 md:flex-row flex-col hover:border-t-2 gap-3 lg:pl-32 px-8 md:pb-12 pb-3 pt-2">
            <div className="col-span-3 md:col-span-1">
              <div className="font-bold text-sm pt-2">Details:</div>
              <div id="chk_details" className={`${style.requestDetails} border-none`}>
                {meetingRequestData && (
                  <>
                    <span className="font-normal mt-2 text-xs w-full">
                      {meetingRequestData?.details.length > 50 ? (
                        <>
                          {readMoreToLess && (
                            <>
                              {meetingRequestData?.details.slice(0, detailsLength)}{'...'}{' '}
                              <span
                                className="flex justify-end underline underline-offset-1 cursor-pointer"
                                onClick={() => {
                                  setDetailsLength(meetingRequestData?.details.length);
                                  setReadMoreToLess(!readMoreToLess);
                                }}
                              >
                                {t('readMore')}
                              </span>
                            </>
                          )}
                          {!readMoreToLess && (
                            <>
                              {meetingRequestData?.details.slice(0, detailsLength)}{' '}
                              <span
                                className="flex justify-end underline underline-offset-1 cursor-pointer"
                                onClick={() => {
                                  setDetailsLength(25);
                                  setReadMoreToLess(!readMoreToLess);
                                }}
                              >
                                {t('readLess')}
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        meetingRequestData?.details
                      )}
                    </span>
                  </>
                )}
              </div>
              <div className="text-sm md:text-base">
                {meetingRequestData && (
                  <span  data-testid="txt_dateTime" className="font-normal text-xs w-full">
                    {meetingRequestData?.select === Select.Option1 &&
                      meetingRequestData?.startAt1DateTime && (
                        <>
                          <DisplayFormattedDate displayDate={meetingRequestData.startAt1DateTime} />{' | '}
                          <DisplayFormattedTime
                            displayFromTime={meetingRequestData.startAt1DateTime}
                            displayToTime={meetingRequestData.endAt1DateTime}
                          />
                        </>
                      )
                    }  
                      {meetingRequestData?.select === Select.Option2 && 
                      meetingRequestData?.startAt2DateTime && (
                        <>
                        <DisplayFormattedDate displayDate={meetingRequestData.startAt2DateTime} />{' | '}
                        <DisplayFormattedTime 
                          displayFromTime={meetingRequestData.startAt2DateTime}
                          displayToTime={meetingRequestData.endAt2DateTime}
                        />
                        </>
                        )}
                      {meetingRequestData?.select === Select.Option3 &&
                      meetingRequestData?.startAt3DateTime && (
                        <>
                        <DisplayFormattedDate displayDate={meetingRequestData.startAt3DateTime} />{' | '}
                        <DisplayFormattedTime 
                          displayFromTime={meetingRequestData.startAt3DateTime}
                          displayToTime={meetingRequestData.endAt3DateTime}
                        />
                        </>
                        )}
                  </span>
                )}
              </div>
              <div id="chk_participants" className="flex gap-1 font-normal text-xs">
                <span>{t('participants')} : {' '}
                  <span className="font-normal text-xs w-full">{meetingRequestData?.participants}</span></span>
              </div>
            </div>
            <div className="col-span-3 md:col-span-1 mt-2 md:pl-16">
              {availableMeetingSubmitDisable && (
                <div className="pb-10 text-xs text-red-500">{t('youAlreadyHaveMeetingInThisTimeSlot')}</div>
              )}
              {availableGuestMeetingSubmitDisable && (
                <div className="pb-10 text-xs text-red-500">{t('sorry...!HostHaveAnotherSessionAtTheTime')}</div>
              )}
              {expireDateSubmitDisable && <div className="pb-10 text-xs text-red-500 ">{t('selectedTimeSlotIsExpired')}</div>}
              {meetingRequestData?.cancellationPolicy && (
                <div className="">
                  <div className="font-bold text-sm">{t('cancellationPolicy')}:</div>
                  <span className="text-xs">
                    <span className="font-normal">{meetingRequestData?.cancellationPolicy.policyType}</span>
                    <div>
                      ({meetingRequestData?.cancellationPolicy.chargePeriodFromTimeInMinute === '0'
                        ? t('noCancellationCharge')
                        : `${t('cancellationBetween')} ${meetingRequestData?.cancellationPolicy.chargePeriodFromTime} ${t(
                          'hours'
                        )} ~ ${Math.floor(Number(meetingRequestData?.cancellationPolicy.chargePeriodToTime))} ${t('hours')} is ${meetingRequestData?.cancellationPolicy.deductionPercentage
                        }% ${t('chargeable')} and before ${meetingRequestData?.cancellationPolicy.chargePeriodFromTime
                        } ${t('hours')} ~ is 100% ${t('chargeable')}`})
                    </div>
                  </span>
                </div>
              )}
            </div>

            <div className="col-span-3 md:col-span-1 pr-10 flex md:justify-center items-center">
              <div className="bg-red-700 w-32 rounded-md text-center text-xs p-1 text-white">
              <div
                    id={`btn_cancelRequest${meetingRequest.id}`}
                    data-testid={`btn_cancelRequest${meetingRequest.id}`}
                    className='cursor-pointer'
                    onClick={e => {
                      e.stopPropagation()
                      toggle()
                    }}
                  > 
                <TextLink link="" type="cancelRequest" className='no-underline'>
                  {t('cancelRequest')}
                </TextLink>
                </div>
              </div>
            </div>
          </div>

        </AccordionPanel>
      </AccordionBorder>
      <Modal isOpen={isOpen} toggle={toggle} closeOnClickOutside={false}>
        <ModalCloseButton toggle={toggle} />
        <ModalHeader>{t('requestCancellation')}...</ModalHeader>
        <ModalBody>
          {t('areyouSureToCancelTheRequest?')}
        </ModalBody>
        <ModalFooter>
          <Button id="btn_cancel" onClick={toggle} color="footerButton" className="mr-1">
            {t('cancel')}
          </Button>
          <Button data-testid="btn_ok" id="btn_ok" onClick={() => {
            handleAcceptedCancellation(meetingRequest.id)
            toggle()
          }} color="footerButton">
            {t('ok')}
          </Button>
        </ModalFooter>
      </Modal>
      <JoinAnonymousModal isOpen={isJoinAnonymousModal} toggle={toggleJoinAnonymousModal} payment={payment} meetingRequestData={meetingRequestData} RFMPayNow={true} meetingRequest={meetingRequest} />

    </div>
  )
}
