/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {useState} from 'react';
import {useGetIPAddressQuery} from '../Services/allOthersApi';
import {
  useGetCognitoKeysQuery,
  useGetIPGeolocationKeyQuery,
  useGetReCaptchaQuery,
} from '../Services/envApi';
import {useGetInviteByTokenQuery} from '../Services/inviteApi';
import {useGetSettingValue} from '../Services/settingReducer';
import {useGetUserDetailByEmailQuery} from '../Services/userApi';
import {Gender} from '../models/user.model';

interface Initial {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  dateOfBirth: string;
  gender: string;
  ageLimit?: number;
}

/**
 * Generates a custom hook for signing up a user, which fetches data from various APIs and sets up the state for the sign-up process.
 *
 * @param {string} token - The token for the invite, if any.
 * @return {{loading: boolean, invite: Invite, val: Initial | undefined, userData: UserDetail | undefined, siteKey: string, ipAddressData: any, showScreen: boolean}} - An object containing the state variables for the sign-up process.
 */

export const useSignUpHook = (token?: string) => {
  const {data: invite, isSuccess: inviteSuccess} = useGetInviteByTokenQuery(
    token ? token : '',
    {skip: !token},
  );
  const {data: siteKey, isSuccess: siteKeySuccess} = useGetReCaptchaQuery();
  const {data: key, isSuccess: keySuccess} = useGetIPGeolocationKeyQuery();
  const {data: ipAddressData, isSuccess: ipAddressSuccess} =
    useGetIPAddressQuery(key, {skip: !key});
  const {isSuccess: cognitoSuccess} = useGetCognitoKeysQuery();

  const {data: userData, isSuccess: userDataSuccess} =
    useGetUserDetailByEmailQuery(invite ? invite.inviteEmail : '', {
      skip: !invite,
    });

  const ageLimit = Number(useGetSettingValue('AGE_LIMIT'));

  const [val, setVal] = useState<Initial>();
  const [loading, setLoading] = useState(true);
  const [showScreen, setShowScreen] = useState(false);

  if (
    !inviteSuccess ||
    !siteKeySuccess ||
    !keySuccess ||
    !ipAddressSuccess ||
    !userDataSuccess ||
    !cognitoSuccess
  ) {
    if (loading) {
      setLoading(false);
    }
  }

  if (inviteSuccess) {
    if (invite && !val?.email) {
      setVal({
        email: invite?.inviteEmail,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        password: '',
        dateOfBirth: '',
        gender: Gender.Male,
        ageLimit: ageLimit,
      });
    }

    if (!showScreen) {
      setShowScreen(true);
    }
  }

  return {loading, invite, val, userData, siteKey, ipAddressData, showScreen};
};
