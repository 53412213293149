/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import Icon from "../../Components/base/icon/icon";
import { useGetNotificationCountQuery } from "../../Services/meetingRequestApi";

  /**
   * A functional component that renders the layout for request events, handling tab switching and rendering the active tab's component.
   *
   * @returns {JSX.Element} The JSX element representing the request events layout.
   */
const RequestEventsWrapper = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const {data: meetingNotificationCount} = useGetNotificationCountQuery(undefined, { pollingInterval: 5000})

  const [sortSent, setSortSent] = useState<boolean | null>(null)
  const [sortReceived, setSortReceived] = useState<boolean | null>(null)

  const [isSentActive, setIsSentActive] = useState<boolean>(false)
  const [isReceivedActive, setIsReceivedActive] = useState<boolean>(false)

  useEffect(() => {
    if (
      pathname.endsWith('/s/events/request-events') ||
      pathname.endsWith('/s/events/request-events/')
    ) {
      if (meetingNotificationCount !== undefined && meetingNotificationCount > 0) {
        navigate('/s/events/request-events/receivedRequests')
      } else {
        navigate('/s/events/request-events/sentRequests')
      }
    }
  }, [navigate, pathname, meetingNotificationCount])

  useEffect(() => {
    if (sortReceived !== null) {
      if (sortReceived) {
        navigate({ pathname: "/s/events/request-events/receivedRequests", search: 'latest' })
      }
      else {
        navigate({ pathname: "/s/events/request-events/receivedRequests" })
      }
    }
  }, [sortReceived])

  useEffect(() => {
    if (sortSent !== null) {
      if (sortSent) {
        navigate({ pathname: '/s/events/request-events/sentRequests', search: 'latest' })
      } else {
        navigate({ pathname: '/s/events/request-events/sentRequests' })
      }
    }
  }, [sortSent])

  const style = {
    events: `flex text-gray-400 cursor-pointer font-normal text-lg hover:text-black hover:font-medium sm:text-sm sm:font-semibold`,
    navLink: `xs:text-xs sm:text-sm md:text-base font-normal hover:text-black hover:font-semibold flex flex-row gap-x-2 items-center`,
    active: `xs:text-sm sm:text-base md:text-lg text-black font-semibold px-2 py-1 rounded-lg flex flex-row gap-x-2 items-center`,
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-center w-full items-center md:space-x-5 gap-2 mb-4 mt-2">
        <div className={style.events}>
          <span className="flex items-center">
            <NavLink
              id="btn_sentRequests"
              data-testid="btn_sentRequests"
              to={{
                pathname: 'sentRequests',
              }}
              className={(navData: any) => {
                setIsSentActive(navData.isActive)
                if (navData.isActive) {
                  return style.active;
                }
                return style.navLink
              }}>
              {t('sentRequests')}
              {isSentActive && <div onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();

                if (sortSent === null) {
                  setSortSent(true)
                }

                setSortSent(!sortSent)
              }}>
                <Icon icon={sortSent ? "DOWN" : "UP"} />
              </div>}
            </NavLink>
            <span className='xs:inline sm:inline md:hidden'>{' '}</span>
          </span>
        </div>
        <div className={style.events}>
          <span className="flex items-center">
            <NavLink
              id="btn_receivedRequests"
              data-testid="btn_receivedRequests"
              to={{
                pathname: 'receivedRequests',
              }}
              className={(navData: any) => {
                setIsReceivedActive(navData.isActive)
                if (navData.isActive) {
                  return style.active;
                }
                return style.navLink
              }}>
              {t('receivedRequests')}
              {isReceivedActive && <div onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();

                if (sortReceived === null) {
                  setSortReceived(true)
                }

                setSortReceived(!sortReceived)
              }}>
                <Icon icon={sortReceived ? "DOWN" : "UP"} />
              </div>}
            </NavLink>
            <span className='xs:inline sm:inline md:hidden'>{' '}</span>
          </span>
        </div>
        <div className={style.events}>
          <NavLink
            id="btn_feedbacks"
            data-testid="btn_feedbacks"
            to={'feedbacks'}
            className={(navData: any) => navData.isActive ? style.active : style.navLink}>
            {t('feedbacks')}
            <span className='xs:inline sm:inline md:hidden'>{' '}</span>
          </NavLink>
        </div>
      </div>
      <div className={`content w-full flex justify-center`}>
        <Outlet />
      </div>
    </div>
  )
}

export default RequestEventsWrapper