/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import defaultImg from '../../Assets/Images/beemg-icons-creator-camera-2023.svg'
import { useHandleMeetingNavigation } from '../../Containers/LiveKit/hooks/livekitWebOnlyHooks'
import FreeEventAnonymousModal from '../../Containers/ViewEvents/FreeEventAnonymousModal'
import JoinAnonymousModal from '../../Containers/ViewEvents/JoinAnonymousModal'
import { useCountDownHook } from '../../Hooks/CountDown'
import { useCreatorCardHook } from '../../Hooks/CreatorCardHook'
import { Meeting } from '../../models/meeting.model'
import { MeetingDetails } from '../../models/meetingDetails.model'
import useToggle from '../_utils/useToggle'
import Button from '../base/button/button'
import Icon from '../base/icon/icon'
import NewIcon from '../base/icon/newIcons'
import Loading from '../base/loading/loading'
import { ToolTip } from '../base/tooltip/tooltip'
import { toastSuccess } from '../toast'
import LiveEventAvatars from './LiveEventAvatars'
import { useCurrencyHook } from '../../Hooks/CurrencyHooks'
import { DisplayFormattedDate, DisplayFormattedTime } from '../formattedDateAndTime'
interface Props {
  meeting: Meeting
  meetingDetails: MeetingDetails
  keyId: number
  eventsTitle: string
  fromHostEvent?: boolean
}

const style = {
  eventImg: `transform duration-200 cursor-pointer group rounded-2xl bg-no-repeat bg-center bg-cover`,
  defaultImg: `transform duration-200 cursor-pointer group rounded-2xl bg-no-repeat bg-center bg-30 bg-gray-200 object`,
}

/**
 * This component is used to display a creator event card.
 * It displays the event image, title, host, language, date, time, cost, and a button to join or register.
 * It also displays a tooltip with the event title if the event title is too long.
 * The component is used in the following pages: upcoming events, live events, and events.
 * @param {Object} props - The props object.
 * @param {Meeting} props.meeting - The meeting object.
 * @param {MeetingDetails} props.meetingDetails - The meeting details object.
 * @param {number} props.keyId - The key id.
 * @param {string} props.eventsTitle - The title of the events (e.g. "Upcoming Events", "Live Events").
 * @param {boolean} [props.fromHostEvent=false] - If the component is used in the host event page.
 */
export const CreatorCard = ({ meeting, meetingDetails, keyId, eventsTitle, fromHostEvent = false }: Props) => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isHover, setIsHover] = useState(false);
  const { toggle, isOpen } = useToggle()
  const { toggle: toggleFreeEventAnonymousModal, isOpen: isFreeEventAnonymousModal } = useToggle()

  const { formatCurrency } = useCurrencyHook();  

  const { joinMeeting } = useHandleMeetingNavigation()
  
  const {
    handleJoin,
    navigateTo,
    creatorDisplayButton,
    creatorDisplayButtonLive,
    registerSuccess,
    handlefav,
    handleRegister,
    preference,
    imageURL,
    currentUser,
    payment,
    navigateScreenLink,
    loading,
    meetingToDisplay,
    eventTitleForID,
    razorpayOpen,
  } = useCreatorCardHook(meeting, meetingDetails, eventsTitle)

  useEffect(() => {
    if (navigateTo) {
      joinMeeting(meeting.id)
    }
  }, [navigateTo])

  useEffect(() => {
    if (registerSuccess) {
      toastSuccess(t('registeredSuccessfully'))
      toggleFreeEventAnonymousModal()
    }
  }, [registerSuccess])

  if (navigateScreenLink) {
    window.location.href = navigateScreenLink
  }

  if (loading) {
    return (
      <>
        <div className="mx-auto rounded-lg border h-[300px] w-[200px] flex items-center justify-center">
          <Loading />
        </div>
        <div
          className="text-black text-base md:text-lg font-medium mt-2 w-auto"
          style={{ width: '200px' }}>
          <p
            data-testid={`txt_title${meeting.id}`}
            id={`txt_title${keyId}`}
            className="line-clamp-2">
            {meeting.meetingDetails.title}
          </p>
        </div>
      </>
    )
  }

  return (
    <>
      <div
        onClick={() =>
          navigate(`/s/meeting/${meetingDetails.id}`)
        }
        onMouseEnter={() => {
          setIsHover(true)
        }}
        onMouseLeave={() => {
          setIsHover(false)
        }}
        id={eventTitleForID}
        style={{ height: '380px', width: '200px' }}
        className="flex flex-1 flex-col relative">
        {/* rating */}
        <div className={`absolute flex flex-row items-center justify-center gap-x-1 z-10 leading-none top-2.5 right-2.5`}>
          <div className={`${isHover ? 'text-black' : 'text-primary'} text-xxs`}
            id={`ratingStar_${eventTitleForID}${meeting.id}`}
            data-testid={`ratingStar_${eventTitleForID}${meeting.id}`}>
            <NewIcon icon='STAR' bgColor={`${isHover ? "black" : "#FFC200"} `} size='x-small' stroke='transparent' />
          </div>
          <div
            id={`ratingCount_${eventTitleForID}${meeting.id}`}
            className={`text-sm font-normal leading-normal
          ${isHover ? 'text-black' : (fromHostEvent ? (meetingDetails.image[0]?.imageName === undefined) : (meeting.display_imageUrl === undefined))
                ? 'text-black '
                : 'text-white'}`}>
            {fromHostEvent ? meetingDetails?.average : meeting?.meetingDetails?.average}
          </div>
        </div>

        <div
          className={`flex flex-1 rounded-2xl relative 
        ${fromHostEvent ? ((meetingDetails.image[0]?.imageName ? style.eventImg : style.defaultImg))
              : (meeting.display_imageUrl ? style.eventImg : style.defaultImg)}`}
          style={{
            backgroundImage:
              fromHostEvent ? `url(${meetingDetails.image[0]?.imageName === undefined ? defaultImg : imageURL + '/' + meetingDetails.image[0]?.imageName})`
                : `url(${meeting.display_imageUrl === undefined ? defaultImg : imageURL + '/' + meeting.display_imageUrl})`,
          }}>

          {/* hover content */}
          <div
            className={`hover:bg-primary p-3 flex flex-wrap flex-col pb-1 overflow-hidden transform duration-200 rounded-2xl h-full w-full`}>
            <div className="opacity-0 text-black text-sm group-hover:opacity-100 transform duration-200 translate-y-20 overflow-hidden group-hover:translate-y-0 hover:overflow-hidden h-full w-full relative flex flex-col justify-end">
              <div className="absolute z-50 top-0 left-0">
                {meetingToDisplay?.favorite === 'Yes' &&
                  currentUser?.id !== (fromHostEvent ? (meetingDetails?.host?.id) : (meeting.meetingDetails?.host?.id)) ? (
                  <ToolTip
                    cursor='pointer'
                    tip="removeFromFavorites"
                    keyId={`remove_${eventTitleForID}${keyId}`}>
                    {' '}
                    <Icon
                      icon="FAVOURITES_fill"
                      size="x-small"
                      stroke='transparent'
                      onClick={(e: any) => {
                        handlefav(meeting)
                        e.stopPropagation()
                      }}
                    />{' '}
                  </ToolTip>
                ) : currentUser?.id !== (fromHostEvent ? (meetingDetails?.host?.id) : (meeting.meetingDetails?.host?.id)) ? (
                  <ToolTip
                    cursor='pointer'
                    tip="addToFavorites"
                    keyId={`add_${eventTitleForID}${keyId}`}>
                    <Icon
                      icon="FAVOURITES_BLACK"
                      size="x-small"
                      onClick={(e: any) => {
                        handlefav(meetingToDisplay)
                        e.stopPropagation()
                      }}
                    />
                  </ToolTip>
                ) : <></>}
              </div>
              {eventsTitle !== 'liveEvents' && (
                <>
                  <div className="flex items-center gap-1">
                    <span
                      data-testid={`txt_date${meeting.id}`}
                      id={`date_${eventTitleForID}${meeting.id}`}
                      className="font-normal text-sm leading-4">     
                      <DisplayFormattedDate displayDate={meeting.fromDateTime} />
                    </span>
                  </div>
                  <div className="flex items-center gap-1 mt-1">
                    <p
                      data-testid={`txt_time${meeting.id}`}
                      id={`time_${eventTitleForID}${meeting.id}`}
                      className="whitespace-nowrap font-normal text-sm leading-4">
                      <DisplayFormattedTime displayFromTime={meeting.fromDateTime}  displayToTime={meeting.toDateTime} />
                    </p>
                  </div>
                  {eventsTitle === 'startingInNext6Hours' && (
                    <div className="flex items-center gap-1 mt-1">
                      <p
                        data-testid={`txt_countDown${meeting.id}`}
                        id={`time_${eventTitleForID}${meeting.id}`}
                        className="whitespace-nowrap font-normal text-sm leading-4">
                        <DisplayCardCountDown meeting={meeting} />
                      </p>
                    </div>
                  )}
                </>
              )}
              {eventsTitle === 'liveEvents' && (
                <LiveEventAvatars eventsTitle={eventsTitle} meeting={meeting} />
              )}
              <div className="flex items-center gap-1">
                <span
                  data-testid={`txt_lang${meeting.id}`}
                  className="font-normal text-sm truncate w-40">
                  {meeting.language}{' '}
                </span>
              </div>
              {eventsTitle === 'liveEvents' && (
                <div className="flex items-center gap-1 mt-1">
                  <p data-testid={`joinCount${meeting.id}`} className="whitespace-nowrap font-medium text-sm leading-4">
                    {meeting.joinedCount} / {meeting.noOfParticipants}
                  </p>
                </div>
              )}
              {fromHostEvent ? <div className="font-normal mt-3 capitalize">
                {meetingDetails?.host.firstName === undefined &&
                  meetingDetails?.host?.nickName?.length > 20 ? meetingDetails?.host.nickName.substring(0, 20) + '...' :
                  meetingDetails?.host.nickName}
                {meetingDetails?.host.firstName !== undefined &&
                  meetingDetails?.host.firstName?.length > 20
                  ? meetingDetails?.host.firstName.substring(0, 20) + '...'
                  : meetingDetails?.host.firstName}
              </div>
                :
                <div className="font-normal mt-3 capitalize">
                  {meeting.meetingDetails?.host.firstName === undefined &&
                    meeting.meetingDetails?.host.nickName.length > 20 ? meeting.meetingDetails?.host.nickName.substring(0, 20) + '...'
                    : meeting.meetingDetails?.host.nickName}
                  {meeting.meetingDetails?.host.firstName !== undefined &&
                    meeting.meetingDetails?.host.firstName.length > 20
                    ? meeting.meetingDetails?.host.firstName.substring(0, 20) + '...'
                    : meeting.meetingDetails?.host.firstName}
                </div>
              }

              <div>
                {((fromHostEvent ? (meetingDetails?.isFreeAudioMeeting) : (meeting.meetingDetails?.isFreeAudioMeeting)) === true) && (
                  <div
                    data-testid={`txt_free${meeting.id}`}
                    id={`txt_free${keyId}`}
                    className="font-normal text-sm mb-2">
                    {' '}
                    {t('freeEvent')}
                  </div>
                )}
                {((fromHostEvent ? (meetingDetails?.isFreeAudioMeeting) : (meeting.meetingDetails?.isFreeAudioMeeting)) === false) && (
                  <div className="flex flex-col font-normal">
                    <span className={`${preference.currency === meeting.currency && 'mb-3'}`}
                      data-testid={`chk_cost${meeting.id}`}
                      id={`chk_host_cost_${eventTitleForID}${meetingDetails.id}`}>
                      { formatCurrency(meeting?.cost_formatted, meeting?.currency) } /{t('person')}
                    </span>
                    {preference.currency !== meeting.currency && (
                      <span className='mb-3'
                        data-testid={`chk_approxCost${meeting.id}`}
                        id={`chk_host_approx_cost_${eventTitleForID}${keyId}`}>
                        ({t('approx')}{' '}
                        {formatCurrency(meeting?.costLocal_formatted, preference?.currency)})
                      </span>
                    )}
                  </div>
                )}

                {/* Button */}
                <div
                  className="flex mb-2 w-full"
                  onClick={e => [e.stopPropagation(), e.preventDefault()]}>
                  {eventsTitle === 'liveEvents' && (
                    <div className="w-full">
                      {creatorDisplayButtonLive === 'JOIN' && (
                        <Button
                          role={'button'}
                          data-testid={`btn_join_${eventTitleForID}${meeting.id}`}
                          id={`btn_join_${eventTitleForID}${meeting.id}`}
                          size="sm"
                          color="coHost"
                          className='w-full h-8 mx-0 cursor-pointer normal-case'
                          onClick={() => handleJoin(meeting)}>
                          {t('join')}
                        </Button>
                      )}
                      {creatorDisplayButtonLive === 'LIMITREACHED' && (
                        <Button
                          data-testid={`btn_limit_${eventTitleForID}${meeting.id}`}
                          id={`btn_limit_${eventTitleForID}${meeting.id}`}
                          size="sm"
                          color="paid"
                          className='w-full h-8 mx-0 cursor-pointer normal-case'
                          disabled>
                          {t('limitReached')}
                        </Button>
                      )}
                    </div>
                  )}
                  {eventsTitle !== 'liveEvents' && (
                    <div className='w-full'>
                      {creatorDisplayButton === 'PAYNOW' && (
                        <>
                          <Button
                            size="sm"
                            id={`btn_payNow_${eventTitleForID}${meeting.id}`}
                            data-testid={`btn_payNow_${eventTitleForID}${meeting.id}`}
                            color="payNow"
                            className='w-full h-8 mx-0 normal-case'
                            onClick={toggle}>
                            <ToolTip
                              tip="PleaseCheckWithYourBank"
                              theme="BeeMG-gray">
                              {t('bookYourSlot')}
                            </ToolTip>
                          </Button>
                        </>
                      )}
                      {creatorDisplayButton === 'SOLDOUT' && (
                        <Button
                          size="sm"
                          data-testid={`btn_soldOut_${eventTitleForID}${meeting.id}`}
                          id={`btn_soldOut_${eventTitleForID}${meeting.id}`}
                          color="paid"
                          className='w-full h-8 mx-0 normal-case'
                          disabled>
                          {t('soldOut')}
                        </Button>
                      )}
                      {creatorDisplayButton === 'PAID' && (
                        <Button
                          size="sm"
                          data-testid={`btn_paid_${eventTitleForID}${meeting.id}`}
                          id={`btn_paid_${eventTitleForID}${meeting.id}`}
                          color="paid"
                          className='w-full h-8 mx-0 normal-case'
                          disabled>
                          {t('paid')}
                        </Button>
                      )}
                      {creatorDisplayButton === 'INVITED' && (
                        <Button
                          size="sm"
                          data-testid={`btn_invited_${eventTitleForID}${meeting.id}`}
                          id={`btn_invited_${eventTitleForID}${meeting.id}`}
                          color="paid"
                          className='w-full h-8 mx-0 normal-case'
                          disabled>
                          {t('invited')}
                        </Button>
                      )}
                      {creatorDisplayButton === 'JOIN' && (
                        <Button
                          size="sm"
                          data-testid={`btn_join_${eventTitleForID}${meeting.id}`}
                          id={`btn_join_${eventTitleForID}${meeting.id}`}
                          rounded
                          color="coHost"
                          className='w-full h-8 mx-0 cursor-pointer normal-case'
                          onClick={() => joinMeeting(meeting.id)}>
                          {t('join')}
                        </Button>
                      )}
                      {creatorDisplayButton === 'COHOST' && (
                        <Button
                          data-testid={`btn_coHost_${eventTitleForID}${meeting.id}`}
                          id={`btn_coHost_${eventTitleForID}${meeting.id}`}
                          size="sm"
                          color="coHost"
                          className='w-full mx-0 normal-case'
                          disabled>
                          {t('co-Host')}
                        </Button>
                      )}
                      {creatorDisplayButton === 'REGISTER' && (
                        <Button
                          data-testid={`btn_register_${eventTitleForID}${meeting.id}`}
                          id={`btn_register_${eventTitleForID}${meeting.id}`}
                          size="sm"
                          color="payNow"
                          className='w-full h-8 mx-0 normal-case'
                          onClick={toggleFreeEventAnonymousModal}>
                          {t('register')}
                        </Button>
                      )}
                      {creatorDisplayButton === 'REGISTERED' && (
                        <Button
                          data-testid={`btn_registered_${eventTitleForID}${meeting.id}`}
                          id={`btn_registered_${eventTitleForID}${meeting.id}`}
                          size="sm"
                          className='w-full h-8 mx-0 normal-case'
                          color="paid"
                          disabled>
                          {' '}
                          {t('registered')}{' '}
                        </Button>
                      )}
                      {!creatorDisplayButton && ' '}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-testid={`txt_title${meeting.id}`}
          id={'txt_eventTitle'}
          className="text-base px-4 py-1 mt-1 font-normal leading-[1.3rem] text-black line-clamp-2 h-16 w-full overflow-hidden cursor-pointer">
          {fromHostEvent ? (
            meetingDetails?.title
          ) : (
            meeting?.meetingDetails?.title && (
              meeting.meetingDetails.title.charAt(0).toUpperCase() + meeting.meetingDetails.title.slice(1)
            )
          )}
        </div>
      </div>
      {isOpen && <JoinAnonymousModal isOpen={isOpen} toggle={toggle} meetingDetails={meetingDetails} meeting={meeting} payment={payment} RFMPayNow={false} razorpayOpen={razorpayOpen} />}
      {isFreeEventAnonymousModal && <FreeEventAnonymousModal isOpen={isFreeEventAnonymousModal} toggle={toggleFreeEventAnonymousModal} meetingDetails={meetingDetails} meeting={meeting} handleRegister={handleRegister} />}

    </>
  )
}

/**
 * A component that displays a countdown timer for a given meeting.
 * It displays the time difference between the current time and the meeting's start time.
 * If the meeting is within the next 6 hours, it displays the time difference in the format 'X days, Y hours, Z minutes, W seconds'.
 * Otherwise, it displays the date and time of the meeting in the format 'MMM DD, YYYY, HH:mm'.
 * @param {{meeting: Meeting}} props - Component props
 * @param {Meeting} props.meeting - The meeting object
 * @returns {React.ReactElement} A React element displaying the countdown timer
 */
function DisplayCardCountDown({meeting}: {meeting: Meeting}) {
  const { displayTimerFormatted } = useCountDownHook({
    meetingObj: meeting,
    next6Hour: true,
  })
  return <>{displayTimerFormatted}</>
}
