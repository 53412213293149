/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../Store';
import {settingApi} from './setting.service';

interface UtilState {
  showViewMoreModal: boolean;
  isAppOffline: boolean;
  serverDate: string | null;
}

const initialStateValue: UtilState = {
  showViewMoreModal: false,
  isAppOffline: false,
  serverDate: null,
};

/**
 * Creates a slice for managing the util state.
 *
 * @description This slice provides actions for changing and resetting the util state.
 * @return {object} The created slice object, including the reducer and actions.
 */

export const utilSlice = createSlice({
  name: 'util',
  initialState: initialStateValue,
  reducers: {
    setShowViewMoreModal: (state, action: PayloadAction<boolean>) => {
      state.showViewMoreModal = action.payload;
    },
    updateAppOffline: (state, action: PayloadAction<boolean>) => {
      state.isAppOffline = action.payload;
    },
    setServerDate: (state, action) => {
      state.serverDate = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      settingApi.endpoints.getServerDateTime.matchFulfilled,
      (state, action) => {
        state.serverDate = (action.payload as any)?.date;
      },
    );
  },
});

export const getShowViewMoreModal = (state: RootState): boolean =>
  state.util.showViewMoreModal;
export const getIsAppOffline = (state: RootState): boolean =>
  state.util.isAppOffline;
export const getServerDate = (state: RootState): string =>
  state.util.serverDate;

export const {setShowViewMoreModal, updateAppOffline, setServerDate} =
  utilSlice.actions;

export default utilSlice.reducer;
