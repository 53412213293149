/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../Components/base/button/button'
import Icon from '../Components/base/icon/icon'
import Loading from '../Components/base/loading/loading'
import { ToolTip } from '../Components/base/tooltip/tooltip'
import { PageTitle } from '../Components/pageTitle'
import { toastSuccess } from '../Components/toast'
import { useEventPolicyAgreementHooks } from '../Hooks/EventPolicyAgreementHooks'

/**
 * Opens a new window and prints the contents of the element with id 'printableArea'.
 * The new window is given a width of 900px and a height of 650px.
 * The title of the new window is set to 'Terms And Conditions'.
 */
const printPageArea = () => {
  const divContents = document.getElementById('printableArea')?.innerHTML
  const a = window.open('', '', 'width=900,height=650')
  a?.document.write('<html>')
  a?.document.write(
    '<body> <h1> <center>Terms And Conditions</center> </h1> <br>',
  )
  a?.document.write('</body></html>')
  a?.document.write(divContents ? divContents : '')
  a?.document.close()
  a?.focus()
  a?.print()
  a?.close()
}

/**
 * Component for displaying the event creation policy to the user and allowing them to accept or decline it.
 * If the user has already accepted the policy, they will be redirected to the new event page.
 * If the user has not accepted the policy, they will be displayed the policy text and given the option to accept or decline.
 */
function EventPolicy() {
  const { t } = useTranslation()
  const navigate = useNavigate()
 
  const  {policies, eventPolicyAgreed, agree, policiesSuccess} = useEventPolicyAgreementHooks()

  useEffect(() => {
    if (eventPolicyAgreed) {
      toastSuccess(t('eventPolicyAgreed'))
      navigate('/s/events/newEvent')
    }
  }, [eventPolicyAgreed, navigate, t])

  if (!policiesSuccess) {
    return <Loading />
  }
  return (
    <PageTitle title='eventCreationAgreement'>
      <div className="flex flex-col justify-center mx-10 mb-4">
          <div id="printableArea" data-testid="printableArea" className="mx-3">
            {policies?.policyName} - {policies?.policyVersion}
            <br />
            <span className='text-justify'
              dangerouslySetInnerHTML={{
                __html: policies ? policies?.description : '',
              }}></span>
          </div>
      </div>
      <div className="flex items-center float-right mx-10 mt-5">
        <Button size="sm" className="p-1" onClick={printPageArea}>
          <ToolTip tip="print">
            <Icon icon="PRINT" size="medium" height="large" />
          </ToolTip>
        </Button>
        <Button id="btn_cancel" onClick={() => navigate('/s/events/my-events')}>{t('cancel')}</Button>
        <Button id="btn_accept" onClick={() => agree()}>{t('accept')}</Button>
      </div>
    </PageTitle>
  )
}
export default EventPolicy
