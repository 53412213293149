/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { GridLayoutProps, TrackLoop } from '@livekit/components-react'
import * as React from 'react'
import { useCustomRoomContext } from './CustomRoomContext'
import {
  useGetHostAndCoHost,
  useReceiveStagedParticipantsList,
  useScreenDimensions,
  useSendStagedParticipantsList,
} from '../livekitHooks'
import { useCustomGridContext } from './CustomGridContext'
import { Track } from 'livekit-client'

/**
 * CustomGridLayout component displays the nested participants in a grid where every participant has the same size.
 *
 * @param {GridLayoutProps} props - The props object containing tracks and additional props.
 * @return {JSX.Element} The rendered GridLayout component.
 */

export function CustomGridLayout({ tracks, ...props }: GridLayoutProps) {
  const { gridWrapperRef } = useScreenDimensions(tracks)
  const {
    gridDimensions,
    stagedParticipants,
    handRaisedParticipants,
    unpinnedLayoutPositions,
    viewType,
    setAllTracksSids,
    setAllTracks,
  } = useCustomRoomContext()
  const { hostAndCoHosts } = useGetHostAndCoHost()
  const { clearPinnedParticipants, setPinnedParticipants } = useCustomGridContext()

  useSendStagedParticipantsList()
  useReceiveStagedParticipantsList()

  const otherTracksForHost = tracks.filter(
    (track) =>
      stagedParticipants.includes(track.participant.sid) || handRaisedParticipants.includes(track.participant.sid)
  )

  const count = otherTracksForHost.length + unpinnedLayoutPositions.length

  const hostScreenSharedTracks = tracks
    .filter(
      (track) =>
        track.source === Track.Source.ScreenShare && hostAndCoHosts.some((host) => host === track.participant.sid)
    )
    .map((track) => track.participant.sid + '-' + track?.source)

  const screenSharedTracks = tracks
    .filter((track) => track.source === Track.Source.ScreenShare)
    .map((track) => track.participant.sid + '-' + track?.source)

  React.useEffect(() => {
    if (viewType === 'grid') {
      clearPinnedParticipants()
    } else if (viewType === 'hostHighlighted') {
      setPinnedParticipants(hostAndCoHosts)
      if (hostScreenSharedTracks.length > 0) {
        setPinnedParticipants(hostScreenSharedTracks)
      }
      if (screenSharedTracks.length > 0) {
        setPinnedParticipants(screenSharedTracks)
      }
    }
  }, [viewType, hostAndCoHosts.length, hostScreenSharedTracks.length, screenSharedTracks.length])

  React.useEffect(() => {
    setAllTracksSids(
      tracks.map((track) =>
        track?.source === Track.Source.ScreenShare ? track.participant.sid + '-' + track?.source : track.participant.sid
      )
    )
    setAllTracks(tracks)
  }, [tracks])

  return (
    <>
      <div className={`flex flex-row w-full h-full overflow-x-scroll overflow-hidden scrollbar-hide`}>
        <div
          ref={gridWrapperRef}
          className="w-[100vw] responsive-grid relative"
          style={{ minWidth: count * 17 * 6 + 'px' }}
        >
          <TrackLoop tracks={tracks}>{props.children}</TrackLoop>
        </div>
        <div>
          {unpinnedLayoutPositions.length > 3 && (
            <span
              style={{
                position: 'absolute',
                bottom: gridDimensions.height / 8,
                right: gridDimensions.width / 25,
                backgroundColor: '#FFC200',
                color: 'black',
                paddingTop: '6px',
                paddingBottom: '6px',
                paddingLeft: '10px',
                paddingRight: '10px',
                borderRadius: '4px',
                fontSize: '12px',
                fontWeight: 'bold',
                zIndex: 1000,
              }}
            >
              {unpinnedLayoutPositions.length - 3} others
            </span>
          )}
        </div>
      </div>
    </>
  )
}
