/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {Auth} from 'aws-amplify';
import {useState} from 'react';
import {awsLogout} from '../Services/tokenReducer';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../Store/hooks';

/**
 * Custom hook to handle the reset password functionality.
 *
 * @param {string} [email] - The email address to send the password reset code to.
 * @return {object} An object containing the error message, navigation screen state, and various callback functions to handle the password reset process.
 */

export const useResetPasswordHook = (email?: string) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [navigateScreen, setNavigateScreen] = useState(false);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [codeSentMessage, setCodeSentMessage] = useState(false);

  dispatch(awsLogout());

  const onSubmit = (values: any) => {
    Auth.forgotPasswordSubmit(
      email ? email : '',
      values.code,
      values.confirmPassword,
    )
      .then(() => {
        if (!showToastMessage) {
          setShowToastMessage(true);
        }

        setTimeout(() => {
          if (!navigateScreen) {
            setNavigateScreen(true);
          }
        }, 2000);
      })
      .catch(error => {
        if (!errorMessage) {
          setErrorMessage('wrongCodeEntered');
        }

        console.log(error);
      });
  };

  function handleResendCodeClick() {
    if (email) {
      Auth.forgotPassword(email)
        .then(data => {
          setCodeSentMessage(true);
        })
        .catch(err => {
          if (err.code === 'LimitExceededException') {
            setErrorMessage(t('attemptLimitExceededPleaseTryagainlater'));
          } else {
            setErrorMessage(err.message);
          }
        });
    }
  }

  const handlePassword = (e: any) => {
    if (e.nativeEvent.code === 'Space') {
      e.preventDefault();
    }
  };

  return {
    errorMessage,
    setErrorMessage,
    navigateScreen,
    onSubmit,
    t,
    showToastMessage,
    setShowToastMessage,
    handlePassword,
    handleResendCodeClick,
    codeSentMessage,
    setCodeSentMessage,
  };
};
