/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

const avatarColors = [
  '#D18000',
  '#D1413F',
  '#DD1576',
  '#C233A0',
  '#6163E1',
  '#C4D6EA',
  '#73B5BD',
  '#7BA100',
  '#9355D2',
  '#627A89',
];

/**
 * Hook to generate avatar colors based on a given name.
 *
 * @return {{ getBackground: (name?: string | null) => string }} An object containing the getBackground function.
 */

export const useAvatarColors = () => {
  const getBackground = (name?: string | null) => {
    if (!name) {
      return avatarColors[0];
    } else {
      return avatarColors[name.length % avatarColors.length];
    }
  };

  return {getBackground};
};
