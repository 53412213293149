/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {useSelector} from 'react-redux';
import {getCurrentUser, getPreference} from '../Services/userReducer';
import {useGetUserDetailsQuery} from '../Services/userApi';
import {useGetMeetingDetailsByUserQuery} from '../Services/meetingDetailsApi';
import {useGetByCUserCurrencyQuery} from '../Services/setting.service';
import {useEffect, useState} from 'react';
import {Verified} from '../models/user.model';

/**
 * A custom React hook for managing host profile-related data and functionality.
 *
 * It fetches the host's data, meeting event details, and minimum cost, and provides
 * states for checking anonymous and verified status. It also returns the preference,
 * loading states, and error.
 *
 * @param {string} hostId - The ID of the host to fetch data for
 * @return {object} An object containing the fetched data, states, and preference
 */

export const useViewHostProfileHook = (hostId: string) => {
  const currentUser = useSelector(getCurrentUser);
  const preference = useSelector(getPreference);

  const {
    data: hostData,
    isLoading: loadingHostData,
    isSuccess,
    error,
  } = useGetUserDetailsQuery(hostId ?? '', {skip: !hostId});
  const {
    data: eventDetailsData,
    isLoading: loadingEventDetails,
    isSuccess: eventDetailsSuccess,
  } = useGetMeetingDetailsByUserQuery(hostId ? hostId : '', {skip: !hostId});
  const {data: minCost} = useGetByCUserCurrencyQuery();
  const [checkAnonymous, setCheckAnonymous] = useState<
    'ANONYMOUS' | 'NOTANONYMOUS' | 'REQUESTFORMEET'
  >();
  const [checkVerified, setCheckVerified] = useState<
    'VERIFIED' | 'NOTVERIFIED'
  >();
  useEffect(() => {
    if (hostData?.verified === Verified.Complete) {
      setCheckVerified('VERIFIED');
    }
    if (
      hostData &&
      hostData.allowVideo &&
      !(hostData.id === currentUser.id) &&
      currentUser.phoneVerified === Verified.Complete &&
      currentUser.emailVerified === Verified.Complete
    ) {
      setCheckAnonymous('REQUESTFORMEET');
    }
  }, [hostData, currentUser]);

  return {
    preference,
    checkAnonymous,
    checkVerified,
    minCost,
    loadingHostData,
    loadingEventDetails,
    isSuccess,
    error,
    hostData,
    eventDetailsData,
    eventDetailsSuccess,
  };
};
