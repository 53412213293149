/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { ToolTip } from "./base/tooltip/tooltip";
import Icon from '../Components/base/icon/icon'

/**
 * A component that renders a help link as a question mark icon.
 *
 * When clicked, opens the BeeMG help site in a new tab.
 *
 * @returns A JSX element with a help link.
 */
export function HelpLink() {
    return <>
        <a href="https://help.beemg.com/" target={"_blank"} rel="noreferrer">
            <span id="chk_helpNavigate">
                <div>
                    <ToolTip tip={'help'} keyId="help">
                        <Icon
                            icon="QUESTION"
                            size="medium"
                            height="large"
                        />
                    </ToolTip>
                </div>
            </span>
        </a>
    </>
}