/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {useEffect, useState} from 'react';
import {useGetRequestMeetingsQuery} from '../Services/meetingApi';
import {isAfter} from 'date-fns';
import {Meeting, Request} from '../models/meeting.model';

/**
 * A custom React hook to manage request events, providing functionality to filter and sort sent and received requests.
 *
 * @return {object} An object containing the request data, loading and success states, refetch function, and functions to filter and sort requests.
 */

export const useRequestEventsHook = () => {
  const {
    data: requests,
    isSuccess: requestsEventsSuccess,
    isLoading: requestsEventsLoading,
    refetch: refetchRequestEvents,
    isFetching: isRequestEventsRefetching,
  } = useGetRequestMeetingsQuery();

  const [sentRequests, setSentRequests] = useState<Meeting[]>([]);
  const [receivedRequests, setReceivedRequests] = useState<Meeting[]>([]);

  useEffect(() => {
    if (requests?.length) {
      setSentRequests(
        requests.filter(request => request.request === Request.SentRequests),
      );
      setReceivedRequests(
        requests.filter(
          request => request.request === Request.ReceivedRequests,
        ),
      );
    }
  }, [requests, isRequestEventsRefetching, requestsEventsSuccess]);

  const getLatestSorted = (requests: Meeting[]) => {
    const latestSorted = [...requests].sort((a, b) => {
      return isAfter(new Date(a.fromDateTime), new Date(b.fromDateTime))
        ? -1
        : 1;
    });

    return latestSorted;
  };

  const filterLatestRequests = ({
    requestType,
    latest,
  }: {
    requestType: Request;
    latest: boolean;
  }) => {
    if (requests) {
      if (requestType === Request.ReceivedRequests) {
        if (latest) {
          setReceivedRequests(
            getLatestSorted(
              requests.filter(
                request => request.request === Request.ReceivedRequests,
              ),
            ),
          );
        } else {
          setReceivedRequests(
            requests.filter(
              request => request.request === Request.ReceivedRequests,
            ),
          );
        }
      } else {
        if (latest) {
          setSentRequests(
            getLatestSorted(
              requests.filter(
                request => request.request === Request.SentRequests,
              ),
            ),
          );
        } else {
          setSentRequests(
            requests.filter(
              request => request.request === Request.SentRequests,
            ),
          );
        }
      }
    }
  };

  return {
    requests,
    requestsEventsLoading,
    requestsEventsSuccess,
    refetchRequestEvents,
    isRequestEventsRefetching,
    sentRequests,
    receivedRequests,
    filterLatestRequests,
  };
};
