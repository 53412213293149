/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import Avatar from '../../Components/base/avatar/avatar'
import { MeetingParticipant } from '../../models/meetingParticipant.model'

interface displayNextCardProps {
  key: string
  invitedGuest: MeetingParticipant
  index: number
}

/**
 * A component to display a guest card.
 *
 * @param {displayNextCardProps} props - The component props.
 * @param {MeetingParticipant} props.invitedGuest - The invited guest to display.
 * @param {number} props.index - The index of the component in the parent component.
 * @return {JSX.Element} The component element.
 */
export default function DisplayGuestCard( { invitedGuest, index } : displayNextCardProps) {

  return (
    <div key={invitedGuest.id} className={`flex flex-col items-center w-full mr-4 mb-6 xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6`} >
    {invitedGuest?.user && (
      <div className='flex flex-col items-center mr-2'>
        <Avatar images={invitedGuest.user.image} avatar={invitedGuest.user.avatar} anonymous={invitedGuest.user.anonymous} />
        <div className='text-sm text-center font-semibold truncate' data-testid="firstname">
          <div>
            {invitedGuest.user.firstName.length > 13
              ? invitedGuest.user.firstName.slice(0, 13) + '...'
              : invitedGuest.user.firstName}
          </div>
        </div>
      </div>
    )}
    </div>
  )
}
