/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import CollaboratorsZoomCard from '../base/card/CollaboratorsZoomCard'
import 'react-multi-carousel/lib/styles.css'
import { User } from '../../models/user.model'
import Loading from '../base/loading/loading'
import Whirligig from '../base/carousel/whirligig/whirligig'
import Icon from '../base/icon/icon'
import useWindowDimensions from '../base/carousel/whirligig/utils'
import { useCollaboratorHooks } from '../../Hooks/CollaboratorHook'
import Field from '../base/field/field'
import NewIcon from '../base/icon/newIcons'
import { useCollaboratorMatchHooks } from '../../Hooks/CollaboratorMatchHook'
import NoSession from '../NoSession'
export interface CollaboratorsProps {
  title: string
  users?: User[]
}

  /**
   * A React component for rendering a list of collaborators.
   *
   * It is a responsive carousel component that renders a list of
   * `CollaboratorsZoomCard` components. It also renders a title
   * and a 'prev' and 'next' button to navigate the list.
   *
   * @param {CollaboratorsProps} props - The props passed to the component.
   * @returns {ReactElement} - The rendered component.
   *
   * @param {string} props.title - The title to render above the list.
   * @param {User[]} [props.users] - The list of users to render.
   */
const CollaboratorsLayout = (props: CollaboratorsProps) => {

  const style = {
    title: `text-2xl font-medium text-gray-400 my-8`,
  }

  const cardWidth = 215
  let whirligig: any
  const next = () => whirligig.next()
  const prev = () => whirligig.prev()
  const { width } = useWindowDimensions()
  let numberOfCards = Math.floor(width / cardWidth)
  let gap = '2rem'

  const {sortedCollaborators} = useCollaboratorMatchHooks(props)

  return (
    <>
      <div className={style.title}>
        <div className="flex justify-between">
          <div className="font-semibold text-gray-400 mb-4 text-2xl lg:text-[1.8rem]" id={props.title.split(' ').join('')} data-testid={`${props.title}-${props.users?.length}`}>
            {props.title}
          </div>
          {props.users && props.users.length >= numberOfCards && (
            <div data-testid="cards" className='mr-3'>
              <button onClick={prev} className="mr-5" data-testid="prevButton">
                <Icon icon="LEFT" size="medium" height="large" stroke='#535353'/>
              </button>
              <button onClick={next} data-testid="nextButton">
                <Icon icon="RIGHT" size="medium" height="large" stroke='#535353'/>
              </button>
            </div>
          )}
        </div>
        <Whirligig
          visibleSlides={numberOfCards}
          gutter={gap}
          ref={_whirligigInstance => {
            whirligig = _whirligigInstance
          }}>
          {sortedCollaborators &&
            sortedCollaborators.map((user, index) => (
              <CollaboratorsZoomCard
                key={user.id}
                bgUrl={user.anonymous ? user.avatar : (user.image[0]?.webpImageName ? user.image[0]?.webpImageName : user.image[0]?.imageName)}
                name={user.anonymous ? user.nickName : user.firstName}
                match={user.match}
                cardTitle={props.title}
                keyId={index}
                userId={user.userId}
                id={user.id}
                catchPhrase={user.catchPhrase}
                data-testid="user-cards" />
            ))}
        </Whirligig>
      </div>
    </>
  )
}

  /**
   * A React component for rendering a list of collaborators.
   *
   * It renders a search bar and three sections of collaborators: people near you,
   * people like you, and people you met recently. Each section is rendered as a carousel
   * that can be navigated with 'prev' and 'next' buttons. If there are no collaborators in
   * a section, it is not rendered.
   *
   * @returns {ReactElement} - The rendered component.
   */
const CollaboratorsCard = () => {
  const { t } = useTranslation()


  const { nearUsersToDisplay, nearUsersSuccess, recentUsersToDisplay, recentUsersSuccess, usersLikeYouToDisplay, register, setValue, usersLikeYouSuccess, } = useCollaboratorHooks()


  if (!nearUsersSuccess || !recentUsersSuccess || !usersLikeYouSuccess) {
    return <Loading />
  }

  return (
    <div className='pl-4 lg:pl-[10%]'>
      <div className="flex justify-start">
        <Field
          {...register('name')}
          name="name"
          type="text"
          data-testid="searchByName"
          placeholder={t('searchByName')}
          icon={<NewIcon icon="SEARCH_GLASS"  stroke='#535353'/>}
          onChange={(e: any) => {
            setValue("name", e.target.value)
          }}
        />
      </div>

      {nearUsersToDisplay && nearUsersToDisplay.length > 0 && (
        <CollaboratorsLayout
          title={t('peopleNearYou')}
          users={nearUsersToDisplay} />

      )}
      {usersLikeYouToDisplay && usersLikeYouToDisplay.length > 0 && (
        <CollaboratorsLayout
          title={t('peopleLikeYou')}
          users={usersLikeYouToDisplay}
        />
      )}
      {recentUsersToDisplay && recentUsersToDisplay.length > 0 && (
        <CollaboratorsLayout
          title={t('peopleYouMetRecently')}
          users={recentUsersToDisplay}
        />
      )}
      {recentUsersToDisplay && usersLikeYouToDisplay && recentUsersToDisplay.length <=0 && usersLikeYouToDisplay.length <=0 && (
        <NoSession type="collobraters" className='justify-start pl-4 lg:pl-[10%] mb-2' />
      )}
    </div>
  )
}

export default CollaboratorsCard
