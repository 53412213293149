/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {meetingApi} from '../Services/meetingApi';
import {useAppDispatch} from '../Store/hooks';

/**
 * Custom hook to check if a meeting exists within a given time range.
 *
 * @return {{ checkMeetingExists: (fromDateTime: string, toDateTime: string, callback: (result: boolean) => void) => void }}
 *         An object containing a single method checkMeetingExists.
 */

export const useCheckMeetingExists = () => {
  const dispatch = useAppDispatch();

  const checkMeetingExists = (
    fromDateTime: string,
    toDateTime: string,
    callback: (result: boolean) => void,
  ) => {
    const args = {
      fromDateTime: fromDateTime.slice(0, -4) + '000Z',
      toDateTime: toDateTime.slice(0, -4) + '000Z',
    };
    const res = dispatch(
      meetingApi.endpoints.checkMeetingForRequest.initiate(args),
    );
    res.then((val: any) => {
      if (val.data) {
        callback(true);
      } else {
        callback(false);
      }
    });
  };

  return {
    checkMeetingExists,
  };
};
