/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import Tippy from '@tippyjs/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import 'tippy.js/themes/translucent.css';
import { tr } from '../../../Utils/helper';

interface Props {
  title: JSX.Element | string;
  position: 'top' | 'bottom';
  children: ReactNode;
  className: string;
  iconClassName?: string;
  cursor?: boolean;
  keyId?: string;
}

const positions = {
  top: 'bottom-full',
  bottom: 'top-full',
};

/**
 * A tooltip component that displays a message when hovered or clicked.
 * @param {string} keyId - An ID to use as the key for the tooltip.
 * @param {string} position - The position of the tooltip relative to the element it is attached to. Can be 'top' or 'bottom'.
 * @param {string|JSX.Element} title - The title of the tooltip.
 * @param {string|JSX.Element} children - The message to display in the tooltip.
 * @param {string} className - Additional CSS classes to apply to the tooltip.
 * @param {string} [iconClassName] - Additional CSS classes to apply to the tooltip's icon.
 * @param {boolean} [cursor] - Whether the tooltip should have a pointer cursor.
 */
const Tooltip = ({
  keyId,
  position,
  title,
  children,
  className,
  iconClassName,
  cursor = true,
}: Props) =>
(
  <div id={`title-${keyId}`} className={`${cursor ? 'group relative text-center cursor-pointer' : 'group relative text-center cursor-default'}`}>
    {title}
    <div data-testid={`message-${children}`} id={`message-${children}`}
      className={`${positions[position]} ${className} opacity-0 text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 -left-8 mr-20 px-3 pointer-events-none`}
    >
      {children}
      <svg
        className={`${iconClassName} absolute h-2 w-full left-0 top-full`}
        x="0px"
        y="0px"
        viewBox="0 0 255 255"
        xmlSpace="preserve"
      >
        <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
      </svg>
    </div>
  </div>
);
interface TooltipProps {
  tip: string;
  children: ReactNode;
  keyId?: string;
  theme?: 'light' | 'light-border' | 'material' | 'translucent' | 'BeeMG-gray' | 'BeeMG-orange' | 'BeeMG-yellow';
  cursor?: 'pointer' | 'default';
  trElements?: {};
  trigger?: 'click'; 
  isCapitalize?:boolean; 
}

/**
 * A tooltip component that displays a translated message when hovered or clicked.
 * @param {string} tip - The key of the message to display in the tooltip.
 * @param {ReactNode} children - The element that the tooltip is attached to.
 * @param {string} keyId - An ID to use as the key for the tooltip.
 * @param {string} [theme='BeeMG-gray'] - The theme of the tooltip. Can be 'light', 'light-border', 'material', 'translucent', 'BeeMG-gray', 'BeeMG-orange', or 'BeeMG-yellow'.
 * @param {string} [cursor='default'] - The cursor style of the tooltip. Can be 'pointer' or 'default'.
 * @param {Record<string, string>} [trElements] - An object of key-value pairs to replace in the translated message.
 * @param {string} [trigger='hover'] - The event to trigger the tooltip. Can be 'hover', 'click', or 'focus'.
 * @param {boolean} [isCapitalize] - Whether to capitalize the first letter of the tooltip content.
 */
export const ToolTip = ({ tip, children, keyId, theme = 'BeeMG-gray', cursor = "default", trElements, trigger, isCapitalize,}: TooltipProps) => {

  const { t } = useTranslation();

  return (
    <div id={`title-${keyId}`} data-testid={`tooltip-${keyId}`}>
      {!trElements &&  <Tippy  className={isCapitalize ? 'capitalize' : ''} theme={theme} content={t(tip)} offset={[0, 8]} trigger={trigger}><div className={`cursor-${cursor} group relative text-center `}>{children}</div></Tippy>}
      {trElements && <Tippy theme={theme} content={tr(tip, trElements)} offset={[0, 8]} trigger={trigger}><div className={`cursor-${cursor} group relative text-center`}>{children}</div></Tippy>}
    </div>
  )
}

export default Tooltip;
