/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import Field from '../../Components/base/field/field'

interface invitedGuestsProps {
  key: String
  invitedEmail: string
  index: number
}

/**
 * A component to display a list of invited guests in a payment form.
 * @param {invitedGuestsProps} props - The component props.
 * @param {String} props.key - The key of the component.
 * @param {String} props.invitedEmail - The email of the invited guest.
 * @param {number} props.index - The index of the component in the list of invited guests.
 * @returns {JSX.Element} The JSX element representing the component.
 */
export default function InvitedGuest(props: invitedGuestsProps) {
  const { invitedEmail, index } = props
  const { t } = useTranslation()
  return (
    <div className="w-full justify-center" key={index}>
      {invitedEmail &&
        <Field
          maxLength={50}
          name={`data.email`} type="email" placeholder={t('emailID')} style={{ textAlign: 'center' }}
          disabled={true}
          defaultValue={invitedEmail} 
          data-testid="data.email"/>
      }
    </div>
  )
}
