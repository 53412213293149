/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from '../../Components/_utils/useToggle';
import Button from '../../Components/base/button/button';
import Icon from '../../Components/base/icon/icon';
import { Modal, ModalBody, ModalFooter } from '../../Components/base/modal/modal';
import { ToolTip } from '../../Components/base/tooltip/tooltip';
import { toastError, toastSuccess } from '../../Components/toast';
import { RequestForMeetingForm, useRequestForMeetingHooks } from '../../Hooks/RequestForMeetingHook';
import { useCurrencyHook } from '../../Hooks/CurrencyHooks';
import { DisplayFormattedDate, DisplayFormattedTime } from '../../Components/formattedDateAndTime';

interface _RFMConfirmationModalProps {
    closeRFMConfirmModal: () => void
    rfmData: RequestForMeetingForm
    hostId: string
}

/**
 * A modal component for confirming request for meeting details.
 * It includes a table for displaying the request details, a button for canceling the request,
 * and a button for confirming the request.
 * If the request is successfully sent, it displays a success toast message and closes the modal.
 * If there is a server error, it displays an error toast message and closes the modal.
 * @param {object} props - The component props
 * @param {function} props.closeRFMConfirmModal - A callback function to close the modal
 * @param {object} props.rfmData - The request for meeting data object
 * @param {string} props.hostId - The host ID
 * @returns {JSX.Element} The RFM confirmation modal component
 */
export default function RFMConfirmationModal(props: _RFMConfirmationModalProps) {
    const { closeRFMConfirmModal, rfmData, hostId } = props
    const { t } = useTranslation()
    const changeToggle = () => closeRFMConfirmModal()
    const { toggle } = useToggle()
    const { submit, requestSentSuccess, preference, requestSentLoading, requestSentError,  serverErrorMessages, setServerErrorMessages } = useRequestForMeetingHooks({ hostId })
    const { formatCurrency } = useCurrencyHook()
    useEffect(() => {
      if (requestSentSuccess) {
        toastSuccess(t('requestSentSuccessfully'))
        changeToggle()
      } else if (serverErrorMessages === 'BeeMG-ERR022') {
        toastError(t(serverErrorMessages))
        changeToggle()
        setServerErrorMessages('')
      } else if (serverErrorMessages === 'BeeMG-ERR105') {
        toastError(t(serverErrorMessages))
        changeToggle()
        setServerErrorMessages('')
      }
    }, [requestSentSuccess, serverErrorMessages, requestSentError])

    return (
      <div>
        <Modal isOpen={true} toggle={toggle} closeOnClickOutside={false}>
          <div
            className="flex justify-end cursor-pointer"
            onClick={changeToggle}>
            <ToolTip tip={'close'}>
              <Icon icon="CLOSE" />
            </ToolTip>
          </div>
          <ModalBody>
            <>
              <div
                id="modal_noOfParticipants"
                className="flex flex-col gap-y-2 p-3">
                <div className="text-xl font-normal border-b w-full p-3">
                  {t('privateSessionRequest')}
                </div>
                <div className="h-0.5" />
                {rfmData?.selectedDates?.length === 1 ? (
                  <div className="flex flex-col">
                    <span className="font-semibold" data-testid="session1">
                      {t('session')}
                      {' 1'}
                    </span>
                    <span className="font-semibold" data-testid="selectedDate">
                      <DisplayFormattedTime
                        displayFromTime={rfmData.selectedDates[0].startDate}
                        displayToTime={rfmData.selectedDates[0].endDate}
                      />
                    </span>
                    <span className="font-semibold" data-testid="selectedTime">
                      <DisplayFormattedDate
                        displayDate={rfmData.selectedDates[0].startDate}
                      />
                    </span>
                    </div>
                ) : (
                  rfmData?.selectedDates?.map((field, index) => {
                    return (
                      <div className="flex flex-row justify-between gap-x-2 ">
                        <div
                          key={`optiondisplay${index + 1}`}
                          className="flex flex-col border-b w-full pb-2">
                          <span
                            data-testid={`session${index + 1}`}
                            className="font-semibold">
                            {t('session')} {index + 1}
                          </span>
                          <span
                            data-testid={`selectedDate${index + 1}`}
                            className="font-semibold">
                            <DisplayFormattedTime displayFromTime={field.startDate} displayToTime={field.endDate} />
                          </span>
                          <span
                            data-testid={`selectedTime${index + 1}`}
                            className="font-semibold">
                            <DisplayFormattedDate displayDate={field.startDate} />
                          </span>
                        </div>
                      </div>
                    )
                  })
                )}

                <span data-testid="meeting_purpose" className="text-gray-400">
                  {'* '}
                  {rfmData.details}
                  {' *'}
                </span>
                <span data-testid="meeting_cost" className="font-bold">
                  {t('totalCostOf')}{' '}
                  {formatCurrency(
                    Number(rfmData.unitPrice) * Number(rfmData.participants),
                    preference.currency,
                  )}{' '}
                  {t('for').toLowerCase()} {rfmData.participants}{' '}
                  {rfmData.participants == 1
                    ? t('userForRfm')
                    : t('usersForRfm')}
                </span>
              </div>
            </>
            <ModalFooter>
              <Button
                id="btn_cancel"
                data-testid="btn_cancel"
                onClick={changeToggle}
                color="footerButton">
                {t(`cancel`)}
              </Button>
              <Button
                className="flex items-center"
                submit
                onClick={() => {
                  submit(rfmData)
                }}
                id="btn_ok"
                data-testid="btn_ok"
                color="footerButton">
                {requestSentLoading && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                )}
                {t(`confirm`)}
              </Button>
            </ModalFooter>
          </ModalBody>
        </Modal>
      </div>
    )
}
