/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { forwardRef, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

interface FieldProps extends HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  autoFocus?: boolean;
  dot?: boolean;
  error?: string | any;
  icon?: JSX.Element;
  label?: string;
  name: string;
  type: string;
  readOnly?: boolean;
  maxLength?: number;
  restrict?: string;
  className?: string;
  min?: string | number;
  max?: string | number;
  rows?: number;
  cols?: number;
  autoComplete?: string;
  options?: {
    value: string,
    label: string
  }[];
}
// type Ref = ForwardedRef<never>;

const style = {
  checkbox: `checked:bg-gray-500 checked:right-1 focus:ring-transparent focus:outline-none right-5 duration-200 ease-in absolute block w-3 h-3 top-1 rounded-full border-0 appearance-none cursor-pointer`,
  checkboxContainer: `relative w-10 mr-2 align-middle select-none mt-3`,
  checkboxLabel: `block overflow-hidden h-5 rounded-full bg-gray-300 mb-1.5 ml-1`,
  container: `relative mb-1`,
  message: `text-base relative flex flex-1 w-full rounded-l-md rounded-r-none py-1 pl-4 pr-10 bg-white text-gray-400 placeholder:text-sm placeholder-gray-400 text-base focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300`,
  jitsiChatMessage: `text-base relative flex flex-1 w-full rounded-l-md rounded-r-none py-1 pl-4 pr-10 text-gray-400 placeholder:text-sm placeholder-gray-400 text-base focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 h-full`,
  default: `leading-5 text-sm relative flex flex-1 w-full rounded-md p-input text-gray-400 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 h-8`,
  dropDown: `leading-5 text-base relative flex flex-1 w-full rounded-md py-2 px-4 text-gray-400 placeholder:text-sm placeholder-gray-400 text-base focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300`,
  textArea: `text-base relative flex flex-1 w-full rounded-md p-input text-gray-400 placeholder:text-sm placeholder-gray-400 text-base focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 h-auto`,
  disabled: `cursor-text bg-gray-300 text-black`,
  dot: `text-red-500 pl-0.5`,
  error: `ring-red-500 ring-1`,
  errorMessage: `text-sm text-red-500 mt-2`,
  iconContainer: `absolute flex border border-transparent  rounded-md left-0 top-0 h-full w-10 r-2`,
  iconContainerRight: `absolute flex border border-transparent  rounded-md right-0 top-0 h-full w-10`,
  icon: `flex items-center justify-center rounded-tl-md rounded-bl-md z-10 text-gray-400 text-lg h-full w-full bg-white `,
  iconRight: `flex items-center justify-center  rounded-tr-md rounded-br-md z-10 text-gray-400 text-lg h-full w-full bg-white `,
}

const Field = forwardRef((props: FieldProps, ref: any) => {
  const { t } = useTranslation();
  const {
    disabled,
    dot,
    type = 'text',
    error,
    icon,
    label,
    name,
    options,
    readOnly,
    restrict,
    className,
    maxLength,
    min,
    max,
    rows,
    autoComplete = 'off',
    cols,
    ...rest
  } = props;

  let component;

  // if you won't use select, you can delete this part
  if (type === 'select') {
    component = (
      <select
        aria-required={dot}
        aria-invalid={!!error}
        className={`${style.dropDown} ${disabled ? style.disabled : ' bg-white '} ${error ? style.error : 'border-gray-300'}`}
        disabled={disabled}
        id={name}
        name={name}
        autoComplete={autoComplete}
        ref={ref}
        {...rest}
      />
    );
  }
  // if (type === 'Select') {
  //   component = (
  //     <Select
  //       value={{ defaultValue, label: defaultValue }}
  //       name={name} options={options}
  //       onChange={(selectedOption) => { onChange(selectedOption?.value) }}
  //   />


  //     // <select
  //     //   aria-required={dot}
  //     //   aria-invalid={!!error}
  //     //   className={`${style.dropDown} ${disabled ? style.disabled : ''}
  //     //      ${error ? style.error : 'border-gray-300'}`}
  //     //   disabled={disabled}
  //     //   id={name}
  //     //   name={name}
  //     //   ref={ref}
  //     //   {...rest}
  //     // />
  //   );
  // }
  // if you won't use textarea, you can delete this part
  if (type === 'textarea') {
    component = (
      <textarea
        aria-required={dot}
        aria-invalid={!!error}
        className={`${style.textArea} ${disabled ? style.disabled : ' bg-white '} 
          ${error ? style.error : 'border-gray-300'}`}
        disabled={disabled}
        id={name}
        name={name}
        ref={ref}
        rows={rows}
        cols={cols}
        autoComplete={autoComplete}
        {...rest}
      />
    );
  }

  if (type === 'message') {
    component = (
      <input
        aria-required={dot}
        aria-invalid={!!error}
        className={`${style.jitsiChatMessage} ${disabled ? style.disabled : ' bg-white '} 
          ${error ? style.error : 'border-gray-300'}`}
        disabled={disabled}
        type='text'
        id={name}
        name={name}
        maxLength={maxLength}
        autoComplete={autoComplete}
        ref={ref}
        {...rest}
      />
    );
  }

  // if you won't use checkbox, you can delete this part and the classes checkbox, checkboxContainer and checkboxLabel
  if (type === 'checkbox') {
    component = (
      <div className={style.checkboxContainer}>
        <input
          aria-required={dot}
          aria-invalid={!!error}
          className={`${style.checkbox} ${disabled ? style.disabled : '  bg-white '}`}
          style={{ backgroundImage: `none` }}
          disabled={disabled}
          autoComplete="off"
          id={name}
          data-testid={name}
          name={name}
          type="checkbox"
          ref={ref}
          readOnly={readOnly}
          {...rest}
        />
        <label htmlFor={readOnly ? undefined : name} className={style.checkboxLabel} />
      </div>
    )
  }

  // if you won't use input, you can delete this part
  if (
    type !== 'checkbox' &&
    type !== 'select' &&
    type !== 'textarea' &&
    type !== 'message'
  ) {
    component = (
      <div className="relative">
        {icon && <div className={style.iconContainer}>
          <div className={style.icon}>{icon}</div>
        </div>}
        <input
          aria-required={dot}
          aria-invalid={!!error}
          className={`${style.default} ${icon ? 'pl-12' : ''}  
            ${error ? style.error : 'border-gray-300'} 
            ${disabled ? style.disabled : '  bg-white '} `}
          disabled={disabled}
          autoComplete="off"
          maxLength={maxLength}
          id={name}
          name={name}
          type={type}
          ref={ref}
          {...rest}
        />
        {error && <ErrorIcon />}
      </div>
    )
  }

  if (type === 'number') {
    component = (
      <div className="relative">
        {icon && <div className={style.iconContainer}>
          <div className={style.icon}>{icon}</div>
        </div>}
        <input
          aria-required={dot}
          aria-invalid={!!error}
          className={`${style.default} ${icon ? 'pl-12' : ''}  
            ${error ? style.error : 'border-gray-300'} 
            ${disabled ? style.disabled : '  bg-white '} `}
          disabled={disabled}
          autoComplete={autoComplete}
          id={name}
          name={name}
          type={type}
          min={min}
          max={max}
          ref={ref}
          {...rest}
        />
        {error && <ErrorIcon type="number" />}
      </div>
    )
  }

  if (
    type !== 'checkbox' &&
    type !== 'select' &&
    type !== 'textarea' && (
      type === 'inputGroup' ||
      type === 'text')

  ) {
    component = (
      <div className="relative">
        {icon && <div className={style.iconContainerRight}>
          <div className={style.iconRight}>{icon}</div>
        </div>}
        <input
          aria-required={dot}
          aria-invalid={!!error}
          className={`${style.default}
            ${error ? style.error : 'border-gray-300'} 
            ${disabled ? style.disabled : ' bg-white '} ${className ? className : ''}`}
          disabled={disabled}
          autoComplete={autoComplete}
          maxLength={maxLength}
          id={name}
          name={name}
          type="text"
          ref={ref}
          {...rest}
        />
        {error && <ErrorIcon />}
      </div>
    )
  }

  if (
    type !== 'checkbox' &&
    type !== 'select' &&
    type !== 'textarea' && (
      type === 'inputGroupNumber')
  ) {
    component = (
      <div className="relative">
        {icon && <div className={style.iconContainerRight}>
          <div className={style.iconRight}>{icon}</div>
        </div>}
        <input
          aria-required={dot}
          aria-invalid={!!error}
          className={`${style.default}
            ${error ? style.error : 'border-gray-300'} 
            ${disabled ? style.disabled : '  bg-white '} ${className ? className : ''}`}
          disabled={disabled}
          autoComplete={autoComplete}
          maxLength={maxLength}
          id={name}
          name={name}
          type="number"
          ref={ref}
          {...rest}
        />
        {error && <ErrorIcon />}
      </div>
    )
  }

  if (type === 'password') {
    component = (
      <div className="relative">
        {icon && <div className={style.iconContainerRight}>
          <div className={style.iconRight}>{icon}</div>
        </div>}
        <input
          aria-required={dot}
          aria-invalid={!!error}
          className={`${style.default}
            ${error ? style.error : 'border-gray-300'} 
            ${disabled ? style.disabled : ' bg-white '} ${className ? className : ''}`}
          disabled={disabled}
          autoComplete={autoComplete}
          maxLength={maxLength}
          id={name}
          name={name}
          type={type}
          ref={ref}
          {...rest}
        />
        {error && <ErrorIcon />}
      </div>
    )
  }

  return (
    <div className={`${type !== 'select' ? style.container : ''} ${disabled ? 'opacity-50' : ''}`}>
      <label htmlFor={name} className="text-gray-700">
        {label}
        {dot && <span className={style.dot}>*</span>}
      </label>
      {component}
      {error && (
        <div role="alert" data-testid={error} id="error_message" className={style.errorMessage}>
          {t(error)}
        </div>
      )}
    </div>
  );
});

/**
 * A small red error icon.
 *
 * @param {{ type?: string }} props
 * @prop {string} [type] - If "number", the icon is shifted a bit to the left.
 *
 * @returns {JSX.Element} The error icon.
 */
const ErrorIcon = ({ type }: { type?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="currentColor"
    className={`${type === 'number' ? "absolute right-8 -mt-6 text-red-500" : "absolute right-4 -mt-6 text-red-500"}`}
    viewBox="0 0 1792 1792"
  >
    <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z" />
  </svg>
);

/**
 * A small lock icon.
 *
 * @returns {JSX.Element} The rendered LockIcon component.
 */
export const LockIcon = () => (
  <svg
    height="20"
    width="20"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 448 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z" />
  </svg>
);

Field.displayName = 'Field';

export default Field;
