/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useTranslation } from "react-i18next"
import { Ledger, TransactionType } from "../../models/ledger.model"

/**
 * A component to display the transaction description.
 * @param {object} props - The component props.
 * @param {Ledger} props.transaction - The transaction details.
 * @param {TransactionType} [props.cardType] - The type of card to display
 * @returns {JSX.Element} - The component element.
 */
export default function DisplayTransactionDescription({ transaction, cardType }: { transaction: Ledger, cardType?: TransactionType }) {
    const { t } = useTranslation()

    return (
        <>
            <span  id={`card_description-${transaction.id}`}  data-testid={`card_description-${transaction.id}`}>{t('transactionDescription')}{":"}{' '}{transaction.transactionDescription}</span>
            {transaction.meeting && transaction.meeting.reason && cardType === (TransactionType.Credit || TransactionType.Debit) &&
                <div id={`card_reason-${transaction.id}`} data-testid={`card_reason-${transaction.id}`}>{t('Reason')}{":"}{' '}{transaction.meeting.reason}</div>}
        </>

    )
}