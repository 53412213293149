/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {useGetFeedbackByUserIdQuery} from '../Services/feedbackApi';

/**
 * Retrieves reviews for a meeting host based on their ID.
 *
 * @param {object} params - An object containing the host ID.
 * @param {string | undefined} params.hostId - The ID of the meeting host.
 * @return {object} An object containing the reviews, loading status, and success status.
 */

export const useRequestForMeetingReviewsHook = ({
  hostId,
}: {
  hostId: string | undefined;
}) => {
  const {
    data: reviews,
    isLoading: isReviewsLoading,
    isSuccess: isReviewsSuccess,
  } = useGetFeedbackByUserIdQuery(
    {userId: hostId ? hostId : ''},
    {skip: !hostId},
  );

  return {reviews, isReviewsLoading, isReviewsSuccess};
};
