/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { FC } from 'react'
interface CardTitleProps {
  meetingTitle: string
  keyId?: number | string
}
/**
 * A reusable component for displaying a meeting title within an EventCard.
 *
 * @param {CardTitleProps} props - The component props containing meeting title and key ID.
 * @return {JSX.Element} A span element containing the meeting title, with class applied based on window width.
 */
const CardTitle: FC<CardTitleProps> = ({ meetingTitle, keyId }: CardTitleProps) => {
  const className = `font-bold text-[1rem] text-base truncate`;
  return <span data-testid={`txt_title${keyId}`} id={`txt_title${keyId}`} className={className}>{meetingTitle}</span>
}
export default CardTitle
