/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {useForm} from 'react-hook-form';
import {User} from '../models/user.model';
import {useCreateReportMutation} from '../Services/allOthersApi';
import {yupResolver} from '@hookform/resolvers/yup';
import {reportValidationSchema} from '../Utils/validation';

interface InitialReport {
  reason: any;
}

/**
 * A custom React hook for managing user report functionality.
 *
 * It provides a form for reporting a user, handles the report submission,
 * and returns the report status and form state.
 *
 * @param {User} userData - The user data of the user being reported
 * @return {object} An object containing the handleReport function, report status, and form state
 */

export const useViewUserReportHook = (userData: User) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: {errors, isDirty, isValid},
  } = useForm<InitialReport>({
    mode: 'onChange',
    resolver: yupResolver(reportValidationSchema),
  });

  const [sendReport, {isSuccess: reportSuccess, isLoading: reportLoading}] =
    useCreateReportMutation();

  const handleReport = (values: any) => {
    const args = {reportedUser: userData, reason: values.reason};
    sendReport(args);
  };

  return {
    handleReport,
    reportSuccess,
    control,
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    isValid,
    reportLoading,
  };
};
