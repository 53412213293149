/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import { Button } from '../../Components'
import beeMGLogo from '../../Assets/NewIcons/beeMG_logo-4.svg'
import { Link } from 'react-router-dom'
import UpdateCategory from '../Profile/UserProfile/SelectCategories'
import { useAppSelector } from '../../Store/hooks'
import { getCurrentUser } from '../../Services/userReducer'
import { useDispatch } from 'react-redux'
import { changeToken } from '../../Services/signUpReducer'
import NewIcon from '../../Components/base/icon/newIcons'

/**
 * Interests component renders a panel with BeeMG logo, title, an UpdateCategory component and a button at the bottom.
 * The button is a link to /s/profile and will be clicked when the user has finished selecting categories.
 * The component also dispatches an action to reset the signUpToken when the button is clicked.
 */
function Interests() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <>
      <div className="flex flex-col w-interests p-3 rounded-lg opacity-90 bg-white">
        <div className="flex items-center flex-col px-2 pr-40 my-4 ml-28"><NewIcon icon="BEEMG_LOGO" stroke='none' className='h-20 w-20 mb-2'/></div>
        <span className="text-5xl sm:text-normal my-4 pl-4">
          {t('selectCategoriesOfYourInterest')}!
        </span>
        <div className="mt-2 mx-2">
          <UpdateCategory />
        </div>
        <div className="flex items-center flex-col px-2 my-4" onClick={() => { dispatch(changeToken(null)) }}>
          <Link id="btn_next3" to="/s/profile">
            <div className='items-center'>
              <Button className="w-72 rounded-lg mt-4 mb-2" color="save" submit>
                <div>{t('done') + ' 4/4 '}</div>
              </Button>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}


/**
 * ChooseInterests component renders a BeeMG background image and a panel with BeeMG logo, title, an UpdateCategory component and a button at the bottom.
 * The button is a link to /s/profile and will be clicked when the user has finished selecting categories.
 * If there is no current user, the component will return an empty component.
 */
function ChooseInterests() {

  const currentUser = useAppSelector(getCurrentUser);

  if (!currentUser) {
    return <></>
  }

  return (
    <div
      className={`beemg_background md:bg-center md:bg-cover bg-no-repeat h-screen flex flex-col`}
      style={{
        backgroundImage: `url(https://dev.img.beemyguest.jp/bmg_hive.webp)`,
      }}>
      <div className='relative'>

        <div className={`h-full flex overflow-y-auto items-center`}>
          <span
            className="flex items-center h-screen"
            style={{ paddingLeft: `5%` }}>
            <Interests />
          </span>
        </div>
      </div>
    </div>
  )
}

export default ChooseInterests
