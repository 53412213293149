/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { ReportHandler } from 'web-vitals';

/**
 * If your app can't render server-side, we recommend running this function in your client-side
 * code. It sends page load performance metrics to Google Analytics.
 *
 * @param {ReportHandler} onPerfEntry - The function to be called after the performance entry has
 * been sent to Google Analytics.
 *
 * @returns {void}
 */
const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
