/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */


import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../Components'
import Chip from '../Components/Chip'
import useToggle from '../Components/_utils/useToggle'
import Avatar from '../Components/base/avatar/avatar'
import Field from '../Components/base/field/field'
import NewIcon from '../Components/base/icon/newIcons'
import Loading from '../Components/base/loading/loading'
import { Modal, ModalBody, ModalCloseButton, ModalFooter } from '../Components/base/modal/modal'
import DisplayName from '../Components/base/user-name/DisplayName'
import { toastError, toastSuccess } from '../Components/toast'
import useCheckOnlineHook from '../Hooks/CheckOnlineHook'
import { setSendVideoChatRequest } from '../Store/Chat/chat'
import { User } from '../models/user.model'
import { useViewUserProfileHook } from '../Hooks/ViewUserProfileHook'
import { useBlockCollaborator } from '../Hooks/BlockCollaboratorHook'
import { useViewUserReportHook } from '../Hooks/ViewUserReportHook'
import { sortingCategories } from '../Hooks/SelectCategoriesHook'


/**
 * Displays the user's profile information, including their avatar, details, video, interests, and anonymous settings.
 *
 * @return {JSX.Element|null} The user's profile information, or null if the user data is not available.
 */
function ViewProfile() {

  const { t } = useTranslation()
  const { userId } = useParams()
  const navigate = useNavigate()

  const { userData, userDataSuccess, allowUserMessage, userDetailSuccess } = useViewUserProfileHook(userId ? userId : '')
  const { isBlocked, blockActivityDisabled } = useBlockCollaborator(userData?.id ?? "")
  const { isOnline } = useCheckOnlineHook(userData?.id ?? "")
  const sortedCategories = sortingCategories(userData?.userCategory)

  const dispatch = useDispatch()

  if (!userDataSuccess || !userDetailSuccess || !userData) {
    return <Loading />
  }

  return (
    <div className="flex flex-col h-[85vh] justify-between items-center overflow-y-scroll">
      <div className="relative flex flex-col items-center justify-center gap-y-4 pt-12 px-5">

        <Avatar
          outline={!isBlocked && isOnline ? true : false}
          outlineColor={'outline-BeeMG-green'}
          size="x-large"
          images={userData?.image}
          avatar={userData?.avatar}
          anonymous={userData?.anonymous}
        />
        <div className='h-1'/>
        <div className='leading-none flex flex-col gap-y-2 items-center'>
        <span
          data-testid="input_name"
          id={`userName_${userData.userId}`}
          className="text-[1.4rem] font-medium leading-none flex items-center">
          <DisplayName user={userData} />
        </span>

        <div className="flex text-justify items-center flex-wrap">
          <span data-testid="txt_catchPhrase" className="md:w-[30rem] leading-[1.2rem] font-normal text-center line-clamp-2">
            {userData?.catchPhrase}
          </span>
        </div>
            </div>
        <div className="flex flex-col md:flex-row justify-between items-center font-bold w-full md:w-[30rem] gap-2 max-w-[30rem]">
          {allowUserMessage === 'ALLOW' && userData?.id && (
            <div
              className={`flex flex-row rounded-lg py-2 px-5 justify-center items-center gap-x-2 w-full flex-1 bg-BeeMG-yellow text-sm font-semibold ${isBlocked || blockActivityDisabled
                ? 'cursor-not-allowed opacity-50'
                : 'cursor-pointer'
                } `}
              data-testid="faceToFace"
              onClick={() => {
                if (isBlocked) {
                  toastError(t('unBlockTheUserToSendVideoRequest'))
                  return
                }
                dispatch(setSendVideoChatRequest(true))
                navigate(`/s/chat-box/${userData?.id}`)
              }}>
              {/* <Icon icon="VIDEO_BLACK" size="small" /> */}
              <NewIcon icon='FACETOFACE'/>
              <span id="btn_connectThroughVideo" >{t('faceToFace')}</span>
            </div>
          )}

          {allowUserMessage === 'ALLOW' && userData?.id && (
            <div
              className={`flex flex-row rounded-lg py-2 px-5 justify-center items-center gap-x-2 w-full flex-1 bg-BeeMG-yellow text-sm font-semibold ${isBlocked || blockActivityDisabled
                ? 'cursor-not-allowed opacity-50'
                : 'cursor-pointer'
                } `}
              data-testid="haveAChat"
              onClick={() => {
                if (isBlocked) {
                  toastError(t('unBlockTheUserToSendChatRequest'))
                  return
                }
                navigate(`/s/chat-box/${userData?.id}`)
              }}>
              <NewIcon icon='CHAT'/>
              <span id="btn_haveAChat" >{t('haveAChat')}</span>
            </div>
          )}
        </div>

        <div className='h-3'/> 
        <div className="flex flex-wrap items-center justify-center gap-1 md:w-[70%]">
          {sortedCategories?.map(data => {

            // check for blank categories
            if (data.category?.name.length) {
              return (
                <Chip
                  value={t(`userCategories.${data.category?.name}`)}
                  key={data.id}
                  addCatId={`category-${data?.category?.name.split(' ')[0]}`}
                />
              )
            }
            return null
          })}
        </div>

      </div>
      <div className='mt-4 justify-self-end'>
        <ReportProfile userId={userId} userData={userData} />
      </div>
    </div>
  )
}

export default ViewProfile

/**
 * A React component for reporting a user.
 *
 * It provides a form for reporting a user, handles the report submission,
 * and returns the report status and form state.
 *
 * @param {{ userId?: string, userData: User, hideBlock?: boolean }} props
 * @param {string} [props.userId] The user ID of the user being reported (optional)
 * @param {User} props.userData The user data of the user being reported
 * @param {boolean} [props.hideBlock] Whether to hide the block button (optional, default false)
 */
export function ReportProfile({ userId, userData, hideBlock = false }: { userId?: string, userData: User, hideBlock?: boolean }) {

  const { t } = useTranslation()
  const { toggle, isOpen } = useToggle()
  const { handleReport, reportSuccess, register, handleSubmit, errors, reset, isDirty, isValid } = useViewUserReportHook(userData)

  useEffect(() => {
    if (reportSuccess) {
      toastSuccess(t('reportedSuccessfully'))
      reset()
      toggle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportSuccess, t])

  return (
    <div>
      <div className="flex flex-row justify-center items-center gap-x-5 mb-4">
        <span id="btn_reportAbuse" data-testid="report_abuse" className="text-red-600 cursor-pointer" onClick={toggle}>{t('reportAbuse')}</span>
        {!hideBlock && <div className='h-2 w-2 rounded-full bg-red-500' />}
        {!hideBlock && <BlockActions id={userData.id} />}
      </div>
      <Modal isOpen={isOpen} toggle={toggle} closeOnClickOutside={false}>
        <ModalCloseButton toggle={toggle} />
        <form onSubmit={handleSubmit(handleReport)}>
          <ModalBody>
            <span className="flex justify-between items-center">
              <span data-testid="txt_modalTitle" className="text-2xl font-semibold pl-2">
                {' '}
                {t('whyDoYouWantToReport')}?
              </span>
            </span>
            <div className="pl-2 pr-4 mt-4">
              <Field
                {...register('reason')}
                error={(errors?.reason as any)?.message}
                name="reason"
                type="textarea"
                id="input_reason"
                data-testid="input_reason"
                placeholder={t(`reason`) + ' *'}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button data-testid="btn_cancel" id="btn_cancel" onClick={() => {
              reset()
              toggle()
            }} color="footerButton">{t(`cancel`)}</Button>
            <Button submit autoFocus
              disabled={!isDirty || !isValid}
              id="btn_submit"
              data-testid="btn_submit"
              color="footerButton">
              {t(`ok`)}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  )
}

/**
 * A component that displays block and unblock actions for a collaborator.
 *
 * @param {string} id - The ID of the collaborator.
 * @return {JSX.Element} The block and unblock action elements.
 */
const BlockActions = ({ id }: { id: string }) => {
  const { t } = useTranslation();

  const { handleBlock, handleUnBlock, alreadyBlockedData, isBlocked, blockActivityDisabled } = useBlockCollaborator(id)

  const blockActionStyle = `text-red-600 ${blockActivityDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer opacity-100'}`

  return (
    <div>
      {isBlocked !== null && <div>
        {!isBlocked && <span id="btn_block" data-testid="btn_block" className={blockActionStyle} onClick={() => { handleBlock() }}>{t('block')}</span>}
        {alreadyBlockedData && isBlocked && <span id="btn_unBlock" data-testid="btn_unBlock" className={blockActionStyle} onClick={() => { handleUnBlock(alreadyBlockedData.id) }}>{t('unBlock')}</span>}
      </div>}
    </div>
  )
}