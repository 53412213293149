/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { FC, useEffect } from 'react'
import { useLanguageSwitchHook } from '../Hooks/LanguageSwitchHook'
import Icon from './base/icon/icon'
import { ToolTip } from './base/tooltip/tooltip'
import { Menu, Transition } from '@headlessui/react'

const style = {
  menu: `block w-full flex mb-1 rounded-md text-base font-normal whitespace-nowrap  hover:bg-gray-200 cursor-pointer`,
  item: `py-1 px-5 w-full text-left`,
}

  /**
   * A dropdown menu for changing the language.
   *
   * @returns {JSX.Element} A JSX element containing the language dropdown menu.
   */
const LangDropdownMenu: FC = () => {
  const { setLanguage, i18n } = useLanguageSwitchHook()

  /**
   * Changes the language to the given language code.
   *
   * @param {string} lng The language code to change to.
   */
  const handleChange = (lng: string) => {
    setLanguage(lng)
  }
  useEffect(() => {
    if (i18n.language === 'en-US') {
      setLanguage('en')
    }
  }, [i18n.language, setLanguage])
  
  return (
    <Menu>
      <div className='relative'>
        <Menu.Button id="dropMenu_lang_language" className=" text-gray-400 font-normal flex items-center cursor-pointer">
          <ToolTip tip={'language'} keyId="language">
            <Icon icon="LANG_B" size="medium" height="large" />
          </ToolTip>
        </Menu.Button>

        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"></Transition>
        <Menu.Items className="absolute right-0 py-2 px-2 bg-white rounded-lg lg:w-44 xs:w-52 mt-6 mb-0 mx-0 bg-clip-padding w-auto border">
          {(i18n.language === 'en' || i18n.language === 'en-US') && 
            <Menu.Item>
              <div
                className={style.menu}
                onClick={() => handleChange('ja')}
              >
                <div id="lang_jp" className={style.item}>日本語</div>
              </div>
            </Menu.Item>
          }
          {(i18n.language === 'ja' || i18n.language === 'ja-JP') && 
            <Menu.Item>
              <div
                className={style.menu}
                onClick={() => handleChange('en')}
              >
                <div id="lang_en" className={style.item}>English</div>
              </div>
            </Menu.Item>
          }
        </Menu.Items>
      </div>
    </Menu>
  )
}

export default LangDropdownMenu
