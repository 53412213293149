/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { MouseEvent } from 'react'
import Icon from './base/icon/icon'

interface ChipProps {
  children?: React.ReactNode
  value: string
  isSelected?: boolean
  onClick?: (value: string) => void
  onClickClose?: (value: string) => void
  addCatId?: string
  removeCatId?: string
  className?: string
}

  /**
   * A React component for a chip.
   *
   * This component is used to create a chip component with a value that can be clicked.
   * If the chip is selected, a close button will be displayed on the right side.
   * If the chip is not selected, a plus button will be displayed on the left side.
   *
   * @param {ChipProps} props - Component props
   * @param {string} props.value - The value of the chip
   * @param {boolean} [props.isSelected = false] - Whether the chip is selected or not
   * @param {(value: string) => void} [props.onClick] - Function to call when the chip is clicked
   * @param {(value: string) => void} [props.onClickClose] - Function to call when the close button is clicked
   * @param {string} [props.addCatId] - The ID of the category to add
   * @param {string} [props.removeCatId] - The ID of the category to remove
   * @param {string} [props.className] - Additional CSS class to apply to the chip
   * @returns {React.ReactElement} JSX element to display the chip
   */
export default function Chip(props: ChipProps) {
  const { value, isSelected = false, onClick, onClickClose, addCatId, removeCatId, className, children } = props

  //Removing the Continuous white space from the string
  const trimmingSpace = value.trimStart()
  const chipStyle = className  ?? `${isSelected ? 'bg-primary' : 'bg-gray-200'} ${onClick ? 'cursor-pointer' : 'cursor-auto'}`

  /**
   * Handles the click event on the close button.
   *
   * Prevents the default click event and calls the onClickClose function with the value of the chip.
   * @param {MouseEvent} e The event object.
   */
  const handleClose = (e: MouseEvent) => {
    e.preventDefault()
    onClickClose?.(value);
    return
  }

  /**
   * Handles the click event on the chip.
   *
   * Prevents the default click event and calls the onClick function with the value of the chip.
   * @param {MouseEvent} e The event object.
   */
  const handleClick = (e: MouseEvent) => {
    e.preventDefault()
    onClick?.(value);
    return
  }

  return (
    <>
      {trimmingSpace.length > 0 && (
        <div
          onClick={handleClick}
          className={`px-3 pr-10 py-1 text-sm rounded-sm flex flex-row items-center justify-between relative ${chipStyle} `}>
          <div
            id={`${isSelected ? `${value.split(' ')[0]}` : `${addCatId}`}`}
            data-testid={`${
              isSelected ? `${value.split(' ')[0]}` : `${addCatId}`
            }`}>
            {children}
            {!children && <>{value}</>}
          </div>
          {isSelected ? (
            <div
              className="absolute right-4 cursor-pointer"
              id={removeCatId}
              data-testid={removeCatId}
              onClick={handleClose}>
              <Icon icon="CLOSE" size="x-small" />
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}
