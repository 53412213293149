/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {Fragment, useEffect, useState} from 'react';
import {Combobox, Transition} from '@headlessui/react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Chip from '../../Components/Chip';
import Button from '../../Components/base/button/button';
import Field from '../../Components/base/field/field';
import NewIcon from '../../Components/base/icon/newIcons';
import Loading from '../../Components/base/loading/loading';
import {PageTitle} from '../../Components/pageTitle';
import {toastSuccess} from '../../Components/toast';
import {useSettingsHook} from '../../Hooks/SettingsHook';
import {Currency} from '../../models/currency.model';
import {PaymentAccountActive} from '../../models/user.model';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

/**
 * @function Settings
 * @description This function renders the settings page.
 * @return {ReactElement} The settings page.
 */
function Settings() {
  const {t} = useTranslation();

  type RegisterFields =
    | 'currency'
    | 'timezone'
    | 'timezoneUtc'
    | 'meetingLanguage'
    | 'onlineList'
    | 'allowMessage'
    | 'allowFreeConnection'
    | 'allowVideo'
    | 'gstin'
    | `meetingLanguage.${number}`;

  const {
    recaptchaValue,
    setVal,
    val,
    onChange,
    preference,
    currencyData,
    siteKey,
    loadingData,
    onSubmit,
    updateSuccess,
    register,
    handleSubmit,
    setValue,
    errors,
    currentUserDetail,
    currentUser,
    checkCurrency,
    currencyError,
    showRazorpay,
    stripeError,
    createAccount,
    link,
    accountActive,
    languageList,
    languageListSuccess,
    handleAddCategoryList,
    handleRemoveLanguage,
    language,
  } = useSettingsHook();

  const [languageQuery, setLanguageQuery] = useState('');
  const [currencyDataVal, setCurrencyDataVal] = useState<Currency[]>();
  const [currencyValue, setCurrencyValue] = useState();

  useEffect(() => {
    if (updateSuccess) {
      toastSuccess(t('settingsUpdatedSuccessfully'));
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (currencyError) {
      setCurrencyDataVal(
        currencyData?.filter((currencyObj: any) => currencyObj.code === 'USD'),
      );
    } else {
      setCurrencyDataVal(currencyData);
    }
  }, [currencyError]);

  let filteredLanguages;
  if (languageList) {
    filteredLanguages =
      languageQuery === ''
        ? languageList
        : languageList.filter((language: any) =>
            language
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(languageQuery.toLowerCase().replace(/\s+/g, '')),
          );
  }

  if (loadingData || !val || !languageList) {
    return <Loading />;
  }

  return (
    <>
      <PageTitle title="settings">
        <div className="mt-5 flex flex-col items-center">
          <div className="mt-6 text-center">
            {!currencyError && (
              <>
                {!showRazorpay && (
                  <>
                    <div>
                      {!stripeError && (
                        <>
                          {!currentUserDetail?.address && (
                            <span
                              id="chk_connectStripe"
                              className="cursor-pointer text-blue-600"
                              onClick={createAccount}>
                              {t('activate')}
                            </span>
                          )}
                          {currentUserDetail?.address && !accountActive && (
                            <a
                              className="cursor-pointer text-blue-600"
                              href={link}>
                              {t('activate')}
                            </a>
                          )}
                        </>
                      )}
                      <NewIcon
                        icon="STRIPE"
                        stroke="none"
                        className="w-[15rem] h-[4rem] my-2"
                      />
                    </div>
                    {accountActive && t('stripAccountIsActive')}
                  </>
                )}
                {showRazorpay && (
                  <>
                    {!currentUserDetail?.address &&
                      !currentUser?.paymentAccountType && (
                        <Link
                          to="/s/razorpay"
                          id="btn_connectWithRazorPay"
                          data-testid="link_razorpay"
                          className="text-blue-600 leading-none">
                          {t('activate')}
                          <NewIcon
                            icon="RAZORPAY"
                            stroke="none"
                            className="w-[15rem] h-[3rem] "
                          />
                        </Link>
                      )}
                    {!currentUserDetail?.address &&
                      currentUser?.paymentAccountType === 'Razorpay' &&
                      !accountActive && (
                        <>
                          <div className="flex flex-col gap-4">
                            <span
                              data-testid="img_razorpay"
                              className="flex flex-row justify-center">
                              <NewIcon
                                icon="RAZORPAY"
                                stroke="none"
                                className="w-[15rem] h-[3rem] "
                              />
                            </span>
                            <span data-testid="txt_razorpay">
                              {t('detailsEntered')}.{' '}
                              {t('accountWillBeActivatedInADay')}
                            </span>
                          </div>
                        </>
                      )}
                    {accountActive && (
                      <div className="text-red-500">
                        {' '}
                        {t('razorpayAccountIsActive')}{' '}
                        <NewIcon
                          icon="RAZORPAY"
                          stroke="none"
                          className="w-[15rem] h-[3rem] my-2 "
                        />{' '}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="mt-2">
            <small className="text-red-500 " id="text_paymentActivation">
              {!currencyError &&
                !accountActive &&
                t('youCanHostBothPaidAndFreeEventsOnPaymentAccountActivation')}
            </small>
          </div>
          <div className="mt-10">
            <div>
              <label className="flex flex-col items-center text-lg font-semibold">
                {t(`currencyPreference`)}
              </label>
            </div>
            {val && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-6 gap-3 mt-1">
                  <div className="col-span-6 md:col-span-3">
                    <Field
                      style={{height: '35px'}}
                      {...register('currency')}
                      error={errors?.currency?.message}
                      name="currency"
                      id="input_currency"
                      defaultValue={preference?.currency}
                      data-testid="input_currency"
                      type="select"
                      disabled={
                        currentUser.paymentAccountActive ===
                        PaymentAccountActive.Yes
                      }
                      onChange={(e: any) => {
                        setValue('currency', e.target.value);
                        checkCurrency(e.target.value);
                        setCurrencyValue(e.target.value);
                      }}>
                      <option value={preference?.currency}>
                        {preference?.currency}
                      </option>
                      {currencyDataVal?.map((currency, i) => {
                        return (
                          <option
                            data-testid={`settings-${currency.code}`}
                            id={`settings-${currency.code}`}
                            value={currency.code}
                            key={i}>
                            {currency.code}-{currency.name}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="col-span-6 md:col-span-3 h-9">
                    <div className="z-30 w-full h-full">
                      <Field
                        {...register('timezone')}
                        error={errors?.timezone?.message}
                        name="timezone"
                        id="input_timezone"
                        defaultValue={preference?.timezone}
                        data-testid="input_timezone"
                        type="text"
                        style={{height: '35px', fontSize: 14}}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <small className="text-red-500 " id="text_paymentActivation">
                  {currencyError === 'Not Supported' &&
                    currentUser.paymentAccountActive ===
                      PaymentAccountActive.No && (
                      <div id="txt_hostFreeEvents">
                        {t('yourLocalCurrencyIsNotSupportedByStripe')}
                        {t(
                          'youCanOnlyHostFreeEventsTryToConnectWithUSDForPaymentActivation',
                        )}
                      </div>
                    )}
                  {currencyError === 'Not Available' &&
                    currentUser.paymentAccountActive ===
                      PaymentAccountActive.No && (
                      <div id="txt_hostFreeEvents">
                        {
                          (preference &&
                            preference.actualCurrency +
                              t('currencyIsNotSupported'),
                          t(
                            'youCanOnlyHostFreeEventsTryToConnectWithUSDForPaymentActivation',
                          ))
                        }
                      </div>
                    )}
                </small>
                {currencyValue === 'INR' && (
                  <div className="mt-4">
                    <label className="flex flex-col items-center text-lg font-semibold">
                      {t(`gstinNumber`)}
                    </label>
                    <Field
                      type="text"
                      className="mt-2 leading-5 flex flex-1 w-full rounded-lg p-input bg-white text-gray-400 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 h-9 text-center"
                      placeholder={t(`enterYourGSTINNumber`)}
                      {...register('gstin')}
                      name="gstin"
                      id="input_gstin"
                      data-testid="input_gstin"
                      error={errors?.gstin?.message}
                      defaultValue={preference?.gstin}
                      onChange={(e: any) => {
                        const newValue = e.target.value;
                        setValue('gstin', newValue);
                      }}
                    />
                  </div>
                )}

                <div className="mt-10">
                  <label className="flex flex-col items-center text-lg font-semibold">
                    {t(`eventLanguage`)}
                  </label>
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-col items-center">
                    {languageListSuccess && (
                      <div className="w-full">
                        {filteredLanguages && languageList && (
                          <div className="z-30 w-full my-2 md:my-0">
                            <Combobox value={''} onChange={() => {}}>
                              <div className="relative z-30 mt-1">
                                <div>
                                  <Combobox.Input
                                    className="leading-5 relative flex flex-1 w-full rounded-lg p-input bg-white text-gray-400 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 h-9 text-center"
                                    displayValue={(language: any) => language}
                                    onChange={() => {}}
                                    onSelect={(event: any) => {
                                      setLanguageQuery(
                                        event.target.value.replace(
                                          /[^a-zA-Z]/g,
                                          '',
                                        ),
                                      );
                                    }}
                                    onKeyPress={(event: any) => {
                                      if (
                                        !`${event.target.value}${event.key}`.match(
                                          /^[a-zA-Z][a-zA-Z0-9]*$/,
                                        )
                                      ) {
                                        event.preventDefault();
                                        return false;
                                      }
                                    }}
                                    placeholder={
                                      t('typeYourPreferredLanguage') + ' *'
                                    }
                                  />
                                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center"></Combobox.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                  afterLeave={() => setLanguageQuery('')}>
                                  <Combobox.Options className="focus:outline-none absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                                    {filteredLanguages?.length === 0 ? (
                                      <Combobox.Option
                                        onClick={() =>
                                          handleAddCategoryList(languageQuery)
                                        }
                                        value={{id: null, name: languageQuery}}
                                        className={({active}) =>
                                          `relative cursor-default select-none py-2 pl-10 pr-4 mx-1 rounded-lg ${
                                            active
                                              ? 'bg-BeeMG-yellow text-black'
                                              : 'text-gray-900'
                                          }`
                                        }>
                                        {languageQuery}
                                      </Combobox.Option>
                                    ) : (
                                      filteredLanguages?.map(
                                        (language: any) => (
                                          <Combobox.Option
                                            key={language}
                                            className={({active}) =>
                                              `relative cursor-default select-none py-2 pl-10 pr-4 mx-1 rounded-lg ${
                                                active
                                                  ? 'bg-BeeMG-yellow text-black'
                                                  : 'text-gray-900'
                                              }`
                                            }
                                            value={language}
                                            onClick={() =>
                                              handleAddCategoryList(language)
                                            }>
                                            {({selected, active}) => (
                                              <span
                                                id="txt_selectedLanguage"
                                                className={`block truncate ${
                                                  selected
                                                    ? 'font-semibold'
                                                    : 'font-normal'
                                                }`}>
                                                {language}
                                              </span>
                                            )}
                                          </Combobox.Option>
                                        ),
                                      )
                                    )}
                                  </Combobox.Options>
                                </Transition>
                              </div>
                            </Combobox>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {language && language.length > 0 && (
                    <div className="flex flex-wrap gap-4 my-4 max-w-[30rem]">
                      <>
                        {language.map((item: any, index: number) => (
                          <Chip
                            isSelected={true}
                            value={item}
                            key={index}
                            removeCatId={`btn_removeCat_${index}`}
                            onClickClose={e => handleRemoveLanguage(item)}
                            className="bg-gray-200"
                          />
                        ))}
                      </>
                    </div>
                  )}
                </div>

                <div className="mt-10">
                  <label className="flex flex-col items-center text-lg font-semibold mb-3">
                    {t(`connectingWithPeople`)}
                  </label>
                </div>
                <div className="flex flex-col gap-y-2 mb-2">
                  <div className="flex justify-center items-center">
                    <div className="flex flex-1 justify-start pl-7">
                      <label
                        htmlFor="input_onlineList"
                        className="self-center col-span-2"
                        data-testid="chk_otherPeopleList"
                        id="chk_otherPeopleList">
                        {t('showUpOnOtherUsersOnlineList')}
                      </label>
                    </div>
                    <div className="px-4 justify-center">
                      <Field
                        type="checkbox"
                        name="onlineList"
                        id="input_onlineList"
                        defaultChecked={!!val.onlineList}
                        onChange={(e: any) => {
                          setValue(
                            'onlineList',
                            e.target.checked ? true : false,
                          );
                          setVal(val);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <div className="flex flex-1 justify-start pl-7">
                      <label
                        htmlFor="input_allowVideo"
                        className="self-center"
                        data-testid="chk_allowToMessages"
                        id="chk_allowToMessage">
                        {t('allowOtherUsersToMessageYou')}
                      </label>
                    </div>
                    <div className="px-4 justify-center">
                      <Field
                        type="checkbox"
                        name="allowMessage"
                        id="input_allowVideo"
                        data-testid={`settings-${val.allowMessage}`}
                        defaultChecked={!!val.allowMessage}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setValue(
                            'allowMessage',
                            e.target.checked ? true : false,
                          );
                          setVal(val);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <div className="flex flex-1 justify-start pl-7">
                      <label
                        htmlFor="input_allowMessage"
                        className="self-center"
                        data-testid="chk_peopleToConnect"
                        id="chk_peopleToConnect">
                        {t('allowOtherUserToConnectWithYou')}
                      </label>
                    </div>
                    <div className="px-4 justify-center">
                      <Field
                        type="checkbox"
                        name="allowVideo"
                        id="input_allowMessage"
                        defaultChecked={!!val.allowVideo}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setValue(
                            'allowVideo',
                            e.target.checked ? true : false,
                          );
                          setVal(val);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <div className="flex flex-1 justify-start pl-7">
                      <label
                        htmlFor="input_allowFreeConnection"
                        className="self-center"
                        data-testid="chk_allowFree"
                        id="chk_allowFree">
                        {t('allowFreeConnectionWithOtherGuests')}
                      </label>
                    </div>
                    <div className="px-4 justify-center">
                      <Field
                        type="checkbox"
                        name="allowFreeConnection"
                        id="input_allowFreeConnection"
                        data-testid="input_allowFreeConnection"
                        defaultChecked={!!val.allowFreeConnection}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setValue(
                            'allowFreeConnection',
                            e.target.checked ? true : false,
                          );
                          setVal(val);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center col-span-6 w-full pt-5">
                  {siteKey && (
                    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                      <GoogleReCaptcha onVerify={onChange} />
                    </GoogleReCaptchaProvider>
                  )}
                </div>
                <div className="flex justify-center">
                  <Button
                    submit
                    className="my-8"
                    color="save"
                    id="btn_saveSettings"
                    data-testid="btn_saveSettings"
                    disabled={!recaptchaValue}>
                    <div className="flex items-center w-80 justify-center">
                      {t('save')}
                    </div>
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </PageTitle>
    </>
  );
}
export default Settings;
