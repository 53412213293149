/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import * as React from 'react'
import {
  ChatEntry,
  ChatProps,
  ReceivedChatMessage,
  useDataChannel,
  useLocalParticipant,
  useMaybeLayoutContext,
} from '@livekit/components-react'
import { ToolTip } from '../../base/tooltip/tooltip'
import { cloneSingleChild } from '../LiveKit.utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faMessage, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'

/** @public */
export interface ChatToggleProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

/**
 * The ChatToggle component toggles the visibility of the chat component.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <ToggleChat />
 * </LiveKitRoom>
 * ```
 * @public
 */
interface ChatMessages {
  id: string
  timestamp: number
  message: string
  from: any
}

/**
 * A customizable chat toggle component that handles chat messaging functionality.
 *
 * @param {object} messageFormatter - A function to format chat messages.
 * @param {object} messageDecoder - A function to decode chat messages.
 * @param {object} messageEncoder - A function to encode chat messages.
 * @param {string} className - The CSS class name for the component.
 * @param {boolean} isPipEnabled - A flag indicating whether Picture-in-Picture mode is enabled.
 * @param {object} props - Additional props for the component.
 * @return {JSX.Element} The chat toggle component.
 */

export function CustomChatToggle({
  messageFormatter,
  messageDecoder,
  messageEncoder,
  className,
  isPipEnabled,
  ...props
}: ChatProps & { isPipEnabled?: boolean }) {
  const [isChatOpen, setIsChatOpen] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const ulRef = React.useRef<HTMLUListElement>(null)

  const { message: chatmsg, send, isSending } = useDataChannel('livekitChat')

  const layoutContext = useMaybeLayoutContext()
  const lastReadMsgAt = React.useRef<ChatMessages['timestamp']>(0)

  const [hasNewMessages, setHasNewMessages] = React.useState(false)
  const [newMessagesCount, setNewMessagesCount] = React.useState(0)
  const [messageLengthExceed, setMessageLengthExceed] = React.useState(false)
  const [chatMessages, setChatMessages] = React.useState<ChatMessages[]>([])
  const { localParticipant } = useLocalParticipant()

  const { t } = useTranslation()

  const capitalizeFirstLetter = (text: string) => {
    if (!text) return ''
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  const scrollToBottom = () => {
    if (ulRef && ulRef.current) {
      ulRef.current.scrollTo({ top: ulRef.current.scrollHeight })
    }
  }

  React.useEffect(() => {
    if (chatmsg) {
      // Decode chat messages
      const chatMessage = JSON.parse(new TextDecoder().decode(chatmsg.payload)) as ReceivedChatMessage

      const newMessage: ChatMessages = {
        id: chatMessage.id,
        timestamp: chatMessage.timestamp,
        message: capitalizeFirstLetter(chatMessage.message),
        from: chatmsg.from,
      }
      setChatMessages((prev) => [...prev, newMessage])
    }
  }, [chatmsg])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current || isChatOpen) {
        inputRef.current?.focus()
      }
    }, 10)

    return () => clearTimeout(timer)
  }, [chatMessages, isChatOpen])

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault()
    if (inputRef.current && ulRef.current) {
      const message = inputRef.current.value.trim()
      if (message !== '') {
        if (message.length > 500) {
          setMessageLengthExceed(true)
          return
        }
        setMessageLengthExceed(false)

        const encodedMessage = new TextEncoder().encode(
          JSON.stringify({ id: localParticipant?.sid, message, timestamp: Date.now() })
        )

        const sentMessage: ChatMessages = {
          id: localParticipant?.sid,
          timestamp: Date.now(),
          message: capitalizeFirstLetter(message),
          from: { name: 'You' },
        }

        send(encodedMessage, { reliable: true })
        setChatMessages((prev) => [...prev, sentMessage])
        inputRef.current.value = ''
        inputRef.current.focus()
      }
    }
  }

  function handleMessageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length <= 500) {
      setMessageLengthExceed(false)
    }
  }

  React.useEffect(() => {
    if (!isChatOpen) {
      setNewMessagesCount(chatMessages.length)
    }
  }, [isChatOpen, chatMessages])

  React.useEffect(() => {
    if (!isChatOpen && chatMessages.length > newMessagesCount) {
      setHasNewMessages(true)
    }
  }, [isChatOpen, chatMessages, newMessagesCount])

  React.useEffect(() => {
    if (isChatOpen) {
      setHasNewMessages(false)
      setNewMessagesCount(chatMessages.length)
      scrollToBottom()
      inputRef.current?.focus()
    }
  }, [isChatOpen, chatMessages])

  React.useEffect(() => {
    if (ulRef && ulRef.current && isChatOpen) {
      ulRef.current?.scrollTo({ top: ulRef.current.scrollHeight })
    }
  }, [ulRef, chatMessages, isChatOpen])

  React.useEffect(() => {
    if (!layoutContext || chatMessages.length === 0) {
      return
    }

    if (
      layoutContext.widget.state?.showChat &&
      chatMessages.length > 0 &&
      lastReadMsgAt.current !== chatMessages[chatMessages.length - 1]?.timestamp
    ) {
      lastReadMsgAt.current = chatMessages[chatMessages.length - 1]?.timestamp
      return
    }

    const unreadMessageCount = chatMessages.filter(
      (msg) => !lastReadMsgAt.current || msg.timestamp > lastReadMsgAt.current
    ).length

    const { widget } = layoutContext
    if (unreadMessageCount > 0 && widget.state?.unreadMessages !== unreadMessageCount) {
      widget.dispatch?.({ msg: 'unread_msg', count: unreadMessageCount })
    }
  }, [chatMessages, layoutContext, layoutContext?.widget])

  React.useEffect(() => {
    const lastMessage = ulRef.current?.lastElementChild
    if (lastMessage && isChatOpen) {
      lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [chatMessages, isChatOpen])

  return (
    <>
      <ToolTip tip={t('message')}>
        <button
          id="btn_chat"
          className={
            !isPipEnabled
              ? 'py-3 px-4 rounded-l-full rounded-r-full border font-medium bg-gray-200 text-gray-700 hover:bg-gray-50 transition-all text-sm'
              : ''
          }
          onClick={() => setIsChatOpen(!isChatOpen)}
        >
          <FontAwesomeIcon
            icon={faMessage}
            height={'small'}
            className={isChatOpen ? 'text-gray-500' : hasNewMessages ? 'text-yellow-500' : 'text-gray-500'}
          />
        </button>
      </ToolTip>
      {isChatOpen && (
        <Draggable handle="#chatHeader">
          <div
            {...props}
            className={`flex flex-col gap-2 w-[20rem] h-[60%] bg-white border-gray-200 border-solid border-2 ${
              isPipEnabled ? 'bottom-[7rem]' : 'bottom-[3rem]'
            } rounded-lg fixed z-50 right-2`}
          >
            {/* Top */}
            <div id="chatHeader" className="flex justify-between bg-amber-500 p-1 rounded-t-md">
              <div>
                <span id="chatHeader" className="text-white">
                  {t('groupChat')}
                </span>
              </div>
              <div className="cursor-pointer" id="btn_CloseChat">
                <FontAwesomeIcon icon={faCircleXmark} onClick={() => setIsChatOpen(!isChatOpen)} />
              </div>
            </div>

            {/* Messages */}
            <div className="overflow-y-auto py-2 h-full flex flex-col gap-2 w-full bg-white">
              <ul ref={ulRef}>
                {props.children
                  ? chatMessages.map((msg, idx) =>
                      cloneSingleChild(props.children, {
                        entry: msg,
                        key: idx,
                        messageFormatter,
                      })
                    )
                  : chatMessages.map((msg, idx, allMsg) => {
                      let slicedName = msg.from?.name || ''
                      if (slicedName.length > 30) {
                        slicedName = slicedName.slice(0, 30) + '...'
                      }
                      const hideName = idx >= 1 && allMsg[idx - 1].from?.name === slicedName
                      // If the time delta between two messages is bigger than 60s show timestamp.
                      const hideTimestamp = idx >= 1 && msg.timestamp - allMsg[idx - 1].timestamp < 60_000

                      const slicedMsg = {
                        ...msg,
                        from: { ...msg.from, name: slicedName },
                        message: capitalizeFirstLetter(msg.message),
                      } as ChatMessages

                      return (
                        <ChatEntry
                          key={idx}
                          hideName={hideName}
                          hideTimestamp={hideName === false ? false : hideTimestamp}
                          entry={slicedMsg}
                          messageFormatter={messageFormatter}
                        />
                      )
                    })}
              </ul>
            </div>

            {/* Send */}
            <div className="flex">
              <form className="w-full" onSubmit={handleSubmit}>
                <div className="flex flex-row w-full p-1">
                  <input
                    disabled={isSending}
                    ref={inputRef}
                    className="bg-transparent relative flex flex-1 w-full rounded-l-md rounded-r-none py-2 pl-4 pr-10 text-gray-400 placeholder:text-sm placeholder-gray-400 text-base focus:outline-none focus:ring-1 focus:ring-yellow-400 focus:border-transparent border border-gray-300 h-full"
                    type="text"
                    id="input_typeMsg"
                    placeholder={t('typeAMessage')}
                    onChange={handleMessageChange}
                  />
                  <div className="flex flex-col justify-center rounded-r-md rounded-l-none border-r border-t border-b border-gray-300">
                    <button disabled={isSending} type="submit" className="p-1">
                      <ToolTip tip={t('send')}>
                        <FontAwesomeIcon id="btn_sendMessage" icon={faPaperPlane} className="text-gray-500" />
                      </ToolTip>
                    </button>
                  </div>
                </div>
                {messageLengthExceed && (
                  <div id="msgLengthError" className="text-red-500 p-0.5 text-sm">
                    {t('messageLengthExceeds')} 500 {t('characters')}
                  </div>
                )}
              </form>
            </div>
          </div>
        </Draggable>
      )}
    </>
  )
}
