/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {envApi} from './envApi';
import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../Store';

interface EnvState {
  cognitoKeys: any;
}

const initialStateValue: EnvState = {
  cognitoKeys: null,
};

/**
 * Handles the extra reducers for the envSlice.
 *
 * @param {Object} builder - The builder object for adding reducers.
 * @return {void}
 */

export const envSlice = createSlice({
  name: 'env',
  initialState: initialStateValue,
  reducers: {
    resetEnv: () => initialStateValue,
  },
  extraReducers: builder => {
    builder.addMatcher(
      envApi.endpoints.getCognitoKeys.matchFulfilled,
      (state, action) => {
        state.cognitoKeys = action.payload;
      },
    );
  },
});

export const getCognitoKeys = (state: RootState): any => state.env.cognitoKeys;
export const {resetEnv} = envSlice.actions;
export default envSlice.reducer;
