/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import Loading from "../../../Components/base/loading/loading";
import { useGetCurrentUserDetailsQuery } from "../../../Services/userApi";
import { getCurrentUser, getCurrentUserDetail } from "../../../Services/userReducer";
import { useAppSelector } from "../../../Store/hooks";
import UserAvatarVideo from "../UserAvatarVideo";
import UserAnonymousEditor from "./UserAnonymousEditor";
import UserInfoFormEditor from "./UserInfoFormEditor";
import UserInterestsEditor from "./UserInterestsEditor";

/**
 * A component that renders the user profile page.
 *
 * It fetches the current user's data from the Redux store and renders a page
 * with their avatar, name, bio, interests, and a toggle for anonymous mode.
 *
 * If the data is still loading, it renders a loading animation.
 */
const UserProfile = () => {
  const { isLoading: isCurrentUserLoading } = useGetCurrentUserDetailsQuery()

  const currentUser = useAppSelector(getCurrentUser)
  const currentUserDetail = useAppSelector(getCurrentUserDetail)

  if (isCurrentUserLoading || !currentUser || !currentUserDetail) {
    return <div className='flex flex-1 h-[80vh] w-full justify-center items-center'>
      <Loading />
    </div>
  }

  return (
    <div className="w-full">
      <UserAvatarVideo user={currentUser} editMode={true} />
      <div className='flex flex-col gap-y-4 justify-center items-center max-w-[70rem] mx-auto mt-[12vh] px-6 pb-[2rem]'>
        <UserInfoFormEditor />
        <UserInterestsEditor />
        <UserAnonymousEditor />
      </div>
    </div>
  )
}

export default UserProfile