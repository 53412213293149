/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../Store';

const initialStateValue = '';

/**
 * Creates a slice for managing the redirect URL state.
 *
 * @description This slice provides actions for changing and resetting the redirect URL.
 * @return {object} The created slice object, including the reducer and actions.
 */

export const redirectUrlSlice = createSlice({
  name: 'redirecturl',
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    changeRedirectUrl: (state, action) => {
      if (!state.value) {
        state.value = action.payload;
      }
    },
    resetRedirectUrl: state => {
      if (state.value) {
        state.value = initialStateValue;
      }
    },
  },
});

export const getRedirectUrl = (state: RootState): string =>
  state.redirecturl?.value;

export const {changeRedirectUrl, resetRedirectUrl} = redirectUrlSlice.actions;
export default redirectUrlSlice.reducer;
