/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */
import { yupResolver } from "@hookform/resolvers/yup";
import { Auth } from "aws-amplify";
import { format } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { User } from "../models/user.model";
import { UserDetail } from "../models/userDetail.model";
import { useGetSettingValue } from "../Services/settingReducer";
import { changeCognitoModelObject, getIsSignUpTermsAccepted } from "../Services/signUpReducer";
import { useCreateUserMutation } from "../Services/userApi";
import { useAppDispatch, useAppSelector } from "../Store/hooks";
import { signUpValidationSchema } from "../Utils/validation";

interface Initial {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  password: string
  gender: string,
  selectedCountryCode: string,
  dateOfBirth: Date
  ageLimit?: number
}
/**
 * A custom React hook for managing the sign-up functionality.
 *
 * It provides various state and functions for handling user input,
 * validation, and submission of the sign-up form.
 *
 * @param {any} formVal - The initial values for the sign-up form.
 * @param {UserDetail} [userData] - The user's data, if available.
 * @param {any} [ipAddressData] - The user's IP address data, if available.
 * @param {any} [validationSchema] - The validation schema for the sign-up form.
 * @return {object} An object containing the state and functions for the sign-up form.
 */
export const useSignUpContentHook = (formVal: any, userData?: UserDetail, ipAddressData?: any, validationSchema?: any) => {
  let maxDob: Date, minDob: Date;
  const [serverError, setServerError] = useState();
  const {
    register, control, handleSubmit, getValues, watch, formState: { errors }, setValue
  } = useForm<Initial>({ defaultValues: formVal, resolver: yupResolver(validationSchema ?? signUpValidationSchema) })

  const ageLimit = Number(useGetSettingValue('AGE_LIMIT'));
  maxDob = new Date();
  maxDob.setFullYear(maxDob.getFullYear() - ageLimit);
  minDob = new Date();
  minDob.setFullYear(minDob.getFullYear() - 122.5);

  const [recaptchaValue, setRecaptchaValue] = useState(false);
  const termsAndConditionStatus = useAppSelector(getIsSignUpTermsAccepted)
  const dispatch = useAppDispatch();

  const [createUser] = useCreateUserMutation()

  const onChange = (value: any) => {
    if (!userData) {
      if (value) {
        if (!recaptchaValue) {
          setRecaptchaValue(true);
        }
      } else {
        if (recaptchaValue) {
          setRecaptchaValue(false);
        }
      }
    }
  }

  const signUp = async (getValues: any) => {
    console.log('signup content ipAddress', ipAddressData.ip);
    console.log('signup content countryName', ipAddressData.country_name);
    console.log('signup content cityName', ipAddressData.city);

    try {
        const values = getValues;
        const model = {} as User;
        const cognitoModel = {} as UserDetail;
        try {
            cognitoModel.email = values.email?.trim();
            cognitoModel.password = values.password;
            cognitoModel.birthdate = format(new Date(values.dateOfBirth), 'yyyy-MM-dd');
            cognitoModel.phone_number = values.phoneNumber.includes('+') ? values.phoneNumber : '+' + values.phoneNumber;
        } catch (error) {
            console.error('Error preparing cognitoModel:', error);
            throw new Error('Failed to prepare user details.');
        }

        try {
            model.firstName = values.firstName?.trim();
            model.lastName = values.lastName?.trim();
            model.token = cognitoModel.email;
            model.gender = values.gender;
            model.ipAddress = ipAddressData?.ip;
            model.country = ipAddressData?.country_name;
            model.city = ipAddressData?.city;
            model.isUserTermsPolicyStatus = termsAndConditionStatus ?? true;
        } catch (error) {
            console.error('Error preparing user model:', error);
            throw new Error('Failed to prepare user data.');
        }

        let signUpResult;
        try {
            signUpResult = await Auth.signUp({
                username: cognitoModel.email,
                password: cognitoModel.password,
                attributes: {
                    email: cognitoModel.email,
                    phone_number: cognitoModel.phone_number,
                    birthdate: cognitoModel.birthdate,
                },
            });
            console.log('Cognito sign up success:', signUpResult);
        } catch (error) {
            console.error('Error during Cognito sign up:', error);
            throw new Error('Failed to sign up with Cognito.');
        }

        try {
            model.sub = signUpResult.userSub;
            cognitoModel.sub = signUpResult.userSub;

            const res = await createUser(model);
            console.log('User created:', res);
            localStorage.setItem('SIGNIN_HELPER_COGNITOMODEL', JSON.stringify(cognitoModel));
            dispatch(changeCognitoModelObject(cognitoModel));
        } catch (error) {
            console.error('Error during user creation:', error);
            throw new Error('Failed to create user in the backend.');
        }

    } catch (e: any) {
        console.error('Sign up failed:', e);

        try {
            let errorObj = e.message.substring(0, e.message.lastIndexOf('.'));
            errorObj = JSON.parse(errorObj.split('PreSignUp failed with error ')[1]);
            setServerError(errorObj.message);
        } catch (parseError) {
            console.error('Error parsing the error message:', parseError);
            setServerError(e.message);
        }
    }
};


  return {
    recaptchaValue,
    serverError,
    onChange,
    signUp,
    maxDob,
    minDob,
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue
  };
}