/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import 'react-aspect-ratio/aspect-ratio.css'
import {
  getDisplayName,
  getIsAnonymous,
  setShowMeeting,
  getMeetingInfo,
  getShowMeeting,
  getCurrentMeetingUser,
  resetLiveKitState,
} from '../../Services/livekitReducer'
import {useAppDispatch, useAppSelector} from '../../Store/hooks'
import {LiveKitApp} from 'beemg-livekit'
import {UserType} from '../../models/livekitTypes'
import {
  useGetMeetingTokenQuery,
  videoMeetingApi,
} from '../../Services/videoMeetingApi'
import {useNavigate} from 'react-router-dom'
import {getCurrentUser} from '../../Services/userReducer'
import {useGetSettingValue} from '../../Services/settingReducer'
import {toastInfo, toastError} from '../../Components/toast'
import { t } from 'i18next'

/**
 * LiveKitMeeting component
 *
 * This component renders a LiveKit meeting when the `showMeeting` state is true.
 * It will get the meeting token and join the meeting when the meeting token is available.
 * It will also update the join time to the server when the meeting is joined.
 * It will render nothing when the `showMeeting` state is false.
 *
 * @param {boolean} showMeeting - Whether to show the meeting or not.
 * @param {MeetingInfo} meetingInfo - The meeting info object.
 * @param {boolean} isAnonymous - Whether the user is anonymous or not.
 * @param {string} displayName - The display name of the user.
 * @param {string} baseImageURL - The base URL of the image server.
 * @param {User} currentUser - The current user object.
 * @param {function} navigate - The navigate function of the react-router-dom.
 * @param {function} onEnd - The function to call when the meeting is ended.
 * @param {function} onLeave - The function to call when the user leaves the meeting.
 */
const LiveKitMeeting = () => {
  const meetingInfo = useAppSelector(getMeetingInfo)
  const showMeeting = useAppSelector(getShowMeeting)
  const dispatch = useAppDispatch()
  const [joinedStateSent, setJoinedStateSent] = useState(false)
  const isAnonymous = useAppSelector(getIsAnonymous)
  const displayName = useAppSelector(getDisplayName)
  const navigate = useNavigate()
  const currentMeetingUser = useAppSelector(getCurrentMeetingUser)
  const isJoinedAnonymous = currentMeetingUser?.isJoinedAnonymous
  const baseImageURL = useGetSettingValue('IMAGE_URL')
  const currentUser = useAppSelector(getCurrentUser)
  const [isGetToken, setIsGetToken] = useState(false)

  const isHostOrCoHost =
    meetingInfo.userType === UserType.Host ||
    meetingInfo.userType === UserType.CoHost


  useEffect(() => {
    if (!meetingInfo.meetingToken && showMeeting && meetingInfo.meetingId) {
      setIsGetToken(true)
    } else {
      setIsGetToken(false)
    }
  }, [meetingInfo.meetingToken, showMeeting, meetingInfo.meetingId])

  const {data, isFetching, isLoading, refetch} = useGetMeetingTokenQuery(
    {
      meetingId: meetingInfo.meetingId ?? '',
      anonymous: isAnonymous,
    },
    {
      skip: !isGetToken,
      // pollingInterval: 2000,
    },
  )

  useEffect(() => {
    const intervel = setInterval(() => {
      if (isGetToken) {
        refetch()
      }
    }, 2000)
    return () => {
      clearInterval(intervel)
    }
  }, [isGetToken])

  /**
   * Ends the meeting and sends the leave time to the server.
   * If the user is a host or cohost, navigates to the creator page.
   * If the user is not a host or cohost, navigates to the feedback page.
   * Invalidates the video meeting tags in the cache and resets the LiveKit state.
   */
  const onEnd = () => {
    setTimeout(() => {
    dispatch(
      setShowMeeting({
        showMeeting: false,
        meetingId: '',
        isAnonymous: false,
        displayName: '',
      }),
    )
    dispatch(
      videoMeetingApi.endpoints.updateEndTime.initiate({
        meeting_id: meetingInfo.meetingId,
      }),
    )
    setJoinedStateSent(false)
    if (!isHostOrCoHost) {
     toastInfo(t('videoMeetHostEnds'), "INFO_WHITE")
     navigate(
       `/s/feedback/${meetingInfo.meetingId}/${isJoinedAnonymous ? '1' : '0'}`,
       )
      } else {
      navigate(`/s/creator`)
    }
    dispatch(videoMeetingApi.util.invalidateTags(['VideoMeeting']))
    dispatch(resetLiveKitState())
    }, 2000)
  }

  /**
   * Sends the leave time to the server, invalidates the video meeting tags in the cache, and resets the LiveKit state.
   * If the user is a host or cohost, navigates to the creator page.
   * If the user is not a host or cohost, navigates to the feedback page.
   */
  const onLeave = () => {
    dispatch(
      setShowMeeting({
        showMeeting: false,
        meetingId: '',
        isAnonymous: false,
        displayName: '',
      }),
    )
    dispatch(
      videoMeetingApi.endpoints.updateLeaveTime.initiate({
        meeting_id: meetingInfo.meetingId,
      }),
    )
    setJoinedStateSent(false)
    if (!isHostOrCoHost) {
      navigate(
        `/s/feedback/${meetingInfo.meetingId}/${isJoinedAnonymous ? '1' : '0'}`,
      )
    } else {
      navigate(`/s/creator`)
    }
    dispatch(videoMeetingApi.util.invalidateTags(['VideoMeeting']))
    dispatch(resetLiveKitState())
  }

  useEffect(() => {
    let interval: any = null

    interval = setInterval(() => {
      if (new Date().toISOString() >= meetingInfo.expectedEndTime) {
        onEnd()
      }
    }, 2000)
    return () => clearInterval(interval)
  }, [meetingInfo.expectedEndTime])

  if (!showMeeting) {
    return null
  }

  if (!meetingInfo.actualStartTime) {
    if (
      (meetingInfo.userType === UserType.Host ||
        meetingInfo.userType === UserType.CoHost) &&
      joinedStateSent === false
    ) {
      // VideoMeetingLogs
      dispatch(
        videoMeetingApi.endpoints.updateStartTimeAPI.initiate({
          meeting_id: meetingInfo.meetingId,
          joinedName: displayName,
          joinedAnonymous: isAnonymous,
          userType: meetingInfo.userType,
        }),
      )
      setJoinedStateSent(true)
    }
    //
    return null
  }

  // TODO: check participnat time or update join time
  if (
    meetingInfo.userType !== UserType.Host &&
    meetingInfo.userType !== UserType.CoHost &&
    joinedStateSent === false
  ) {
    // VideoMeetingLogs
    dispatch(
      videoMeetingApi.endpoints.updateJoinTime.initiate({
        meeting_id: meetingInfo.meetingId,
        joinedName: displayName,
        joinedAnonymous: isAnonymous,
        userType: meetingInfo.userType,
      }),
    )
    setJoinedStateSent(true)
  }

  return (
    <LiveKitApp
      isAudioOnly={meetingInfo.isAudioMeeting}
      token={meetingInfo.meetingToken}
      endTime={new Date(meetingInfo.expectedEndTime)}
      waitingTimeForHostOrCoHost={5 * 60} // 5 minutes in seconds
      serverUrl={'wss://dev.livekitlatest.beemyguest.jp'}
      toast={'meeting joined'}
      meetingTitle={meetingInfo.meetingTitle}
      navigateTo={navigate}
      meetingId={meetingInfo.meetingId}
      onClickLogo={navigate}
      profileImg={
        isAnonymous
          ? baseImageURL + '/' + currentUser.avatar
          : baseImageURL + '/' + currentUser.image[0]?.webpImageName
      }
      onMessage={(messages: any, type: any) => {
        toastInfo(messages, 'INFO_WHITE')
        if (type === 'error') {
          toastError(messages)
        }
      }}
      onEnd={() => {
        onEnd()
      }}
      onLeave={() => {
        onLeave()
      }}
    />
  )
}

export default LiveKitMeeting
