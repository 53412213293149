/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import Icon from "../../Components/base/icon/icon";

interface EditIconProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  wrapperClassName?: string
  iconClassName?: string
  iconSize?: "small" | "x-small" | "medium" | "large"
  keyId?: string
}

/**
 * A reusable EditIcon component with customizable size, onClick event and wrapperClassName.
 * 
 * The component renders a clickable 'EDIT' icon.
 * 
 * @param {EditIconProps} props - The props object containing onClick, wrapperClassName, iconClassName, iconSize and keyId.
 * @return {JSX.Element} A View component containing a clickable 'EDIT' icon.
 */
const EditIcon = (props: EditIconProps) => {
  const { onClick, wrapperClassName, iconSize, iconClassName, keyId } = props;

  return (
    <div
      onClick={onClick}
      className={`bg-white rounded-full p-2 ${wrapperClassName}`}
      role="button"
      data-testid={`edit_${keyId}`}
      id={`edit_${keyId}`}
    >
      <Icon icon="EDIT" size={iconSize ? iconSize : "small"} className={iconClassName} />
    </div>
  )
}

export default EditIcon