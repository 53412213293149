/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { HTMLAttributes, ReactNode } from 'react'
import useWindowDimensions from '../Components/base/carousel/whirligig/utils'
import { HelpLink } from '../Components/HelpLink'
import LangDropdownMenu from '../Components/LangDropdownMenu'
import { useGetSettingValue } from '../Services/settingReducer'
import Footer from './Footer'
import NewIcon from '../Components/base/icon/newIcons'
import useSignOutHook from '../Hooks/SignOutHook'
import { useLocation} from 'react-router-dom'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

/**
 * A page component that renders the default background image and a
 * top-right corner with language dropdown and help link. The component
 * is designed to be used as a parent component for other pages.
 *
 * @param {Object} props
 * @param {ReactNode} props.children - The content to be rendered
 * inside the page
 */
function InitialPage({ children }: Props) {
  const { height: screenHeight, width: screenWidth } = useWindowDimensions()
  const baseImageURL = useGetSettingValue('IMAGE_URL');
  const imageSize = (size: number) => `${(size)}px`
  const location = useLocation()


  const imageWidth = imageSize(((screenWidth)) / 2)
  const imageHeight = imageSize((screenHeight) / 2)
  const { signOutActions } = useSignOutHook()

  return (
    <div
      className={`md:bg-center md:bg-cover bg-no-repeat h-screen flex flex-col overflow-hidden relative`}>
      <div className={`absolute z-10 -left-[8vw] -top-[48vh]`}>
        <img
          width={imageWidth}
          height={imageHeight}
          className="object-cover"
          src={`${baseImageURL}/bmg_hive.webp`}
          alt="hive"
        />
      </div>
      <div className={`absolute -right-[18vw] -bottom-[50vh]`}>
        <img
          width={imageWidth}
          height={imageHeight}
          className="object-cover"
          src={`${baseImageURL}/bmg_hive.webp`}
          alt="hive"
        />
      </div>

      <div className="flex flex-row fixed mt-4 right-0 pr-2 md:pr-10 gap-x-4 z-40">
        <LangDropdownMenu />
        <HelpLink />
        {(location.pathname === '/verifyPhone' ||
          location.pathname === '/verifyEmail') && 
          <NewIcon
            icon="SIGNOUT"
            stroke="none"
            size="medium"
            onClick={signOutActions}
          />
        }
      </div>

      <div
        className={`h-full w-full flex flex-1 overflow-y-auto items-center justify-center z-30`}>
        {children}
      </div>
      <div className="z-30">
        <Footer />
      </div>
    </div>
  )
}

export default InitialPage
