/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { ReactNode } from "react";

/**
 * A component to display a red status.
 *
 * The component renders a span tag with the given children, styled with
 * a larger font size, a bold font weight, and a red color.
 *
 * @param {ReactNode} children - The children of the component.
 *
 * @returns {JSX.Element} The JSX element representing the component.
 */
export default function StatusRed({ children }: { children: ReactNode }) {
    return <span className="text-lg font-semibold text-red-500 ">{children}</span>
}