/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "../Containers/useTitle";
import TitleComponent from "./titleComponent";

/**
 * A component for displaying a page title.
 *
 * This component will set the title of the page
 * to the translated value of the `title` and `subTitle` properties.
 *
 * It will also render the `children` inside a div with the class `w-full flex flex-col items-center overflow-y-auto justify-center`.
 * The `children` will be wrapped in a div with the class `mb-8 gap-2 ${width} flex place-items-center`.
 *
 * If `title` is not empty, it will also render a `TitleComponent` with the translated value of `title`.
 *
 * @param {{children: ReactNode, title?: string, subTitle?: string, width?: string}} props
 * @prop {ReactNode} children The content of the component.
 * @prop {string} [title] The title of the page.
 * @prop {string} [subTitle] The subtitle of the page.
 * @prop {string} [width] The width of the content. Defaults to "w-full lg:w-9/12".
 */
export function PageTitle({children, title="", subTitle="", width="w-full lg:w-9/12"}: {children: ReactNode, title?: string, subTitle?: string, width?: string}) {
  
  
  const {t} = useTranslation()
  useTitle(t(title) + t(subTitle));
  
  return (
    <div className="w-full flex flex-col items-center overflow-y-auto justify-center">
      <div className={`mb-8 gap-2 ${width} flex place-items-center`}>
        <div className="w-full mt-10 xs:mt-0 sm:mt-0 items-center">
          {title && <TitleComponent>{t(title)}</TitleComponent>}
            {children}
        </div>
      </div>
    </div>
  )
}