/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ToolTip } from '../Components/base/tooltip/tooltip'
import { useCountDownHook } from '../Hooks/CountDown'
import { useHandleMeetingNavigation } from './LiveKit/hooks/livekitWebOnlyHooks'


/**
 * A functional component that renders a countdown timer with navigation and join meeting functionality.
 *
 * It retrieves the next upcoming meeting and its details from the store, and displays a countdown timer
 * that updates every second. It also handles navigation to the meeting details page and join meeting
 * functionality.
 *
 * @return {JSX.Element} The countdown timer component
 */
const CountDown = () => {
  const { t } = useTranslation()

  const { displayTimerFormatted, nextEventFromDateTime, meeting, showJoinButton, showCountDown, navigateTo, headerNavigate, timeZone, offsetString } = useCountDownHook({ next6Hour: false })
  const { joinMeeting } = useHandleMeetingNavigation()

  if (!nextEventFromDateTime || !meeting) {
    return <></>
  }

  return (
    <div>
      <div className="lg:hidden"><hr /></div>
      <ToolTip tip={meeting?.meetingDetails?.title}>
        <div className="items-center leading-none">
          {!showJoinButton && meeting && headerNavigate === 'NOTALLOWED' && (
            <span className="text-sm font-semibold md:font-normal" id="chk_countdown">{t('nextEventStartsIn')}</span>
          )}

          {headerNavigate === 'ALLOWED' && (
            <Link data-testid="chk_join_meeting" to={navigateTo === 'Attending' ? "/s/events/attending-events" : "/s/events/my-events?sort=upcomingMeeting&page=1"}>
              {showJoinButton && meeting && (
                <span className="text-sm font-semibold md:font-normal cursor-pointer" id="chk_countdown"></span>
              )}
              {!showJoinButton && meeting && (
                <span className="text-sm font-semibold md:font-normal cursor-pointer" id="chk_countdown">{t('nextEventStartsIn')}</span>
              )}
            </Link>)}
          {(showCountDown) && headerNavigate === 'NOTALLOWED' && displayTimerFormatted && (
            <span data-testid="chk_countdown" className="text-sm md:text-xl font-bold"> {displayTimerFormatted}</span>
          )}

          {(showCountDown) && headerNavigate === 'ALLOWED' && displayTimerFormatted && (
            <Link data-testid="chk_countdown_link" to={navigateTo === 'Attending' ? "/s/events/attending-events" : "/s/events/my-events?sort=upcomingMeeting&page=1"} >
              <span className="text-sm font-bold cursor-pointer"> {displayTimerFormatted}</span>
            </Link>
          )}

          {!showJoinButton && meeting && (
            <Link 
            data-testid="timezone_offset_link"
            to={navigateTo === 'Attending' ? "/s/events/attending-events" : "/s/events/my-events?sort=upcomingMeeting&page=1"}>
            <div className="text-right -mt-1">
              <span className="text-xs font-semibold  text-BeeMG-orange pl-1">{timeZone}</span>
              <span className="text-xxs font-semibold -my-1.5">{' '}{offsetString}</span>
            </div>
            </Link>
          )}

          {showJoinButton && meeting && (
            <div id={`joinTheMeeting${meeting?.id}`} onClick={() => joinMeeting(meeting.id)}
              className='cursor-pointer font-medium text-black bg-primary rounded-md px-5 py-2 flex flex-row items-center justify-center text-[1rem]'>
              {t('joinTheMeeting')}
            </div>
          )}
        </div>
      </ToolTip>
      <div className="mt-2 lg:hidden"><hr /></div>
    </div>
  )
}

export default React.memo(CountDown)
