/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import CollaboratorsCard from '../Components/Collaborators/collaboratorsCard'
import CollaboratorsImage from '../Components/Collaborators/collaboratorsImage'
import { useTitle } from './useTitle'

/**
 * Renders the Collaborators screen which displays a list of nearby users, users similar to the current user, and users the current user has recently met.
 *
 * @return {JSX.Element} The Collaborators screen UI.
 */
function Collaborators() {

  const { t } = useTranslation()
  useTitle(t('collaborators'))

  return (
    <>
      <CollaboratorsImage />
      <CollaboratorsCard />
    </>
  )
}

export default Collaborators
