/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import { ReactComponent as DOWN_ARROW } from '../NewIcons/beemg-icons-dropdownarrow-2023.svg'
import { ReactComponent as INVITE } from '../NewIcons/beemg-icons-invite-2023.svg'
import { ReactComponent as PROFILE } from '../NewIcons/beemg-icons-profile-2023.svg'
import { ReactComponent as SETTINGS } from '../NewIcons/beemg-icons-settings-2023.svg'
import { ReactComponent as SIGNOUT } from '../NewIcons/beemg-icons-signout-2023.svg'
import { ReactComponent as TRANSACTION } from '../NewIcons/beemg-icons-transaction-2023.svg'
import { ReactComponent as SEARCH_GLASS } from '../NewIcons/beemg-icons-search-2023.svg'
import { ReactComponent as FILTER_RESET } from '../NewIcons/beemg-icons-filter-reset-2023.svg'
import { ReactComponent as CREATEEVENT } from '../NewIcons/beemg-icons-createevent-2023.svg'
import { ReactComponent as CALENDER } from '../NewIcons/beemg-icons-calender-2023.svg'
import { ReactComponent as CANCELEVENT } from '../NewIcons/beemg-icons-cancelevent-2023.svg'
import { ReactComponent as NOTIFYEVENT } from '../NewIcons/beemg-icons-notifyevent-2023.svg'
import { ReactComponent as AWAITCANCEL } from '../NewIcons/beemg-icons-awaitingcancel-2023.svg'
import { ReactComponent as FILTER } from '../NewIcons/beemg-icons-filter-2023.svg'
import { ReactComponent as STAR } from '../NewIcons/beemg-icons-star-2023.svg'
import { ReactComponent as CHAT } from '../NewIcons/beemg-icons-chat-2023.svg'
import { ReactComponent as FACETOFACE } from '../NewIcons/beemg-icons-facetoface-2023.svg'
import { ReactComponent as SEND } from '../NewIcons/beemg-icons-requestcancellation-2023-37 1.svg'
import { ReactComponent as STRIPE } from '../NewIcons/stripe.svg'
import { ReactComponent as ADDNEW } from '../NewIcons/beemg-icons-addnew-2023-38.svg'
import { ReactComponent as COHOST } from '../NewIcons/beemg-icons-cohost-2023-40.svg'
import { ReactComponent as VIDEO_CAM } from '../NewIcons/beemg-icons-video-2023.svg'
import { ReactComponent as VIDEO_CAM_WHITE } from '../NewIcons/beemg-icons-video-white-2023.svg'
import { ReactComponent as RAZORPAY } from '../NewIcons/razorpay.svg'
import { ReactComponent as AUDIO } from '../NewIcons/beemg-icons-voicerecord-2023.svg'
import { ReactComponent as ATTACHMENT } from '../NewIcons/beemg-icons-attach-2023.svg'
import { ReactComponent as GIF } from '../NewIcons/beemg-icons-GIF-2023.svg'
import { ReactComponent as EMOJI } from '../NewIcons/beemg-icons-emoji-2023.svg'
import { ReactComponent as GREENUP_ARROW } from '../NewIcons/beemg-icons-green-up-arrow.svg'
import { ReactComponent as REDDOWN_ARROW } from '../NewIcons/beemg-icons-red-down-arrow.svg'
import { ReactComponent as BEEMG_LOGO } from '../NewIcons/beeMG_logo-2.svg'
import { ReactComponent as REPLY} from '../NewIcons/beemg-icons-reply-2023.svg'

export const NewIcons = {
    DOWN_ARROW,
    INVITE,
    PROFILE,
    SETTINGS,
    SIGNOUT,
    TRANSACTION,
    SEARCH_GLASS,
    FILTER_RESET,
    CREATEEVENT,
    CALENDER,
    CANCELEVENT,
    NOTIFYEVENT,
    AWAITCANCEL,
    FILTER,
    STAR,
    CHAT,
    FACETOFACE,
    SEND,
    STRIPE,
    ADDNEW,
    COHOST,
    VIDEO_CAM,
    VIDEO_CAM_WHITE,
    RAZORPAY,
    AUDIO,
    EMOJI,
    ATTACHMENT,
    GIF,
    GREENUP_ARROW,
    REDDOWN_ARROW,
    BEEMG_LOGO,
    REPLY
} 