/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, NavLink, useNavigate, useLocation } from 'react-router-dom'
import { PageTitle } from '../Components/pageTitle'
import { useGetNotificationCountQuery } from '../Services/meetingRequestApi'

  /**
   * Events component, contains the layout for the events page.
   * It handles the tab switching and renders the active tab's component.
   * It also redirects to /s/events/attending-events when the user visits /s/events directly.
   * @returns {JSX.Element} The JSX element representing the events layout.
   */
function Events() {
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const {data: meetingNotificationCount} = useGetNotificationCountQuery(undefined, { pollingInterval: 30000})
  
  useEffect(() => {
    if (pathname.endsWith('/s/events')) {
      navigate('/s/events/attending-events')
    }
  }, [navigate, pathname])

  const { t } = useTranslation()

  const style = {
    events: `flex text-gray-400 cursor-pointer font-normal text-lg hover:text-black hover:font-medium sm:text-sm sm:font-semibold`,
    navLink: `xs:text-xs sm:text-sm md:text-base font-normal hover:text-black hover:font-semibold`,
    active: `xs:text-xs sm:text-sm md:text-base text-black font-semibold`,
  }

  return (
    <PageTitle title='events'>
      <div className="flex justify-center w-full md:space-x-20 gap-2 mb-4">
        {/* My events */}
        <div className={style.events}>
          <NavLink
            id="btn_myEvents"
            to={'my-events?page=1'}
            className={(navData: any) => navData.isActive ? style.active : style.navLink}>
            {t('myEvents')}
            <span className='xs:inline sm:inline md:hidden'>{' '}</span>
          </NavLink>
        </div>
        {/* Attending events */}
        <div className={style.events}>
          <span className="flex items-center">
            <NavLink
              id="btn_attendingEvents"
              to={'attending-events'}
              className={(navData: any) => navData.isActive ? style.active : style.navLink}>
              {t('attendingEvents')}
            </NavLink>

            <span className='xs:inline sm:inline md:hidden'>{' '}</span>
          </span>
        </div>
        {/* interested events */}
        <div className={style.events + ' hidden sm:block'}>
          <NavLink
            id="btn_interestedEvents"
            to={'interested-events'}
            className={(navData: any) => navData.isActive ? style.active : style.navLink}>
            {t('interestedEvents')}
            <span className='xs:inline sm:inline md:hidden'>{' '}</span>
          </NavLink>
        </div>
        {/* suggestions */}
        <div className={style.events + ' sm:hidden block'}>
          <NavLink
            id="btn_interestedEvents"
            to={'interested-events'}
            className={(navData: any) => navData.isActive ? style.active : style.navLink}>
            {t('interested')}
            <span className='xs:inline sm:inline md:hidden'>{' '}</span>
          </NavLink>
        </div>
        <div className={style.events}>
          <NavLink
            id="btn_suggestions"
            to={'suggestions'}
            className={(navData: any) => navData.isActive ? style.active : style.navLink}>
            {t('suggestions')}
          </NavLink>
        </div>
        {/* sessions request */}
        <div className={style.events}>
          <span className="flex items-center">
            <NavLink
              data-testid="btn_requestEvents"
              id="btn_requestEvents"
              to={'request-events'}
              className={(navData: any) => navData.isActive ? style.active : style.navLink}>
              {t('session')} {t('request')}
            </NavLink>

            {meetingNotificationCount !== 0 && (
              <div className="bg-green-800 px-2 rounded-full text-white text-xs ml-1">
                {meetingNotificationCount}
              </div>
            )}
            <span className='xs:inline sm:inline md:hidden'>{' '}</span>
          </span>
        </div>
      </div>
      {/* Outlet */}
      <div className={`content w-full flex justify-center`}>
        <Outlet />
      </div>
    </PageTitle>
  )
}

export default Events
